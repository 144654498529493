// @flow
import { PUSH_NOTIFICATION, PULL_NOTIFICATION } from "./constants"
import type { NotificationType } from "./types"

export const showNotification = (content: string, dispatch: Function) => {
  const id = Math.random() * 10e15

  dispatch(pushNotification({ id, content }))

  setTimeout(() => {
    dispatch(pullNotification(id))
  }, 3000)
}

export const pushNotification = ({
  id,
  content,
  type,
  icon,
}: NotificationType) => ({
  type: PUSH_NOTIFICATION,
  payload: {
    id,
    content,
    type,
    icon,
  },
})

export const pullNotification = id => ({
  type: PULL_NOTIFICATION,
  payload: id,
})

export const _oldPushNotification = ({
  content,
  type,
  icon,
}: NotificationType) => (dispatch: Function) => {
  const id = Math.random() * 10e15
  dispatch(
    pushNotification({
      id,
      content,
      type,
      icon,
    })
  )
  setTimeout(() => {
    dispatch(pullNotification(id))
  }, 3000)
}
