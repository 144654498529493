// @flow
export default function dropdownize(input: {}, field: string): [] {
  const output = []
  Object.keys(input).forEach((key) => {
    const item = {
      value: input[key]._id,
      text: input[key].name || input[key].city,
    }

    if (field) {
      item[field] = input[key][field]
    }

    output.push(item)
  })
  return output
}
