import { reset } from "redux-form"

import { instance as httpClient } from "instances/Axios"
import objectifyArray from "utils/objectifyArray"
import { NOTIFICATION } from "./notifications"

export const CALL_API = "Call API"

function validateCallAPI(types) {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.")
  }

  if (!types.every(type => typeof type === "string")) {
    throw new Error("Expected action types to be strings.")
  }

  return true
}

/*
  CALL_API: {
    types: (required) [REQUEST_TYPE, SUCCESS_TYPE, ERROR_TYPE]
    promise: (required) function returning a promise
    schema: (optional) response data will be mapped to scheme
    notifyOnError: (optional) if false, error wont be forwarded to notifyCenter
    successMessage: (optional)  if set, message will be forwarded to notifyCenter
    errorMessage: (optional)  if set, message will be forwarded to notifyCenter
    unauthorizedMessage: (optional) if set, message will be forwarded to notifyCenter
    formResetName: (optional) if set, redux-form (action) reset will be dispatched
    closeModal: (optional) if set, modal will be closed
    resetActiveFormId: (optional) if set, it will reset activeUpdatingForm
    payloadModifier:function (optional) if set, response data will be modified
  }
  ... all other fields will be forwarded to reducer
 */
export default (/* store*/) => next => async action => {
  const callAPI = action[CALL_API]

  if (typeof callAPI === "undefined") {
    return next(action)
  }

  const {
    types,
    promise,
    // notifyOnError,
    successMessage,
    errorMessage,
    // unauthorizedMessage,
    formResetName,
    payloadModifier,
  } = callAPI

  validateCallAPI(types)

  const [requestType, successType, failureType] = types
  next({
    type: requestType,
    ...action,
  })

  try {
    const response = await promise(httpClient)
    if (!response || !response.data || !response.data.data) throw new Error("No data in response")
    let data = response.data.data ? response.data.data : response.data
    if (payloadModifier) data = payloadModifier(data)
    data = objectifyArray(data)

    const nextAction = {
      type: successType,
      payload: data,
      [NOTIFICATION]: {
        successMessage,
      },
      ...action,
    }

    if ("formResetName" in callAPI) {
      next(reset(formResetName))
    }

    return next(nextAction)
  } catch (err) {
    const nextAction = {
      type: failureType,
      payload: err,
      [NOTIFICATION]: {
        errorMessage,
      },
      ...action,
    }
    return next(nextAction)
  }
}
