// @flow
import moment from "moment"

import { CALL_API } from "middleware/api"
import history from "instances/History"
import { showNotification } from "wrappers/Notifications/actions"
import objectifyArray from "utils/objectifyArray"
import ReactPixel from "react-facebook-pixel"

import {
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
} from "./constants"
import type { BookingType } from "./types"

// Helpers
const isSuccessType = (action) => {
  return action && action.type && action.type.includes("SUCCESS")
}

const isFailureType = (action) => {
  return action && action.type && action.type.includes("FAILURE")
}

// Low level action creators
export const bookTable = ({
  table_id,
  deal_id,
  contact,
  date,
  name,
  comments,
  qty,
  minimum_spend,
  promotion_id,
}: BookingType) => ({
  [CALL_API]: {
    types: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
    promise: (httpClient) =>
      httpClient({
        method: "post",
        url: "/bookings",
        data: {
          table_id,
          deal_id,
          contact,
          comments,
          minimum_spend,
          promotion_id,
          qty,
          date: moment(date).format("YYYY-MM-DD"),
        },
      }),
  },
})

export const fetchBookings = () => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: (httpClient) =>
      httpClient({
        method: "get",
        url: "/bookings",
      }),
    payloadModifier: (payload) => {
      return payload.map((e) => {
        if (e.event && e.event.override_prices) {
          e.event.override_prices = objectifyArray(e.event.override_prices, "table_id")
        }
        return e
      })
    },
  },
})

export const cancelBooking = (id: string) => ({
  [CALL_API]: {
    types: [DELETE_REQUEST, DELETE_SUCCESS, DELETE_FAILURE],
    promise: (httpClient) =>
      httpClient({
        method: "delete",
        url: "/bookings",
        params: {
          id,
        },
      }),
  },
})

// Operations
export const bookTableAndRedirect =
  (
    { table_id, deal_id, contact, date, name, comments, minSpend, qty, promotion }: BookingType,
    isDeal: ?boolean = false
  ) =>
  async (dispatch: Function, getState: Function) => {
    const finalCreateType = await dispatch(
      bookTable({
        table_id,
        deal_id,
        promotion_id: promotion,
        contact,
        date,
        name,
        comments,
        qty,
        minimum_spend: minSpend,
      })
    )
    if (isSuccessType(finalCreateType)) {
      history.push(isDeal ? "/confirmation/deal" : "/confirmation")
      if (window && window.gtag && typeof window.gtag === "function") {
        window.gtag("config", "AW-757063567")
        window.gtag("event", "conversion", {
          send_to: "AW-757063567/XrH1COmi6rAYEI-__-gC",
          value: minSpend,
          currency: "THB",
        })
      }
      // Notify Facebook Pixel about the event
      if (window && window.fbq && typeof window.fbq === "function") {
        window.fbq("track", "Purchase", {
          value: minSpend,
          currency: "THB",
        })
      }
      ReactPixel.track("Book", {
        value: minSpend,
        currency: "THB",
      })
    }
    if (isFailureType(finalCreateType))
      showNotification("Failed to book selected table, it might be already booked by someone else", dispatch)

    return finalCreateType
  }

export const cancelBookingAndRefetch = (id: string) => async (dispatch: Function, getState: Function) => {
  const finalCreateType = await dispatch(cancelBooking(id))

  if (isSuccessType(finalCreateType)) {
    dispatch(fetchBookings())
    showNotification("Booking cancellation successful", dispatch)
  }
  if (isFailureType(finalCreateType)) showNotification("Failed to cancel", dispatch)
}
