// @flow
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Grid, Divider, Container, Dropdown } from "semantic-ui-react"
import Swiper from "react-id-swiper"
import { Link } from "react-router-dom"

import { fetchDeals } from "data/Deals/actions"
import { fetchClubs } from "data/Clubs/actions"

import withPageBackground from "wrappers/PageBackground"
import DealsList from "containers/DealsList"
import SpotlightItem from "components/SpotlightItem"
import Covered from "components/Covered"
import Loading from "components/Loading"

import cover from "assets/Images/deals-background.webp"
import styles from "./styles.module.css"
import dropdownize from "utils/dropdownize"
import Cookies from "universal-cookie"
const cookies = new Cookies()

type PropTypes = {
  fetchClubs: Function,
  fetchDeals: Function,
  deals: {
    readLoading: boolean,
    segments: {},
  },
}
type StateTypes = {
  city: string,
  spotlightEvents: Array<any>,
  locations: ?Array,
  location_id: ?String,
}

class Deals extends React.Component<PropTypes, StateTypes> {
  state = {
    city: cookies.get("selectedLocation") || "Bangkok",
    spotlightEvents: [],
    locations: [],
    location_id: null,
  }

  componentDidMount() {
    if (this.props.locations.readSuccess) {
      const defaultCity = cookies.get("selectedLocation") ? cookies.get("selectedLocation") : "Bangkok"
      const locations = dropdownize(this.props.locations.segments, "country")
      const defaultLocation = locations.find((location) => location.text === defaultCity)

      if (defaultLocation) {
        this.setState({ ...this.state, location_id: defaultLocation.value, locations: locations }, () => {
          this.props.fetchClubs(defaultLocation.value)
          this.props.fetchDeals(defaultLocation.value)
        })
      } else {
        this.props.fetchDeals()
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { deals } = this.props
    if (!prevProps.deals.readSuccess && deals.readSuccess) {
      this.generateSpotlight(prevProps)
    }

    if (!prevProps.locations.readSuccess && this.props.locations.readSuccess) {
      const defaultCity = cookies.get("selectedLocation") ? cookies.get("selectedLocation") : "Bangkok"
      const locations = dropdownize(this.props.locations.segments, "country")
      const defaultLocation = locations.find((location) => location.text === defaultCity)
      if (defaultLocation) {
        this.setState({ ...this.state, location_id: defaultLocation.value, locations: locations }, () => {
          this.props.fetchClubs(defaultLocation.value)
          this.props.fetchDeals(defaultLocation.value)
        })
      } else {
        this.props.fetchDeals()
      }
    }
  }

  generateSpotlight() {
    const { deals } = this.props
    const spotlightEvents = Object.keys(deals.segments)
      .map((key) => {
        return deals.segments[key]
      })
      .sort(({ date: a }, { date: b }) => new Date(a) - new Date(b))

    this.setState({ spotlightEvents })
  }

  handleLocationChange = (e, data) => {
    const chosenLocation = data.options.find((location) => location.value === data.value)
    if (chosenLocation) {
      cookies.set("selectedLocation", chosenLocation.text, { path: "/" })
      this.setState({ ...this.state, location_id: data.value }, () => {
        this.props.fetchClubs(this.state.location_id)
        this.props.fetchDeals(this.state.location_id)
      })
    }
  }

  render() {
    const { spotlightEvents } = this.state
    return (
      <div className={"fadeIn"}>
        <Covered
          nowrap
          title={
            <>
              Exclusive <br /> Deals & Experiences
            </>
          }
          filter={
            this.state.locations.length > 0 && (
              <Dropdown
                className="no-max-height"
                style={{ "font-weight": "bold", margin: "20px 0 0 0" }}
                placeholder="Select your Location"
                options={this.state.locations}
                selection
                defaultValue={this.state.location_id}
                onChange={this.handleLocationChange}
              />
            )
          }
        />
        <div className={styles.masterContainer}>
          <Loading isLoading={this.props.deals.readLoading}>
            {this.props.windowSize && this.props.windowSize.windowWidth && spotlightEvents.length ? (
              <React.Fragment>
                <Container>
                  <h3>Spotlight</h3>
                </Container>
                <Swiper
                  shouldSwiperUpdate
                  centerInsufficientSlides
                  slidesOffsetBefore={
                    spotlightEvents.length > 1
                      ? this.props.windowSize.windowWidth && calculcateMargin(this.props.windowSize.windowWidth)
                      : undefined
                  }
                  slidesOffsetAfter={spotlightEvents.length > 1 ? 20 : undefined}
                  slidesPerView={3}
                  spaceBetween={30}
                  breakpoints={swipeBreakpoints}
                >
                  {this.state.spotlightEvents.map(
                    ({ _id, slug, name, category, spotlight, images, club_id }, i) => {
                      if (!spotlight) return null
                      const club = this.props.clubs.segments[club_id]
                      return (
                        <div className={styles.itemContainer} key={i}>
                          <Link to={`/deal/${slug || _id}`}>
                            <SpotlightItem
                              title={name}
                              subtitle={club && club.name}
                              image={images && images[0] && images[0].path}
                              label={category}
                            />
                          </Link>
                        </div>
                      )
                    }
                  )}
                </Swiper>
              </React.Fragment>
            ) : null}
          </Loading>
          <Divider hidden style={{ margin: "2em 0" }} />
          <Container>
            <Grid columns={2}>
              <Grid.Column>
                <h3>All Deals & Experiences</h3>
              </Grid.Column>
              <Grid.Column textAlign={"right"}></Grid.Column>
            </Grid>
            <DealsList />
          </Container>
        </div>
      </div>
    )
  }
}

const mstp = ({ deals, clubs, locations }) => ({ deals, clubs, locations })
const mdtp = (dispatch) => ({
  fetchDeals: bindActionCreators(fetchDeals, dispatch),
  fetchClubs: bindActionCreators(fetchClubs, dispatch),
})

export default connect(mstp, mdtp)(withPageBackground({ image: cover })(Deals))

const swipeBreakpoints = {
  "10000": {
    slidesPerView: 8,
    spaceBetween: 20,
  },
  "3000": {
    slidesPerView: 6,
    spaceBetween: 20,
  },
  "2000": {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  "1400": {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  "768": {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  "450": {
    slidesPerView: 1.2,
    spaceBetween: 20,
  },
}

function calculcateMargin(windowWidth) {
  function formula(windowWidth, containerWidth) {
    return (windowWidth - containerWidth) / 2
  }

  let containerWidth = 0
  if (windowWidth < 768) return 20
  if (windowWidth >= 768 && windowWidth <= 991) {
    containerWidth = 723
    return formula(windowWidth, containerWidth)
  }
  if (windowWidth <= 992 && windowWidth) {
    containerWidth = 933
    return formula(windowWidth, containerWidth)
  }
  if (windowWidth >= 1200) {
    containerWidth = 1127
    return formula(windowWidth, containerWidth)
  }
  return 20
}
