// @flow
import React from 'react'

import styles from './styles.module.css'

const ColumnImage = ({ src, alt, }: { src: string, alt: string }) => (
  <img src={src} alt={alt} className={styles.image} />
)

export default ColumnImage
