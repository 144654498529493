// @flow
import * as React from "react"
import moment from "moment"
import { Dropdown } from "semantic-ui-react"

import SingleDatePicker from "components/SingleDatePicker"

type MetaType = {
  touched: boolean,
  error: React.Element<*>,
}

type InputType = {
  input: {},
  label: string,
  type: string,
  placeholder: string,
  meta: MetaType,
}

type DropdownType = {
  input: {
    value: string,
    onChange: Function,
  },
  label: string,
  meta: MetaType,
  options: [
    {
      text: string,
      value: string,
    },
  ],
}

type SingleDatePickerType = {
  input: {
    value: Date,
    onChange: Function,
  },
  meta: MetaType,
  label: string,
}

export const renderInput = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error },
}: InputType): React.Element<*> => (
  <div>
    <label>{label}</label>
    <input {...input} type={type} placeholder={placeholder} />
    {touched && error && error}
  </div>
)

export const renderDropdown = ({
  input,
  meta,
  options,
  label,
  ...rest
}: DropdownType) => (
  <div>
    <label>{label}</label>
    <Dropdown
      fluid
      className={"dropdown"}
      selection
      search
      value={input.value}
      onChange={(e, { value }) => {
        input.onChange(value)
      }}
      options={options}
      {...rest}
    />
  </div>
)

export const renderSingleDatePicker = ({
  input,
  meta,
  label,
}: SingleDatePickerType) => (
  <div>
    <label>{label}</label>
    <SingleDatePicker
      placeholder="Select date"
      selectMonthAndYear
      value={(input.value === "" ? null : input.value) || null}
      onChange={date => input.onChange(moment(date))}
    />
  </div>
)
