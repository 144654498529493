// @flow
import React, { useEffect, useState } from "react"
import { Grid } from "semantic-ui-react"
import { Link } from "react-router-dom"
import DOMPurify from "dompurify"
import {
  FacebookIcon,
  FacebookShareButton,
  LineShareButton,
  LineIcon,
  WhatsappIcon,
  WhatsappShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share"

import Button from "components/Button"
import styles from "./styles.module.css"

type PropTypes = {
  title: string,
  subtitle?: string,
  text?: string,
  label?: string, // mb object later with other props like onClick or to
  link?: string,
}

const SubjectHeaders = ({ title, subtitle, text, label, link }: PropTypes) => {
  const shareUrl = window.location.href

  const onModalOpen = () => {
    window.addEventListener("scroll", onModalClose)
    setModalState(true)
  }
  const onModalClose = () => {
    window.removeEventListener("scroll", onModalClose)
    setModalState(false)
  }

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", onModalClose)
    }
  }, [])

  const [isModalOpen, setModalState] = useState<boolean>(false)

  return (
    <div className={styles.container}>
      <Grid columns={"equal"} stackable>
        <Grid.Column width={label ? 11 : 16}>
          {title && (
            <div className={styles.titleContainer}>
              <h1>
                {title}{" "}
                <Button onClick={onModalOpen} className={styles.shareIcon}>
                  Share with friends
                </Button>
              </h1>
            </div>
          )}
        </Grid.Column>
        {label && (
          <Grid.Column>
            <Link to={link || "/"}>
              <Button simple className={styles.label}>
                {label}
              </Button>
            </Link>
          </Grid.Column>
        )}
      </Grid>
      {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
      {text && <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} />}

      <div className={`${styles.popup} ${isModalOpen ? styles.open : styles.closed}`}>
        <FacebookShareButton url={shareUrl} quote={title} hashtag="#TABLE" onClick={onModalClose}>
          <FacebookIcon />
        </FacebookShareButton>
        <EmailShareButton url={shareUrl} subject={title} body={shareUrl}>
          <EmailIcon />
        </EmailShareButton>
        <WhatsappShareButton url={shareUrl} title={title} onClick={onModalClose}>
          <WhatsappIcon />
        </WhatsappShareButton>
        <LineShareButton url={shareUrl} title={title} onClick={onModalClose}>
          <LineIcon />
        </LineShareButton>
      </div>
    </div>
  )
}

export default SubjectHeaders
