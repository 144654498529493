export const SET = "SET_USER"
export const UNSET = "UNSET_USER"

export const REGISTER = "register"
export const UNAUTHORIZED = "unauthorized"
export const AUTHORIZED = "authorized"

export const USERS = "USERS"
const which = USERS

export const CHANGE_PASSWORD_REQUEST = `${which}_CHANGE_PASSWORD_REQUEST`
export const CHANGE_PASSWORD_SUCCESS = `${which}_CHANGE_PASSWORD_SUCCESS`
export const CHANGE_PASSWORD_FAILURE = `${which}_CHANGE_PASSWORD_FAILURE`

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE"
