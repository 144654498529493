import { combineReducers } from "redux"
import { reducer as form } from "redux-form"

import { CLUBS } from "data/Clubs/constants"
import { CLUB } from "data/Club/constants"
import { BOOKINGS } from "data/Bookings/constants"
import { EVENTS } from "data/Events/constants"
import { EVENT } from "data/Event/constants"
import { DEALS } from "data/Deals/constants"
import { DEAL } from "data/Deal/constants"
import { LOCATIONS } from "data/Locations/constants"

import commonReducer from "utils/commonReducer"

import notifications from "wrappers/Notifications/reducer"

import user from "data/User/reducer"
import tables from "data/Tables/reducer"
import navigation from "data/Navigation/reducer"

export default combineReducers({
  notifications,
  user,
  tables,
  form,
  navigation,
  locations: commonReducer(LOCATIONS),
  clubs: commonReducer(CLUBS),
  club: commonReducer(CLUB),
  events: commonReducer(EVENTS),
  event: commonReducer(EVENT),
  deals: commonReducer(DEALS),
  deal: commonReducer(DEAL),
  bookings: commonReducer(BOOKINGS),
})
