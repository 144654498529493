import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"

import thunkMiddleware from "redux-thunk"
import logger from "redux-logger"
import apiMiddleware from "middleware/api"
import notificationsMiddleware from "middleware/notifications"

import combinedReducers from "./combinedReducers"

const store = createStore(
  combinedReducers,
  composeWithDevTools(
    applyMiddleware(thunkMiddleware),
    applyMiddleware(apiMiddleware),
    applyMiddleware(notificationsMiddleware),
    applyMiddleware(logger)
  )
)

export default store
