// @flow
import AuthorizationForm from "components/AuthorizationForm"
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ReactPixel from "react-facebook-pixel"

import type { UserType } from "data/User/types"
import { instance as axios } from "instances/Axios"
import { pushNotification } from "wrappers/Notifications/actions"
import type { FieldObjectType } from "components/AuthorizationForm"
import authService from "instances/AuthService"
import { includesNumericAndLetters } from "wrappers/FormValidation/validations"

// import styles from "./styles.module.css"

type PropType = {
  user: UserType,
  setFakeUser: Function,
  location: { pathname: string },
  pushNotification: Function,
}

type StateType = {
  fields: Array<FieldObjectType>,
  fieldValues: any,
  email: ?string,
}

class Register extends React.Component<PropType, StateType> {
  state = {
    fieldValues: {},
    email: null,
    fields: [
      {
        name: "name",
        label: "Full Name",
        type: "text",
      },
      {
        name: "email",
        label: "E-mail",
        type: "email",
        placeholder: "",
      },
      {
        name: "phone_number",
        label: "Phone",
        type: "text",
        placeholder: "",
      },
      {
        name: "password",
        label: "Password",
        type: "password",
        placeholder: "",
      },
      {
        name: "passwordConfirm",
        label: "Confirm Password",
        type: "password",
      },
    ],
  }

  handleFormStateChange(fields) {
    this.setState({ fieldValues: fields })
  }

  async handleSubmit(e: SyntheticEvent<*>) {
    e.preventDefault()
    try {
      const { name, password, email, phone_number } = this.state.fieldValues
      await axios.post("/users/register", {
        name,
        email,
        password,
        phone_number,
      })
      this.props.pushNotification({ content: "User created" })
      authService.login({ email, password })
      ReactPixel.track("CompleteRegistration")
      // Ping Facebook Pixel about the event
      try {
        if (window && window.fbq && typeof window.fbq === "function") {
          window.fbq("track", "CompleteRegistration")
        }
        if (window && window.gtag && typeof window.gtag === "function") {
          window.gtag("config", "AW-757063567")
          window.gtag("event", "conversion", { send_to: "AW-757063567/Nwk0CIOdkJcBEI-__-gC" })
        }
      } catch (e) {
        console.error("Facebook Pixel error", e)
      }
    } catch (e) {
      console.log(e)
      if (e.response) {
        return this.props.pushNotification({
          content:
            (e &&
              e.response &&
              e.response.data &&
              e.response.data.errors &&
              Array.isArray(e.response.data.errors) &&
              e.response.data.errors.join(", ")) ||
            "Failed to create new account",
        })
      }
      throw e
    }
  }

  render() {
    return (
      <AuthorizationForm
        schema={schema}
        fields={this.state.fields}
        title={"Create Your TABLE Account"}
        handleSubmit={(e) => this.handleSubmit(e)}
        submitButtonText={"Register"}
        register={true}
        onChange={(fields) => this.handleFormStateChange(fields)}
      />
    )
  }
}

const schema = {
  name: {
    required: true,
    minLength: 2,
  },
  email: {
    // required: true,
    // minLength: 2,
    // customValidation: {
    //   isValid: value => isEmail(value),
    //   errorMessage: "Must be valid email",
    //   on: "blur",
    // },
  },
  phone_number: {
    required: true,
  },
  password: {
    required: true,
    minLength: 8,
    maxLength: 256,
    customValidation: {
      isValid: (value) => includesNumericAndLetters(value),
      errorMessage: "Must include combination of numbers and letters",
      on: "blur",
    },
  },
  passwordConfirm: {
    required: true,
    confirm: "password",
  },
}

const mstp = ({ user }) => ({ user })
const mdtp = (dispatch) => ({
  pushNotification: bindActionCreators(pushNotification, dispatch),
})
export default connect(mstp, mdtp)(Register)
