// @flow
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import type { UserType } from "data/User/types"
import AuthorizationForm from "components/AuthorizationForm"
import { isEmail } from "wrappers/FormValidation/validations"
import { _oldPushNotification } from "wrappers/Notifications/actions"
import styles from "./styles.module.css"
import authService from "instances/AuthService"
import { includesNumericAndLetters } from "wrappers/FormValidation/validations"

type PropType = {
  user: UserType,
  pushNotification: Function,
}

type StateType = {
  email: string,
  password: string,
  fieldValues: any,
}

class Login extends React.Component<PropType, StateType> {
  state = {
    email: "",
    password: "",
    fieldValues: {},
  }

  handleInputChange(which: string, value: string) {
    const state = { ...this.state }
    state[which] = value
    this.setState(state)
  }

  handleFormStateChange(fieldValues) {
    this.setState({ fieldValues })
  }

  async handleSubmit(e: SyntheticEvent<*>) {
    e.preventDefault()
    const { email, password } = this.state.fieldValues
    try {
      await authService.login({
        email,
        password,
      })
      this.props.pushNotification({ content: "Logged in" })
    } catch (e) {
      if (e.response && e.response.data && e.response.data.errors) {
        return this.props.pushNotification({
          content: e.response.data.errors.join(", "),
        })
      }
      throw e
    }
  }

  render() {
    return (
      <AuthorizationForm
        onChange={fieldValues => this.handleFormStateChange(fieldValues)}
        title={"Sign in below"}
        handleSubmit={e => this.handleSubmit(e)}
        submitButtonText={"Login"}
        fields={fields}
        schema={schema}
        underneathComponent={
          <div className={styles.underneathContainer}>
            <p>
              <a className={"link"} href={"/forgot-password"}>
                Forgot password?
              </a>
            </p>
          </div>
        }
      />
    )
  }
}

const fields = [
  {
    name: "email",
    label: "E-mail",
    type: "email",
    placeholder: "yourname@example.com",
  },
  {
    name: "password",
    label: "Password",
    type: "password",
    placeholder: "",
  },
]

const schema = {
  email: {
    required: true,
    minLength: 2,
    customValidation: {
      isValid: value => isEmail(value),
      errorMessage: "Must be valid email",
      on: "blur",
    },
  },
  password: {
    required: true,
    minLength: 8,
    maxLength: 256,
    customValidation: {
      isValid: value => includesNumericAndLetters(value),
      errorMessage: "Must include combination of numbers and letters",
      on: "blur",
    },
  },
  // "passwordConfirm": {
  //   required: true,
  //   confirm: "password",
  // },
}

const mstp = ({ user }) => ({ user })
const mdtp = dispatch => ({
  pushNotification: bindActionCreators(_oldPushNotification, dispatch),
})
export default connect(
  mstp,
  mdtp
)(Login)
