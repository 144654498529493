// @flow
import * as React from "react"
import { Grid } from "semantic-ui-react"
import { reduxForm, Field } from "redux-form"

import Button from "components/Button"
import { renderInput } from "utils/renderField"

import {
  required,
  minLength8,
  matchPasswordField,
  includesNumericAndLetters,
} from "utils/validations"

type PropTypes = {
  handleSubmit: Function,
  changePassword: Function,
  pristine: boolean,
  submitting: boolean,
  valid: boolean,
}

const PasswordChangeForm = ({
  handleSubmit,
  changePassword,
  pristine,
  submitting,
  valid,
}: PropTypes) => (
  <form onSubmit={handleSubmit(changePassword)}>
    <Grid stackable columns={"equal"}>
      <Grid.Column>
        <label>Current Password</label>
        <Field
          name={"oldPassword"}
          component={renderInput}
          type={"password"}
          placeholder={"Enter current password"}
          validate={[required, minLength8, includesNumericAndLetters]}
        />
      </Grid.Column>
      <Grid.Column>
        <label>New password</label>
        <Field
          name={"password"}
          component={renderInput}
          type={"password"}
          placeholder={"Enter new password"}
          validate={[required, minLength8, includesNumericAndLetters]}
        />
      </Grid.Column>
      <Grid.Column>
        <label>Confirm new Password</label>
        <Field
          name={"passwordConfirm"}
          component={renderInput}
          type={"password"}
          placeholder={"Confirm new password"}
          validate={[
            required,
            minLength8,
            matchPasswordField,
            includesNumericAndLetters,
          ]}
        />
      </Grid.Column>
      <Grid.Column className={"labelless"}>
        <Button simple disabled={pristine || submitting || !valid}>
          Change password
        </Button>
      </Grid.Column>
    </Grid>
  </form>
)

export default reduxForm({
  form: "PasswordChangeForm",
})(PasswordChangeForm)
