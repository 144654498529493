// @flow
import React from "react"

import icon from "assets/Icons/time-color.svg"
import styles from "./styles.module.css"

type PropTypes = {
  start: string,
  end: string,
}

const TimeLabel = ({ start, end }: PropTypes) => (
  <div className={styles.masterContainer}>
    <img src={icon} alt={"Time"} />
    <span>{start}</span>
    <span className={styles.separetor}>{"-"}</span>
    <span>{end}</span>
  </div>
)

export default TimeLabel
