// @flow
import React from "react"
import { connect } from "react-redux"
import { Icon } from "semantic-ui-react"
import { Link, withRouter } from "react-router-dom"

import PopupMenu from "components/PopupMenu"
import authService from "instances/AuthService"
import type { UserType } from "data/User/types"
import Button from "components/Button"

import styles from "./styles.module.css"

type PropTypes = {
  user: UserType,
}

class HeaderUserMenu extends React.PureComponent<PropTypes> {
  render() {
    return this.props.user && this.props.user.isLoggedIn ? (
      <PopupMenu
        options={[
          {
            text: "Profile settings",
            onClick: () => this.props.history.push("/settings"),
          },
          { text: "Logout", onClick: () => authService.logout() },
        ]}
        popupProps={{ style: { transform: "translateY(-1em)", zIndex: 2100 } }}
        trigger={onClick => (
          <div
            onClick={onClick}
            className={styles.userContainer}
            name={"header-user-container"}
          >
            <React.Fragment>
              {/* <img
            alt="Avatar"
            src={"https://ui-avatars.com/api/?name=John-Smith&length=2"}
          /> */}
              <React.Fragment>
                <span>
                  {(this.props.user && this.props.user.fullName) ||
                    this.props.user.email}
                </span>
                <Icon className={styles.icon} name="caret down" />
              </React.Fragment>
            </React.Fragment>
          </div>
        )}
      />
    ) : (
      <div className={styles.noUserContainer}>
        <Link to="/login">Login</Link>
        <Link to="/register">
          <Button basic>Signup</Button>
        </Link>
      </div>
    )
  }
}
const mstp = ({ user }) => ({ user })

export default connect(
  mstp,
  null
)(withRouter(HeaderUserMenu))
