// @flow
import React from "react"
import { Container } from "semantic-ui-react"

import styles from "./styles.module.css"

type PropTypes = {
  title?: string,
  subtitle?: string,
  children: any,
  filter?: any,
  image?: string,
  text?: string,
  nowrap?: boolean,
}

const Cover = ({ title, subtitle, children, filter, image, text, nowrap }: PropTypes) => (
  <div styles={styles.container}>
    <div
      className={styles.contentContainer}
      style={{
        backgroundImage: image ? `url(${image})` : undefined,
      }}
    >
      {image && <div className={styles.contentContainerCover} />}
      <Container className={nowrap ? styles.contentNowrap : styles.content}>
        <h1>{title}</h1>
        {subtitle && <h2>{subtitle}</h2>}
        {text && <p>{text}</p>}
      </Container>
      {filter && <Container>{filter}</Container>}
      <Container>{children}</Container>
    </div>
  </div>
)

export default Cover
