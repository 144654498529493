// @flow
import axios from "axios"
import authService from "instances/AuthService"

export const instance = axios.create({
  baseURL: (window._env_ && window._env_.API_URL) || "",
  timeout: 30000,
})

let authInterceptor = null
let alreadyRetried = false

export function authifyInstance(accessToken: string) {
  instance.defaults.headers["Authorization"] = `Bearer ${accessToken}`
  authInterceptor = instance.interceptors.response.use(
    (config) => config,
    async (error) => {
      if (error.response && error.response.status === 401) {
        const { config: originalRequest } = error
        try {
          const { accessToken } = await authService.refreshTokens()
          originalRequest.headers["Authorization"] = `Bearer ${accessToken}`
          if (!alreadyRetried) {
            alreadyRetried = true
            return instance(originalRequest)
          } else return authService.logout()
        } catch (e) {
          console.log(e)
          authService.logout()
          return e
        }
        return Promise.reject(error)
      }
    }
  )
}

export function unauthifyInstance() {
  delete instance.defaults.headers["Authorization"]
  instance.interceptors.response.eject(authInterceptor)
  return authInterceptor
}
