// @flow
import React from "react"

import apple from "assets/Icons/apple.svg"

import styles from "./styles.module.css"

const AppleButton = () => (
  <div className={`${styles.container} ${styles.apple}`}>
    <div className={styles.contentContainer}>
      <div className={styles.logoContainer}>
        <img src={apple} alt="Apple store icon" />
      </div>
      <div className={styles.textContainer}>
        <div>
          <p>Soon on the</p>
          <p>Apple Store</p>
        </div>
      </div>
    </div>
  </div>
)

export default AppleButton
