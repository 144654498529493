// @flow
import React from "react"

import styles from "./styles.module.css"

const List = ({ items }: { items: [{ component: any, header: any }] }) =>
  items.map(({ component, header }, i) => (
    <div key={i} className={styles.listContainer}>
      <div className={styles.list}>
        <div className={styles.listHeader}>{header}</div>
        <div className={styles.listValue}>{component}</div>
      </div>
    </div>
  ))

export default List
