// @flow
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Grid, Container, Divider, Dropdown } from "semantic-ui-react"
import { Link } from "react-router-dom"

import withPageBackground from "wrappers/PageBackground"
import Covered from "components/Covered"
import Item from "components/Item"
import Loading from "components/Loading"

import { fetchClubs } from "data/Clubs/actions"

import newvenues from "assets/Images/newvenues.webp"
import dropdownize from "utils/dropdownize"
import Cookies from "universal-cookie"
const cookies = new Cookies()

type PropTypes = {
  fetchClubs: Function,
  clubs: {
    readLoading: boolean,
    segments: {},
  },
}

type StateTypes = {
  locations: ?Array,
  location_id: ?String,
}

class Venues extends React.Component<PropTypes, StateTypes> {
  state = {
    locations: [],
    location_id: null,
  }

  componentDidMount() {
    if (this.props.locations.readSuccess) {
      const defaultCity = cookies.get("selectedLocation") ? cookies.get("selectedLocation") : "Bangkok"
      const locations = dropdownize(this.props.locations.segments, "country")
      const defaultLocation = locations.find((location) => location.text === defaultCity)

      if (defaultLocation) {
        this.setState({ ...this.state, location_id: defaultLocation.value, locations: locations }, () =>
          this.props.fetchClubs(defaultLocation.value)
        )
      } else {
        this.props.fetchClubs()
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.locations.readSuccess && this.props.locations.readSuccess) {
      const defaultCity = cookies.get("selectedLocation") ? cookies.get("selectedLocation") : "Bangkok"
      const locations = dropdownize(this.props.locations.segments, "country")
      const defaultLocation = locations.find((location) => location.text === defaultCity)
      if (defaultLocation) {
        this.setState({ ...this.state, location_id: defaultLocation.value, locations: locations }, () =>
          this.props.fetchClubs(defaultLocation.value)
        )
      } else {
        this.props.fetchClubs()
      }
    }
  }

  handleLocationChange = (e, data) => {
    const chosenLocation = data.options.find((location) => location.value === data.value)
    if (chosenLocation) {
      cookies.set("selectedLocation", chosenLocation.text, { path: "/" })
      this.setState({ ...this.state, location_id: data.value }, () =>
        this.props.fetchClubs(this.state.location_id)
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={"fadeIn"}>
          <Covered
            title={"Book Tables & Packages"}
            filter={
              this.state.locations.length > 0 && (
                <Dropdown
                  className="no-max-height"
                  style={{ "font-weight": "bold", margin: "20px 0 0 0" }}
                  placeholder="Select your Location"
                  options={this.state.locations}
                  selection
                  defaultValue={this.state.location_id}
                  onChange={this.handleLocationChange}
                />
              )
            }
          />
          <Divider hidden />
          <Container>
            <Grid doubling stackable columns={3}>
              <Loading isLoading={this.props.clubs.readLoading}>
                {Object.keys(this.props.clubs.segments).map((id, i) => {
                  const { _id, slug, name, music_type, address, images, description, hidden } =
                    this.props.clubs.segments[id]
                  if (hidden) return null
                  return (
                    <Grid.Column key={`venture-${i}`}>
                      <Link to={`/club/${slug || _id}`}>
                        <Item
                          bookNowLink={`/tables/${_id}`}
                          title={name}
                          subtitle={description || `${music_type}, ${address}`}
                          image={images && images[0] && images[0].path}
                          button={"Find out more"}
                        />
                      </Link>
                    </Grid.Column>
                  )
                })}
              </Loading>
            </Grid>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

const mstp = ({ clubs, locations }) => ({ clubs, locations })
const mdtp = (dispatch) => ({
  fetchClubs: bindActionCreators(fetchClubs, dispatch),
})

export default connect(mstp, mdtp)(withPageBackground({ image: newvenues })(Venues))
