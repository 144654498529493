// @flow
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Grid, Divider, Container, Dropdown } from "semantic-ui-react"
import Swiper from "react-id-swiper"
import { Link } from "react-router-dom"
import moment from "moment"

import { fetchEvents } from "data/Events/actions"
import { fetchClubs } from "data/Clubs/actions"

import withPageBackground from "wrappers/PageBackground"
import EventsList from "containers/EventsList"
import SpotlightItem from "components/SpotlightItem"
import SingleDatePicker from "components/SingleDatePicker"
import Covered from "components/Covered"
import Loading from "components/Loading"

import cover from "assets/Images/club-cover.webp"
import styles from "./styles.module.css"
import dropdownize from "utils/dropdownize"
import Cookies from "universal-cookie"
const cookies = new Cookies()

type PropTypes = {
  fetchClubs: Function,
  fetchEvents: Function,
  events: {
    readLoading: boolean,
    segments: {},
  },
}
type StateTypes = {
  city: string,
  date: ?Date,
  spotlightEvents: Array<any>,
  locations: ?Array,
  location_id: ?String,
}

class Events extends React.Component<PropTypes, StateTypes> {
  state = {
    city: cookies.get("selectedLocation") || "Bangkok",
    date: null,
    spotlightEvents: [],
    locations: [],
    location_id: null,
  }

  componentDidMount() {
    if (this.props.locations.readSuccess) {
      const defaultCity = cookies.get("selectedLocation") ? cookies.get("selectedLocation") : "Bangkok"
      const locations = dropdownize(this.props.locations.segments, "country")
      const defaultLocation = locations.find((location) => location.text === defaultCity)

      if (defaultLocation) {
        this.setState({ ...this.state, location_id: defaultLocation.value, locations: locations }, () => {
          this.props.fetchClubs(this.state.location_id)
          this.props.fetchEvents({ location_id: defaultLocation.value })
        })
      } else {
        this.props.fetchEvents({})
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.locations.readSuccess && this.props.locations.readSuccess) {
      const defaultCity = cookies.get("selectedLocation") ? cookies.get("selectedLocation") : "Bangkok"
      const locations = dropdownize(this.props.locations.segments, "country")
      const defaultLocation = locations.find((location) => location.text === defaultCity)
      if (defaultLocation) {
        this.setState({ ...this.state, location_id: defaultLocation.value, locations: locations }, () => {
          this.props.fetchClubs(this.state.location_id)
          this.props.fetchEvents({ location_id: defaultLocation.value })
        })
      } else {
        this.props.fetchEvents({})
      }
    }

    if (!prevProps.events.readSuccess && this.props.events.readSuccess) {
      this.generateSpotlight(prevProps)
    }
  }

  generateSpotlight() {
    const spotlightEvents = Object.keys(this.props.events.segments)
      .map((key) => {
        return this.props.events.segments[key]
      })
      .sort(({ date: a }, { date: b }) => new Date(a) - new Date(b))

    this.setState({ spotlightEvents })
  }

  handleDateChange(date: Date) {
    this.setState({ ...this.state, date }, () =>
      this.props.fetchEvents({ from: this.state.date, location_id: this.state.location_id })
    )
  }

  handleLocationChange = (e, data) => {
    const chosenLocation = data.options.find((location) => location.value === data.value)
    if (chosenLocation) {
      cookies.set("selectedLocation", chosenLocation.text, { path: "/" })
      this.setState({ ...this.state, location_id: data.value }, () => {
        this.props.fetchClubs(this.state.location_id)
        this.props.fetchEvents({ from: this.state.date, location_id: this.state.location_id })
      })
    }
  }

  render() {
    const { spotlightEvents } = this.state
    return (
      <div className={"fadeIn"}>
        <Covered
          title={"Experience The Best Events"}
          filter={
            this.state.locations.length > 0 && (
              <Dropdown
                className="no-max-height"
                style={{ "font-weight": "bold", margin: "20px 0 0 0" }}
                placeholder="Select your Location"
                options={this.state.locations}
                selection
                defaultValue={this.state.location_id}
                onChange={this.handleLocationChange}
              />
            )
          }
        />
        <div className={styles.masterContainer}>
          <Loading isLoading={this.props.events.readLoading}>
            {this.props.windowSize && this.props.windowSize.windowWidth && spotlightEvents.length ? (
              <React.Fragment>
                <Container>
                  <h3>Spotlight</h3>
                </Container>
                <Swiper
                  shouldSwiperUpdate
                  centerInsufficientSlides
                  slidesOffsetBefore={
                    spotlightEvents.length > 1
                      ? this.props.windowSize.windowWidth && calculcateMargin(this.props.windowSize.windowWidth)
                      : undefined
                  }
                  slidesOffsetAfter={spotlightEvents.length > 1 ? 20 : undefined}
                  slidesPerView={3}
                  spaceBetween={30}
                  breakpoints={swipeBreakpoints}
                >
                  {this.state.spotlightEvents.map(({ _id, name, type, spotlight, date, images }, i) => {
                    if (!spotlight) return null
                    return (
                      <div className={styles.itemContainer} key={i}>
                        <Link to={`/event/${_id}`}>
                          <SpotlightItem
                            title={name}
                            subtitle={type}
                            image={images && images[0] && images[0].path}
                            label={daysLeft(date)}
                            // button={"Find out more"}
                          />
                        </Link>
                      </div>
                    )
                  })}
                </Swiper>
              </React.Fragment>
            ) : null}
          </Loading>
          <Divider hidden style={{ margin: "2em 0" }} />
          <Container>
            <Grid columns={2}>
              <Grid.Column>
                <h3>All Events</h3>
              </Grid.Column>
              <Grid.Column textAlign={"right"}>
                <SingleDatePicker
                  selectionFrom
                  value={this.state.date ? moment(this.state.date) : null}
                  onChange={(date) => this.handleDateChange(date)}
                />
              </Grid.Column>
            </Grid>
            <EventsList />
          </Container>
        </div>
      </div>
    )
  }
}

const daysLeft = (date) => {
  const diff = moment(date).diff(moment(Date.now()), "days") + 1
  const hourDiff = moment(date).diff(moment(Date.now()), "hours")
  if (hourDiff < 0) return "Today"
  return `${diff} day${diff === 1 ? "" : "s"} left`
}

const mstp = ({ events, clubs, locations }) => ({ events, clubs, locations })
const mdtp = (dispatch) => ({
  fetchEvents: bindActionCreators(fetchEvents, dispatch),
})

export default connect(mstp, mdtp)(withPageBackground({ image: cover })(Events))

const swipeBreakpoints = {
  "10000": {
    slidesPerView: 8,
    spaceBetween: 20,
  },
  "3000": {
    slidesPerView: 6,
    spaceBetween: 20,
  },
  "2000": {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  "1400": {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  "768": {
    slidesPerView: 2,
    spaceBetween: 20,
  },
  "450": {
    slidesPerView: 1.2,
    spaceBetween: 20,
  },
}

function calculcateMargin(windowWidth) {
  function formula(windowWidth, containerWidth) {
    return (windowWidth - containerWidth) / 2
  }

  let containerWidth = 0
  if (windowWidth < 768) return 20
  if (windowWidth >= 768 && windowWidth <= 991) {
    containerWidth = 723
    return formula(windowWidth, containerWidth)
  }
  if (windowWidth <= 992 && windowWidth) {
    containerWidth = 933
    return formula(windowWidth, containerWidth)
  }
  if (windowWidth >= 1200) {
    containerWidth = 1127
    return formula(windowWidth, containerWidth)
  }
  return 20
}
