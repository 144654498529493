// @flow
import { CALL_API } from "middleware/api"

import { READ_REQUEST, READ_SUCCESS, READ_FAILURE } from "./constants"
import objectifyArray from "utils/objectifyArray"

export const fetchDeals = (location_id?: string) => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],

    promise: (httpClient) =>
      httpClient({
        url: "/deals",
        method: "get",
        params: { location_id },
      }),
    payloadModifier: (payload) => {
      return payload.map((e) => {
        e.override_prices = objectifyArray(e.override_prices, "table_id")
        return e
      })
    },
  },
})
