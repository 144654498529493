// @flow

import React from "react"
import styles from "./styles.module.css"

type PropTypes = {
  content: Array<*>,
}

const ErrorText = ({ content }: PropTypes) =>
  content ? (
    <span className={styles.errorText}>{` (${content.join(". ")})`}</span>
  ) : null

export default ErrorText
