// @flow
import React from "react"
import LazyLoad from "react-lazyload"
import { Loader } from "semantic-ui-react"

import Button from "components/Button"

import styles from "./styles.module.css"

const Item = ({
  title,
  subtitle,
  image,
  buttonText,
  disabled,
}: {
  title: string,
  subtitle: string,
  image: string,
  buttonText: string,
  disabled?: Boolean,
}) => (
  <div
    className={`${styles.masterContainer} ${disabled ? styles.disabled : ""}`}
  >
    <div className={styles.imageContainer}>
      <LazyLoad height={200} placeholder={<Loader active />}>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      </LazyLoad>
    </div>
    <h1>{title}</h1>
    <h2>{subtitle}</h2>
    <div className={styles.buttonContainer}>
      <Button basic>{buttonText}</Button>
    </div>
  </div>
)

export default Item
