// @flow
import React from "react"
import { Icon } from "semantic-ui-react"

import styles from "./styles.module.css"

const Button = ({
  children,
  name,
  fluid,
  onClick,
  style,
  disabled,
  basic,
  small,
  colored,
  simple,
  compact,
  icon,
  className,
  shadowed,
  secondary,
}: {
  compact?: boolean,
  children: any,
  name?: string,
  fluid: Boolean,
  onClick: Function,
  style: any,
  disabled?: Boolean,
  basic?: Boolean,
  small?: Boolean,
  colored?: Boolean,
  fluid?: Boolean,
  simple?: Boolean, // if true, primary color is used instead of gradient
  icon?: string,
  className?: string,
  shadowed?: Boolean,
  secondary?: Boolean,
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    name={name}
    className={`${className || ""} ${colored ? styles.colored : ""} ${fluid ? styles.fluid : ""} ${
      basic ? styles.basicElement : styles.element
    } ${simple ? styles.simple : ""} ${shadowed ? styles.shadowed : ""} ${secondary ? styles.secondary : ""} ${
      small ? styles.small : ""
    }`}
    style={{
      padding: compact ? "0.5em 1em" : undefined,
      cursor: disabled ? "not-allowed" : "pointer",
      opacity: disabled ? 0.5 : 1,
      width: fluid ? "100%" : undefined,
      ...style,
    }}
  >
    {icon && <Icon name={icon} />}
    {children}
  </button>
)

export default Button
