// @flow
import React from "react"
import moment from "moment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { fetchBookings, cancelBookingAndRefetch } from "data/Bookings/actions"
import rowsCreator from "utils/rowsCreator"
import TableSection from "components/TableSection/TableSection"
import ColumnImage from "components/ColumnImage"
import Button from "components/Button"
import TitleAndSubtitle from "components/TitleAndSubtitle"
import Covered from "components/Covered"
import Loading from "components/Loading"

import placeholder from "assets/Images/new-placeholder.webp"
import { COVER, LABEL, LIST, TITLE, STATUS_BUTTON, ACTIONS } from "components/TableSection/constants"

type PropTypes = {
  fetchBookings: Function,
}

class Bookings extends React.Component<PropTypes> {
  componentDidMount() {
    this.props.fetchBookings()
  }

  render() {
    return (
      <div className={"fadeIn"}>
        <Covered
          title="Manage your Bookings"
          subtitle={
            Object.keys(this.props.bookings.segments).length
              ? null
              : "You have no bookings yet - head over to our venue or events page to check out what's hot 🔥"
          }
        >
          <Loading isLoading={this.props.bookings.readLoading}>
            <div>
              {Object.keys(this.props.bookings.segments).length ? (
                <TableSection
                  headers={headers}
                  rows={rowsCreator(segmentToRow, this.props.bookings.segments, {
                    cancelBookingAndRefetch: (id) => this.props.cancelBookingAndRefetch(id),
                  })}
                />
              ) : null}
            </div>
          </Loading>
        </Covered>
      </div>
    )
  }
}

const mstp = ({ bookings }) => ({ bookings })
const mdtp = (dispatch) => ({
  fetchBookings: bindActionCreators(fetchBookings, dispatch),
  cancelBookingAndRefetch: bindActionCreators(cancelBookingAndRefetch, dispatch),
})

export default connect(mstp, mdtp)(Bookings)

const headers = [
  { component: <span /> },
  { component: <span>{"Club/Party"}</span> },
  { component: <span>{"Date"}</span> },
  { component: <span>{"Max guests"}</span> },
  { component: <span>{"Table"}</span> },
  { component: <span>{"Status"}</span> },
  { component: <span>{"Arrive before"}</span> },
  { component: <span>{"Min spent"}</span> },
  { component: <span /> },
]

function getBookingStatus(confirmed, transaction_status) {
  if (confirmed) {
    return "Confirmed"
  }
  if (transaction_status === "completed") {
    return "Paid"
  }
  return "Pending"
}

function segmentToRow(
  {
    _id,
    club,
    event,
    table,
    deal,
    club_deal,
    date,
    confirmed,
    transaction_status,
    minimum_spend,
    qty,
    images_event,
    images_club,
    images_deal,
    images_club_deal,
  },
  { cancelBookingAndRefetch }
) {
  return {
    columns: [
      {
        component: (
          <span>
            <ColumnImage src={placeholder} alt={"Visual of event or club"} />
          </span>
        ),
        mobileComponent: (
          <img
            style={{ height: "70%", margin: "auto" }}
            // style={
            //   (event && images_event.length > 0 && images_event[0].path) ||
            //   (club && images_club.length > 0 && images_club[0].path) ||
            //   (deal && images_deal.length > 0 && images_deal[0].path) ||
            //   (club_deal && images_club_deal.length > 0 && images_club_deal[0].path)
            //     ? {
            //         width: "calc(100% + 2em)",
            //         margin: "-1em",
            //       }
            //     : { height: "100%" }
            // }
            src={
              // (event && images_event.length > 0 && images_event[0].path) ||
              // (club && images_club.length > 0 && images_club[0].path) ||
              // (deal && images_deal.length > 0 && images_deal[0].path) ||
              // (club_deal && images_club_deal.length > 0 && images_club_deal[0].path) ||
              placeholder
            }
            alt={"Visual of event or club"}
          />
        ),
        position: COVER,
      },
      {
        position: TITLE,
        component: (
          <TitleAndSubtitle
            title={(event && event.name) || (deal && deal.name) || "No event"}
            subtitle={(club && club.name) || (club_deal && club_deal.name) || "Unknown club name"}
          />
        ),
      },
      {
        component: <span>{date ? moment(date).format("MMM DD") : "Unknown date"}</span>,
        position: LABEL,
      },
      {
        component: <span>{(table ? table.capacity : qty ? qty : 1) || "Unknown capacity"}</span>,
        position: LIST,
        header: "Max guests",
      },
      {
        component: <span>{(table && table.name) || (deal && "-") || "Unkown table name"}</span>,
        position: LIST,
        header: "Table",
      },
      {
        component: <span>{getBookingStatus(confirmed, transaction_status)}</span>,
        mobileComponent: (
          <Button basic colored>
            {confirmed ? "Confirmed" : "Pending"}
          </Button>
        ),
        position: STATUS_BUTTON,
      },
      {
        component: <span>{(table && table.arrival_time) || (deal && "-") || "Unkown arrival time"}</span>,
        position: LIST,
        header: "Arrive by",
      },
      {
        component: (
          <span>
            ฿{" "}
            {table
              ? (table &&
                  table._id &&
                  event &&
                  event.override_prices &&
                  event.override_prices[table._id] &&
                  event.override_prices[table._id].price) ||
                (table && (minimum_spend || table.minimum_spend)) ||
                (table.minimum_spend === 0 ? 0 : "Unkown min. spend")
              : deal && deal._id && (deal.minimum_spend || deal.minimum_spend === 0)
              ? qty
                ? (minimum_spend || deal.minimum_spend) * qty
                : minimum_spend || deal.minimum_spend
              : "Unkown min. spend"}
          </span>
        ),
        position: LIST,
        header: "Min spend",
      },
      {
        component: (
          <Button fluid basic onClick={() => cancelBookingAndRefetch(_id)}>
            Cancel reservation
          </Button>
        ),
        position: ACTIONS,
      },
    ],
  }
}
