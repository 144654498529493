// @flow
import * as React from "react"
import { Container } from "semantic-ui-react"

import styles from "./styles.module.css"

type PropTypes = {}

class Page extends React.Component<PropTypes> {
  render() {
    return (
      <Container className={`${styles.container} fadeIn`}>
        {this.props.content}
      </Container>
    )
  }
}

export default Page
