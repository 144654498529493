import { PUSH_NOTIFICATION, PULL_NOTIFICATION } from "./constants"

const notificationsReducer = (
  state = {
    active: [],
  },
  action
) => {
  switch (action.type) {
    case PUSH_NOTIFICATION:
      return (() => {
        const active = [...state.active, action.payload]
        return {
          ...state,
          active,
        }
      })()
    case PULL_NOTIFICATION:
      return (() => {
        const active = state.active.filter(e => e.id !== action.payload)
        return {
          ...state,
          active,
        }
      })()
    default:
      return state
  }
}

export default notificationsReducer
