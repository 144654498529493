// @flow
import React from "react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import authService from "instances/AuthService"
import { _oldPushNotification } from "wrappers/Notifications/actions"

type PropTypes = {
  pushNotification: Function,
}

class Logout extends React.Component<PropTypes> {
  componentDidMount() {
    this.props.pushNotification({ content: "Logged out" })
    authService.logout()
  }

  render() {
    return <Redirect to="/" />
  }
}

const mdtp = dispatch => ({
  pushNotification: bindActionCreators(_oldPushNotification, dispatch),
})

export default connect(
  null,
  mdtp
)(Logout)
