// @flow
import React from "react"
import moment from "moment"
import { Container, Divider, Grid, Responsive, Loader } from "semantic-ui-react"

import Back from "components/Back"
import TitleAndSubtitle from "components/TitleAndSubtitle"
import TableItem from "components/TableItem"
import DealCard from "components/DealCard"
import SingleDatePicker from "components/SingleDatePicker"

import { fetchTablesAndStatuses } from "data/Tables/actions"
import { bookTableAndRedirect } from "data/Bookings/actions"
import { bookTableAndPay } from "data/Omise/actions"
import { setLocation } from "data/Navigation/actions"
import { fetchDeals } from "data/Deals/actions"

import { useSelector, useDispatch } from "react-redux"

import styles from "./styles.module.css"
import DOMPurify from "dompurify"

const BookDeal = ({ location, history }) => {
  const [, , clubId] = location.pathname.split("/")

  const dispatch = useDispatch()

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn)
  const user = useSelector((state) => state.user)
  const clubs = useSelector((state) => state.clubs)
  const allDealsList = useSelector((state) => state.deals.segments)
  const readSuccess = useSelector((state) => state.deals.readSuccess)
  const readLoading = useSelector((state) => state.deals.readLoading)

  const [active, setActive] = React.useState(null)
  const [selectedDate, setSelectedDate] = React.useState(null)
  const [dealsList, setDealsList] = React.useState({})

  React.useEffect(() => {
    dispatch(fetchDeals())
    dispatch(fetchTablesAndStatuses({ club: clubId }))
  }, [])

  React.useEffect(() => {
    if (allDealsList) {
      const filteredDeals = Object.values(allDealsList).filter(({ club_id }) => club_id === clubId)
      const deals = {}
      filteredDeals.forEach((deal) => {
        deals[deal._id] = deal
      })
      setDealsList(deals)
    }
  }, [allDealsList])

  React.useEffect(() => {
    if (clubs.readSuccess) {
      const club = clubs.segments[clubId]

      if (club.booked_before) {
        setSelectedDate(moment().add(club.booked_before, "d"))
      }
    }
  }, [clubs])

  const rememberAndRedirect = () => {
    const locationToRemember = `${location.pathname}${location.search}`
    dispatch(setLocation(locationToRemember))
    history.push("/confirmation")
  }

  const handleDateChange = (date: Date) => {
    setSelectedDate(date)
  }

  const club = clubs.segments[clubId]
  const activeItem = active && dealsList[active]

  const onBookClick = (qty: number, totalSpent: number, promotion: string) => {
    if (isLoggedIn) {
      dispatch(
        bookTableAndRedirect(
          {
            name: activeItem && activeItem.name,
            date: selectedDate ? selectedDate : moment(),
            deal_id: active,
            transaction_status: "invoiced",
            contact: user.phone || user.email,
            comments: activeItem && activeItem.conditions,
            minSpend: totalSpent,
            qty,
            promotion,
          },
          true
        )
      )
    } else {
      rememberAndRedirect()
    }
  }

  const onTokenReceived = (token: string, qty: number, totalSpent: number, promotion: string) => {
    const bookingDetails = {
      name: activeItem && activeItem.name,
      date: selectedDate ? selectedDate : moment(),
      deal_id: active,
      transaction_status: "invoiced",
      contact: user.phone || user.email,
      comments: activeItem && activeItem.conditions,
      minSpend: totalSpent,
      qty,
      promotion,
    }

    dispatch(bookTableAndPay(bookingDetails, token, false, true))
  }

  const onSourceReceived = (source: string, qty: number, totalSpent: number, promotion: string) => {
    const bookingDetails = {
      name: activeItem && activeItem.name,
      date: selectedDate ? selectedDate : moment(),
      deal_id: active,
      transaction_status: "invoiced",
      contact: user.phone || user.email,
      comments: activeItem && activeItem.conditions,
      minSpend: totalSpent,
      qty,
      promotion,
    }

    dispatch(bookTableAndPay(bookingDetails, source, true, true))
  }

  const checkShouldProccessedWithCheckout = () => {
    if (isLoggedIn) {
      return true
    }
    rememberAndRedirect()
    return false
  }

  return (
    <Container className={`${styles.masterContainer} fadeIn`}>
      <Back to="/deals" />
      <Divider hidden />
      <TitleAndSubtitle
        size={"medium"}
        title={clubs.segments[clubId] && clubs.segments[clubId].name}
        subtitle={"Deals & Experiences"}
      />
      <Divider hidden style={{ marginBottom: 0 }} />

      <SingleDatePicker
        selectionFrom
        bookFor
        value={selectedDate || moment()}
        bookedBefore={clubs.segments[clubId] && clubs.segments[clubId].booked_before}
        onChange={(date) => handleDateChange(date)}
      />

      {clubs.segments[clubId] && clubs.segments[clubId].client_note && (
        <Grid stackable columns={"equal"}>
          <Grid.Column width={11}>
            <section className={styles.contactSection}>
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(clubs.segments[clubId].client_note) }} />
            </section>
          </Grid.Column>
          <Responsive as={Grid.Column} minWidth={768} />
        </Grid>
      )}

      <Grid stackable columns={"equal"} style={{ minHeight: 400 }}>
        <Grid.Column width={11}>
          {readSuccess ? (
            Object.keys(dealsList).length ? (
              Object.keys(dealsList).map((key) => {
                const { _id: id, name, description, minimum_spend: minSpend } = dealsList[key]

                return (
                  <TableItem
                    booked={false}
                    key={`deal-booking-${id}`}
                    active={active === id}
                    name={name}
                    maxGuests={null}
                    minSpend={minSpend}
                    placement={description}
                    onClick={() => setActive(id)}
                  />
                )
              })
            ) : (
              <div style={{ height: 300, textAlign: "center" }}>
                <div className="vertical-align">No available deals</div>
              </div>
            )
          ) : readLoading ? (
            <Loader active />
          ) : (
            <div style={{ height: 300, textAlign: "center" }}>
              <div className="vertical-align">No available deals</div>
            </div>
          )}
        </Grid.Column>
        <Responsive as={Grid.Column} minWidth={768}>
          <DealCard
            name={activeItem && activeItem.name}
            active={active !== null}
            conditions={activeItem && activeItem.conditions}
            clubId={clubId}
            minSpend={activeItem && activeItem.minimum_spend}
            hasPayButton={club && club.payment_enabled}
            hasBookingButton={club && club.booking_enabled}
            onClick={onBookClick}
            onTokenReceived={onTokenReceived}
            onSourceReceived={onSourceReceived}
            checkShouldProccessedWithCheckout={checkShouldProccessedWithCheckout}
          />
        </Responsive>
      </Grid>
      <Responsive maxWidth={768}>
        {active !== null && (
          <div className={styles.tableCardContainer}>
            <div className={styles.tableCardDimmer} onClick={() => setActive(null)} />
            <div className={styles.tableCardContent}>
              <DealCard
                name={activeItem && activeItem.name}
                active={active !== null}
                conditions={activeItem && activeItem.conditions}
                clubId={clubId}
                minSpend={activeItem && activeItem.minimum_spend}
                hasPayButton={club && club.payment_enabled}
                hasBookingButton={club && club.booking_enabled}
                onClick={onBookClick}
                onTokenReceived={onTokenReceived}
                onSourceReceived={onSourceReceived}
                checkShouldProccessedWithCheckout={checkShouldProccessedWithCheckout}
              />
            </div>
          </div>
        )}
      </Responsive>
    </Container>
  )
}

export default BookDeal
