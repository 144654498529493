// @flow
import * as React from "react"
import { compose } from "redux"
import { Route, Switch } from "react-router-dom"
import { withWindowSizeListener } from "react-window-size-listener"

import Login from "views/Login"
import Page404 from "views/Page404"
import Register from "views/Register"
import Venues from "views/Venues"
import Club from "views/Club"
import Events from "views/Events"
import Deals from "views/Deals"
import Event from "views/Event"
import Deal from "views/Deal"
import Bookings from "views/Bookings"
import Settings from "views/Settings"
import Tables from "views/Tables"
import BookDeal from "views/BookDeal"
import Home from "views/Home"
import Confirmation from "views/Confirmation"
import Logout from "views/Logout"
import Page from "views/Page"
import ForgotPassword from "views/ForgotPassword"

import withLayout from "wrappers/Layout"
import withTitle from "wrappers/Title"
import StatelessHeader from "components/Header"
import Footer from "components/Footer"
import { AUTHORIZED, UNAUTHORIZED, REGISTER } from "data/User/constants"

import withProtection from "wrappers/Protection"
import withHeaderNavigation from "wrappers/HeaderNavigation"
import withPageBackground from "wrappers/PageBackground"
import withNecessaryCommonData from "wrappers/NecessaryCommonData"
import withPageContent from "views/Page/withPageContent"
import PrivacyPolicy from "views/Page/PrivacyPolicy"
import TermsOfUse from "views/Page/TermsOfUse"
import ContactUs from "views/Page/ContactUs"
import Concierge from "../../views/Concierge"

const Header = withHeaderNavigation(StatelessHeader)

export default function withRoutes(Component: React.ComponentType<any>) {
  return function Routes(props: {}) {
    return (
      <React.Fragment>
        <Component {...props} />
        <Switch>
          {/* <Route exact path={"/"} render={() => <Redirect to={"/login"} />} /> */}

          <Route
            exact
            path={"/"}
            component={compose(
              withTitle({ title: "Home", canonicalUrl: "" }),
              withLayout({ Header, Footer })
            )(Home)}
          />

          <Route
            path={"/login"}
            component={compose(
              withTitle({ title: "Login", canonicalUrl: "/login" }),
              withProtection([UNAUTHORIZED]),
              withLayout({ Header, Footer }),
              withPageBackground({ image: "/background.webp", dimmed: true })
            )(Login)}
          />

          <Route
            path={"/forgot-password"}
            component={compose(
              withTitle({ title: "Forgot Password" }),
              withProtection([UNAUTHORIZED]),
              withLayout({ Header, Footer }),
              withPageBackground({ image: "/background.webp", dimmed: true })
            )(ForgotPassword)}
          />

          <Route
            path={"/register"}
            component={compose(
              withProtection([REGISTER]),
              withTitle({ title: "Register", canonicalUrl: "/register" }),
              withLayout({ Header, Footer }),
              withPageBackground({ image: "/background.webp", dimmed: true })
            )(Register)}
          />

          <Route
            path={"/venues"}
            component={compose(
              withNecessaryCommonData(["locations"]),
              withTitle({
                title: "Venues",
                description: "TABLE - Booking Tables & Package",
                canonicalUrl: "/venues",
              }),
              withLayout({ Header, Footer })
            )(Venues)}
          />

          <Route
            path={"/concierge"}
            component={compose(
              withTitle({
                title: "Concierge",
                canonicalUrl: "/concierge",
                description:
                  "TABLE - We plan, organize and give you unparalleled access to exclusive events, restaurants, villas, yachts and everything else lifestyle.",
              }),
              withLayout({ Header, Footer }, true)
            )(Concierge)}
          />

          <Route
            path={"/club"}
            component={compose(
              withNecessaryCommonData(["events"]),
              withLayout({ Header, Footer }),
              withPageBackground({ image: "/background.webp", dimmed: true })
            )(Club)}
          />

          <Route
            path={"/events"}
            component={compose(
              withWindowSizeListener,
              withNecessaryCommonData(["clubs", "locations"]),
              withTitle({
                title: "Events",
                description: "TABLE - Experience The Best Events. Book now!",
                canonicalUrl: "/events",
              }),
              withLayout({ Header, Footer })
            )(Events)}
          />

          <Route
            path={"/deals"}
            component={compose(
              withWindowSizeListener,
              withNecessaryCommonData(["clubs", "locations"]),
              withTitle({
                title: "Deals & Experiences",
                description: "TABLE - Exclusive Deals & Experiences. Book now!",
                canonicalUrl: "/deals",
              }),
              withLayout({ Header, Footer })
            )(Deals)}
          />

          <Route
            path={"/event"}
            component={compose(
              withLayout({ Header, Footer }),
              withPageBackground({ image: "/background.webp", dimmed: true })
            )(Event)}
          />

          <Route
            path={"/deal"}
            component={compose(
              withLayout({ Header, Footer }),
              withPageBackground({ image: "/background.webp", dimmed: true })
            )(Deal)}
          />

          <Route
            path={"/bookings"}
            component={compose(
              withProtection([AUTHORIZED]),
              withTitle({ title: "Your Bookings", disallowIndex: true }),
              withLayout({ Header, Footer })
            )(Bookings)}
          />

          <Route
            path={"/tables"}
            component={compose(
              withTitle({ title: "Tables", disallowIndex: true }),
              withNecessaryCommonData(["clubs"]),
              withLayout({
                Header,
                Footer,
              }),
              withPageBackground({ image: "/background.webp", dimmed: true })
            )(Tables)}
          />

          <Route
            path={"/book-deal"}
            component={compose(
              withTitle({ title: "Deals & Experiences", disallowIndex: true }),
              withNecessaryCommonData(["clubs", "deals"]),
              withLayout({
                Header,
                Footer,
              }),
              withPageBackground({ image: "/background.webp", dimmed: true })
            )(BookDeal)}
          />

          <Route
            path={"/confirmation/:deal?"}
            component={compose(
              withTitle({ title: "Confirmation", disallowIndex: true }),
              withLayout({
                Header,
                Footer,
              }),
              withPageBackground({ image: "/background.webp", dimmed: true })
            )(Confirmation)}
          />

          <Route
            path={"/settings"}
            component={compose(
              withProtection([AUTHORIZED]),
              withTitle({ title: "Settings", disallowIndex: true }),
              withLayout({ Header, Footer })
            )(Settings)}
          />

          <Route
            path={"/privacy-policy"}
            component={compose(
              withTitle({ title: "Privacy Policy" }),
              withLayout({ Header, Footer }),
              withPageContent(PrivacyPolicy)
            )(Page)}
          />

          <Route
            path={"/terms-of-use"}
            component={compose(
              withTitle({ title: "Terms of Use" }),
              withLayout({ Header, Footer }),
              withPageContent(TermsOfUse)
            )(Page)}
          />

          <Route
            path={"/contact-us"}
            component={compose(
              withTitle({ title: "Contact Us" }),
              withLayout({ Header, Footer }),
              withPageContent(ContactUs)
            )(Page)}
          />

          <Route path={"/logout"} component={Logout} />

          <Route component={Page404} />
        </Switch>
      </React.Fragment>
    )
  }
}
