// @flow
import React from "react"
import { Link } from "react-router-dom"

import styles from "./styles.module.css"
import logo from "assets/Images/logo-full.svg"

const Logo = ({ style, className }) => (
  <Link to={"/"}>
    <div style={style} className={`${styles.logoContainer} ${className}`}>
      <img alt="Logo" src={logo} />
    </div>
  </Link>
)

export default Logo
