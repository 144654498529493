// @flow
import * as React from "react"
import { Redirect } from "react-router-dom"
import { pushNotification } from "wrappers/Notifications/actions"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

type PropTypes = {
  pushNotification: Function,
}

class Page404 extends React.Component<PropTypes> {
  componentDidMount() {
    this.props.pushNotification({ content: "Page not found, redirected..." })
  }

  render() {
    return <Redirect to="/" />
  }
}

const mdtp = dispatch => ({
  pushNotification: bindActionCreators(pushNotification, dispatch),
})

export default connect(
  null,
  mdtp
)(Page404)
