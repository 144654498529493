// @flow
import React from "react"
import { instance as axios } from "instances/Axios"
import { Link } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha"

import Button from "components/Button"
import FormValidation from "wrappers/FormValidation"
import styles from "./styles.module.css"

type FieldType = {
  name?: string,
  label: string,
  type: string,
  placeholder?: string,
  readOnly?: boolean,
  value?: string | number,
}
type PropTypes = {
  title: string,
  subtitle: string,
  handleSubmit: Function,
  submitButtonText: string,
  underneathComponent?: any,
  fields: Array<FieldType>,
  schema: any,
  onChange: Function,
}

class AuthorizationForm extends React.Component<PropTypes, StateTypes> {
  constructor(props) {
    super(props)
    this.captchaRef = React.createRef(null)
  }
  state = { acceptToc: false, disableBtn: false }

  render() {
    const {
      title,
      subtitle,
      handleSubmit,
      submitButtonText,
      underneathComponent,
      register = false,
      fields: formFields,
      schema,
    } = this.props

    const submitForm = async (e) => {
      e.preventDefault()
      this.setState({ ...this.state, disableBtn: true })
      if (register) {
        const token = this.captchaRef.current.getValue()
        try {
          const response = await axios.post("/checks", {
            token: token,
          })

          this.setState({
            ...this.state,
            disableBtn: false,
          })
          this.captchaRef.current.reset()
          if (response.data.data.success) {
            console.log(response.data.data.success)
            handleSubmit(e)
          } else {
            console.log("Verification failed")
          }
        } catch (e) {
          console.log(e)
          this.captchaRef.current.reset()
          this.setState({
            ...this.state,
            disableBtn: false,
          })
        }
      } else {
        this.setState({ ...this.state, disableBtn: false })
        handleSubmit(e)
      }
    }
    return (
      <div className={`${styles.masterContainer} fadeIn`}>
        <div className={styles.contentContainer}>
          <div className={styles.headingContainer}>
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
          </div>
          <div className={styles.formContainer}>
            <FormValidation onChange={this.props.onChange} schema={schema}>
              {({ isValid, fieldProps, alertedFields, fields }) => (
                <form onSubmit={submitForm}>
                  {formFields.map((field, i) => (
                    <React.Fragment key={i}>
                      <label>
                        {field.label}
                        {alertedFields[field.name] && (
                          <span className={styles.errorText}>{` (${alertedFields[field.name].join(". ")})`}</span>
                        )}
                      </label>
                      <input
                        {...fieldProps(field.name)}
                        type={field.type}
                        placeholder={field.placeholder}
                        name={field.name}
                        className={alertedFields[field.name] ? "alerted-field" : ""}
                        readOnly={field.readOnly}
                      />
                    </React.Fragment>
                  ))}
                  {register && (
                    <>
                      <div className={styles.tocContainer}>
                        <input
                          type={"checkbox"}
                          name={"accept_toc"}
                          value={this.state.acceptToc}
                          onChange={(e) => {
                            this.setState({ acceptToc: e.target.checked })
                          }}
                          style={{
                            width: "auto",
                            marginBottom: 0,
                            accentColor: "#f54073",
                          }}
                        />
                        <span
                          style={{
                            paddingLeft: "5px",
                          }}
                        >
                          I have read and agree to the
                          <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">
                            <span className={styles.link}> Terms of Use </span>
                          </Link>
                          and
                          <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                            <span className={styles.link}> Privacy Policy </span>
                          </Link>
                        </span>
                      </div>
                      <div className={styles.captchaContainer}>
                        <ReCAPTCHA
                          sitekey={window._env_ && window._env_.CAPTCHA_APP_SITE_KEY}
                          ref={this.captchaRef}
                          theme={"dark"}
                        />
                      </div>
                    </>
                  )}

                  <Button
                    disabled={register ? !isValid || !this.state.acceptToc || this.state.disableBtn : !isValid}
                    fluid
                    name={"login-button"}
                  >
                    {submitButtonText}
                  </Button>
                </form>
              )}
            </FormValidation>
          </div>
          {underneathComponent}
        </div>
      </div>
    )
  }
}

export default AuthorizationForm
