import React from "react"

const Content = () => (
  <div>
    <h1>Contact Us</h1>

    <p>Keep in touch with us - THE TABLE TEAM IS HERE FOR YOU 24/7</p>
    <p>
      <h3>Singapore</h3>
      49CAP PTE. LTD.
      <br />
      51 GOLDHILL PLAZA #07-10/11 <br />
      Singapore <br />
      308900
    </p>
    <h3>Thailand</h3>
    <p>
      TABLE (Thailand) Co., Ltd. <br />
      Woof Pack Building 1
      <br />
      3-9 Sala Daeng 1 Alley
      <br />
      Silom, Bang Rak, Bangkok
      <br />
      10500 <br />
    </p>
    <h3>Phone</h3>
    <p>+66 (0)2055 8710</p>
    <h3>Social Media</h3>
    <p>
      Line:{" "}
      <a href="https://lin.ee/3yv7oLwpi" target="_blank" rel="noopener noreferrer">
        @table_asia
      </a>
      <br />
      <a href="https://www.facebook.com/tableasia" target="_blank" rel="noopener noreferrer">
        Facebook{" "}
      </a>
      <br />
      <a href="https://www.instagram.com/table_asia/" target="_blank" rel="noopener noreferrer">
        Instagram
      </a>
    </p>
    <h3>E-mail</h3>
    <p>
      <a href="mailto:info@tablebooking.co">info@tablebooking.co</a>
      <br />
      <a href="mailto:partnerships@tablebooking.co">partnerships@tablebooking.co</a> - For venues / event
      organizers
      <br />
      <a href="mailto:careers@tablebooking.co">careers@tablebooking.co</a> - For career inquiries
      <br />
    </p>
  </div>
)

export default Content
