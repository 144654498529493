// @flow
import { CALL_API } from "middleware/api"
import authService from "instances/AuthService"

import {
  SET,
  UNSET,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
} from "./constants"

import type { UserType } from "./types"

export function setUser(user: UserType) {
  return {
    type: SET,
    payload: user,
  }
}

export function unsetUser() {
  return { type: UNSET }
}

export const changePassword = (params: UserType, form: string) => {
  return {
    [CALL_API]: {
      types: [
        CHANGE_PASSWORD_REQUEST,
        CHANGE_PASSWORD_SUCCESS,
        CHANGE_PASSWORD_FAILURE,
      ],
      promise: httpClient => httpClient.put("/users/password", params),
      successMessage: "Password is successfully changed",
      errorMessage: "Failed to change password",
      formResetName: form,
    },
  }
}

export const updateProfileDetails = (params: UserType, form: string) => ({
  [CALL_API]: {
    types: [
      UPDATE_PROFILE_REQUEST,
      UPDATE_PROFILE_SUCCESS,
      UPDATE_PROFILE_FAILURE,
    ],
    promise: httpClient => httpClient.put("/users/me", params),
    successMessage: "Updated user profile",
    failureMessage: "Failed to update user profile",
  },
})

// Operations
export const updateProfileDetailsAndUpdateUser = (
  params: UserType,
  form: string
) => async (dispatch: Function) => {
  await dispatch(updateProfileDetails(params, form))
  authService.fetchUser()
}
