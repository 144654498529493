// @flow
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import type { UserType } from "data/User/types"
import AuthorizationForm from "components/AuthorizationForm"
import { isEmail } from "wrappers/FormValidation/validations"
import { _oldPushNotification } from "wrappers/Notifications/actions"
import { instance as axios } from "instances/Axios"

type PropType = {
  user: UserType,
  pushNotification: Function,
}

type StateType = {
  fieldValues: any,
}

class ForgotPassword extends React.Component<PropType, StateType> {
  state = {
    fieldValues: {},
  }

  handleInputChange(which: string, value: string) {
    const state = { ...this.state }
    state[which] = value
    this.setState(state)
  }

  handleFormStateChange(fieldValues) {
    this.setState({ fieldValues })
  }

  async handleSubmit(e: SyntheticEvent<*>) {
    e.preventDefault()
    try {
      await axios.post("/users/forgotPassword", {
        email: this.state.fieldValues.email,
      })
      this.props.pushNotification({
        content: "Password change is sent to your email",
      })
    } catch (e) {
      console.log(e)
      this.props.pushNotification({ content: "Failed" })
    }
  }

  render() {
    return (
      <AuthorizationForm
        onChange={fieldValues => this.handleFormStateChange(fieldValues)}
        title={"Forgot password"}
        subtitle={"Enter your email to receive new password"}
        handleSubmit={e => this.handleSubmit(e)}
        submitButtonText={"Request new password"}
        fields={fields}
        schema={schema}
      />
    )
  }
}

const schema = {
  email: {
    required: true,
    minLength: 2,
    customValidation: {
      isValid: value => isEmail(value),
      errorMessage: "Must be valid email",
      on: "blur",
    },
  },
}

const fields = [
  {
    name: "email",
    label: "E-mail",
    type: "email",
    placeholder: "yourname@example.com",
  },
]

const mstp = ({ user }) => ({ user })
const mdtp = dispatch => ({
  pushNotification: bindActionCreators(_oldPushNotification, dispatch),
})
export default connect(
  mstp,
  mdtp
)(ForgotPassword)
