// @flow
import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Grid } from "semantic-ui-react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import ReCAPTCHA from "react-google-recaptcha"

import Button from "components/Button"
import { unsetLocation } from "data/Navigation/actions"
import { instance as axios } from "instances/Axios"
import { _oldPushNotification as pushNotification } from "wrappers/Notifications/actions"

import logo from "assets/Images/concierge-logo.webp"
import event from "assets/Images/concierge1.webp"
import food from "assets/Images/concierge2.webp"
import travel from "assets/Images/concierge3.webp"
import health from "assets/Images/concierge4.webp"
import security from "assets/Images/concierge5.webp"
import luxury from "assets/Images/concierge6.webp"

import facebookLogoColor from "assets/Icons/facebook-color.svg"
import instagramLogoColor from "assets/Icons/instagram-color.svg"
import linkedinLogoColor from "assets/Icons/linkedin-color.svg"
import facebookLogo from "assets/Icons/facebook-logo.svg"
import instagramLogo from "assets/Icons/instagram-logo.svg"
import linkedinLogo from "assets/Icons/linkedin-logo.svg"
import lineLogo from "assets/Icons/line-logo.svg"
import lineLogoColor from "assets/Icons/line-color.svg"

import will from "assets/Images/will.webp"
import sarah from "assets/Images/sarah.webp"
import justin from "assets/Images/justin.webp"

import styles from "./styles.module.css"

type PropTypes = {
  history: {
    push: Function,
  },
  unsetLocation: Function,
  navigation: {
    location: string,
  },
}

class Concierge extends React.Component<PropTypes> {
  constructor(props) {
    super(props)
    this.captchaRef = React.createRef(null)
  }
  state = {
    contact: "",
    description: "",
    disableBtn: false,
  }

  handleContactFormInputChange(value) {
    this.setState(value)
  }

  componentDidMount() {
    if (this.props.navigation.location) {
      this.props.history.push(this.props.navigation.location)
      this.props.unsetLocation()
    }
  }

  async handleContactFormSubmit(e) {
    e.preventDefault()
    if (this.state.contact.length > 0 && this.state.description.length > 0) {
      this.setState({ ...this.state, disableBtn: true })

      const token = this.captchaRef.current.getValue()
      try {
        const response = await axios.post("/checks", {
          token: token,
        })

        this.setState({
          ...this.state,
          disableBtn: false,
        })
        this.captchaRef.current.reset()
        if (response.data.data.success) {
          await axios.post("/contacts", {
            contact: this.state.contact,
            description: this.state.description,
          })
          this.props.pushNotification({ content: "Contact send!" })
          this.setState({
            contact: "",
            description: "",
            disableBtn: false,
          })
        } else {
          this.props.pushNotification({ content: "Verification Failed" })
        }
      } catch (e) {
        console.log(e)
        this.props.pushNotification({ content: "Failed to send contact." })
        this.captchaRef.current.reset()
        this.setState({
          contact: "",
          description: "",
          disableBtn: false,
        })
      }
    } else {
      this.props.pushNotification({ content: "Need to input data to send contact." })
    }
  }

  render() {
    const coverSection = (
      <section className={styles.coverSection}>
        <Carousel showArrows={true} autoPlay={true} infiniteLoop={true} showStatus={false} swipeable={false}>
          <div className={styles.carouselItem}>
            <div className={styles.firstCarouselItem}>
              <img src={logo} alt="Concierge Logo" />
            </div>
          </div>
          <div className={styles.carouselItem}>
            <div className={styles.secondCarouselItem}>
              <div className={styles.carouselWording}>
                <h1>We Make It Happen For You</h1>
                <p>
                  With over 20 years experience in Asia our TABLE concierge staff is there for you 24/7/365. We
                  plan, organize and give you unparalleled access to exclusive events, restaurants, villas, yachts
                  and everything else lifestyle. Just get in touch with us below and we will take care of the rest
                  - all custom tailored to your needs and desires.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.carouselItem}>
            <div className={styles.thirdCarouselItem} />
          </div>
        </Carousel>
      </section>
    )

    const testimonialsSection = (
      <section className={styles.testimonialsSection}>
        <h1>Testimonials</h1>
        <hr />
        <Grid stackable columns="3">
          <Grid.Column>
            <div className={styles.masterContainer}>
              <div className={styles.imageContainer}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${justin})`,
                  }}
                />
              </div>
              <h1>Justin Han</h1>
              <h2>
                “The TABLE team planned my whole vacation in Bangkok & Koh Samui for me. I saw places I never knew
                existed eventhough I had been to Thailand many times before.”
              </h2>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.masterContainer}>
              <div className={styles.imageContainer}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${sarah})`,
                  }}
                />
              </div>
              <h1>Sarah Goodfellow</h1>
              <h2>
                “I took my boyfriend around Bangkok for a couple of days. Everything went without any hiccups.
                Thank you so much, will definitely do this again when we are back in Thailand.”
              </h2>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.masterContainer}>
              <div className={styles.imageContainer}>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${will})`,
                  }}
                />
              </div>
              <h1>Will Craig</h1>
              <h2>
                “Did my bachelor party with TABLE Concierge and will never forget the fun we had. Especially loved
                the full service boat trip to the islands.”
              </h2>
            </div>
          </Grid.Column>
        </Grid>
      </section>
    )

    const placesSection = (
      <section className={styles.placesSection}>
        <Grid stackable columns="3">
          <Grid.Column>
            <div className={styles.masterContainer}>
              <div className={styles.imageContainer}>
                <div className={styles.imageDescription}>
                  <ul>
                    <li>Private party planning</li>
                    <li>Business Outings</li>
                    <li>Event Venue Search and Rentals</li>
                  </ul>
                </div>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${event})`,
                  }}
                />
              </div>
              <h1>Event Planning</h1>
              <h2>Private Event Planning • Business Outings • Event Venue Search & Rentals</h2>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.masterContainer}>
              <div className={styles.imageContainer}>
                <div className={styles.imageDescription}>
                  <ul>
                    <li>Restaurant, Bar & Club Reservations</li>
                    <li>Private Chefs</li>
                    <li>Cooking Courses</li>
                  </ul>
                </div>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${food})`,
                  }}
                />
              </div>
              <h1>Food & Drinks</h1>
              <h2>Restaurant, Bar & Club Reservations • Private Chefs • Cooking Courses</h2>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.masterContainer}>
              <div className={styles.imageContainer}>
                <div className={styles.imageDescription}>
                  <ul>
                    <li>Yachts / Boats</li>
                    <li>Villas / Hotels</li>
                    <li>Guided Tours</li>
                  </ul>
                </div>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${travel})`,
                  }}
                />
              </div>
              <h1>Luxury Travel Planning</h1>
              <h2>Yachts / Boats • Villas / Hotels • Guided Tours</h2>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.masterContainer}>
              <div className={styles.imageContainer}>
                <div className={styles.imageDescription}>
                  <ul>
                    <li>Spa Treatments </li>
                    <li>Gym / Personal Trainer</li>
                    <li>Beauty & Dental Treatments</li>
                  </ul>
                </div>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${health})`,
                  }}
                />
              </div>
              <h1>Health & Beauty</h1>
              <h2>Spa Treatments • Gym / Personal Trainer • Beauty & Dental Treatments</h2>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.masterContainer}>
              <div className={styles.imageContainer}>
                <div className={styles.imageDescription}>
                  <ul>
                    <li>Event Security</li>
                    <li>Bodyguards</li>
                    <li>Valuabe Item Storage</li>
                  </ul>
                </div>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${security})`,
                  }}
                />
              </div>
              <h1>Security Services</h1>
              <h2>Event Security • Bodyguards • Valuabe Item Storage</h2>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.masterContainer}>
              <div className={styles.imageContainer}>
                <div className={styles.imageDescription}>
                  <ul>
                    <li>Handbags</li>
                    <li>Watches</li>
                    <li>Jewelry</li>
                  </ul>
                </div>
                <div
                  className={styles.image}
                  style={{
                    backgroundImage: `url(${luxury})`,
                  }}
                />
              </div>
              <h1>Luxury Goods Sourcing</h1>
              <h2>Handbags • Watches • Jewelry</h2>
            </div>
          </Grid.Column>
        </Grid>
      </section>
    )

    const contactSection = (
      <section className={styles.contactSection}>
        <h2>
          Leave us your contact and a short message with what you need help with and we will get back to you
          shortly.
        </h2>
        <form onSubmit={(e) => this.handleContactFormSubmit(e)}>
          <input
            placeholder="Name, email, phone number"
            className={styles.contact}
            onChange={({ target: { value } }) =>
              this.handleContactFormInputChange({ contact: value, description: this.state.description })
            }
            value={this.state.contact}
          />
          <input
            placeholder="Short message with what you need help with..."
            className={styles.description}
            onChange={({ target: { value } }) =>
              this.handleContactFormInputChange({ contact: this.state.contact, description: value })
            }
            value={this.state.description}
          />
          <div className={styles.captchaContainer}>
            <ReCAPTCHA
              sitekey={window._env_ && window._env_.CAPTCHA_APP_SITE_KEY}
              ref={this.captchaRef}
              size={"compact"}
              theme={"dark"}
            />
          </div>

          <Button basic disabled={this.state.disableBtn}>
            Send
          </Button>
        </form>
      </section>
    )

    const socialSection = (
      <div className={styles.socialContainer}>
        <h1>You can also reach us here</h1>
        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/tableasia/">
          <div className={styles.icon}>
            <img src={facebookLogo} alt={"Facebook"} />
            <img src={facebookLogoColor} alt={"Facebook"} />
          </div>
        </a>
        <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/table_asia/">
          <div className={styles.icon}>
            <img src={instagramLogo} alt={"Instagram"} />
            <img src={instagramLogoColor} alt={"Instagram"} />
          </div>
        </a>
        <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/tableasia/">
          <div className={styles.icon}>
            <img src={linkedinLogo} alt={"Linkedin"} />
            <img src={linkedinLogoColor} alt={"Linkedin"} />
          </div>
        </a>
        <a rel="noopener noreferrer" target="_blank" href="https://lin.ee/3yv7oLwpi">
          <div className={styles.icon}>
            <img src={lineLogo} alt={"Line"} />
            <img src={lineLogoColor} alt={"Line"} />
          </div>
        </a>
      </div>
    )

    return (
      <div className={`${styles.container} fadeIn`}>
        {coverSection}
        {placesSection}
        {contactSection}
        {socialSection}
        {testimonialsSection}
      </div>
    )
  }
}

const mstp = ({ navigation }) => ({ navigation })

const mdtp = (dispatch) => ({
  unsetLocation: bindActionCreators(unsetLocation, dispatch),
  pushNotification: bindActionCreators(pushNotification, dispatch),
})

export default connect(mstp, mdtp)(Concierge)
