// @flow
import React from "react"
import { Grid, Icon } from "semantic-ui-react"
import DOMPurify from "dompurify"

import styles from "./styles.module.css"

type PropTypes = {
  name: string,
  maxGuests: number,
  minSpend: number,
  onClick: Function,
  active?: boolean,
  booked: boolean,
}

const TableItem = ({ maxGuests, name, placement, minSpend, onClick, active, booked }: PropTypes) => (
  <div onClick={onClick} className={`${styles.container} ${active ? styles.active : ""}`}>
    <Grid>
      <Grid.Column width={10}>
        {maxGuests && <p>{`Max ${maxGuests} guests`}</p>}
        <h3>{name}</h3>
        <h4 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(placement) }} />
      </Grid.Column>
      <Grid.Column width={4} className={styles.rightAligned}>
        <p>
          <strong>{`฿${minSpend}`}</strong>
        </p>
      </Grid.Column>
      <Grid.Column width={2} className={styles.rightAligned}>
        <Icon className={`${styles.icon} ${active ? styles.active : ""}`} size={"large"} name={"chevron right"} />
      </Grid.Column>
    </Grid>
  </div>
)

export default TableItem
