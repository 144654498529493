export const TABLES = "TABLES"
const which = TABLES

export const CREATE_REQUEST = `${which}_CREATE_REQUEST`
export const CREATE_SUCCESS = `${which}_CREATE_SUCCESS`
export const CREATE_FAILURE = `${which}_CREATE_FAILURE`
export const READ_REQUEST = `${which}_READ_REQUEST`
export const READ_SUCCESS = `${which}_READ_SUCCESS`
export const READ_FAILURE = `${which}_READ_FAILURE`
export const UPDATE_REQUEST = `${which}_UPDATE_REQUEST`
export const UPDATE_SUCCESS = `${which}_UPDATE_SUCCESS`
export const UPDATE_FAILURE = `${which}_UPDATE_FAILURE`
export const DELETE_REQUEST = `${which}_DELETE_REQUEST`
export const DELETE_SUCCESS = `${which}_DELETE_SUCCESS`
export const DELETE_FAILURE = `${which}_DELETE_FAILURE`

export const BOOKING_STATUSES_REQUEST = `${which}_BOOKING_STATUSES_REQUEST`
export const BOOKING_STATUSES_SUCCESS = `${which}_BOOKING_STATUSES_SUCCESS`
export const BOOKING_STATUSES_FAILURE = `${which}_BOOKING_STATUSES_FAILURE`
