// @flow
import * as React from "react"
import { Grid } from "semantic-ui-react"

import List from "components/List"
import { COVER, LABEL, LIST, STATUS_BUTTON, ACTIONS, TITLE } from "./constants"
import styles from "./styles.module.css"

type ColumnType = {
  position?: string,
  component: any,
  mobileComponent?: any,
  style?: {},
  header?: string,
}

type RowType = {
  label?: { component: any },
  columns: [ColumnType],
}

type PropTypes = {
  headingComponent?: any,
  headers: Array<{ component: any }>,
  rows: [RowType],
}

const TableSection = ({ headers, rows, headingComponent }: PropTypes) => {
  const positionedRows = rowsToPositioned(rows)
  return (
    <React.Fragment>
      <div className={styles.bodyContainer}>
        {headingComponent}
        <table className={styles.table}>
          <thead>
            <tr className={"heading-row"}>
              {headers.map(({ component }, i) => (
                <td key={i}>{component}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr key={i}>
                {row.columns.map(
                  ({ component, style }: { component: any, style: any }, j) => (
                    <td key={j} style={style}>
                      {component}
                      {j === row.columns.length - 1 &&
                        row.label &&
                        row.label.component}
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.mobileContainer}>
        {positionedRows.map(
          ({ actions, cover, label, list, statusButton, title }, i) => (
            <div key={i} className={styles.mobileBodyContainer}>
              <div className={styles.mobileCoverImage}>{cover}</div>
              {label && <div className={styles.mobileLabel}>{label}</div>}
              <div className={styles.mobileContentContainer}>
                <Grid columns={"equal"}>
                  <Grid.Row>
                    <Grid.Column width={10}>{title}</Grid.Column>
                    <Grid.Column>{statusButton}</Grid.Column>
                  </Grid.Row>
                </Grid>
                {list && <List items={list} />}
                <div style={{ textAlign: "center", marginTop: "3em" }}>
                  {actions}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </React.Fragment>
  )
}

// TODO: wrap these with window size listener to avoid useless render

function rowsToPositioned(rows: [RowType]) {
  const output = []
  rows.forEach(({ columns }) => output.push(columnsToPositioned(columns)))
  return output
}

function columnsToPositioned(columns: [ColumnType]) {
  const output = {
    cover: null,
    label: null,
    statusButton: null,
    title: null,
    actions: [],
    list: [],
  }
  columns.forEach(({ position, component, mobileComponent, header }, i) => {
    switch (position) {
      case COVER:
        return (output.cover = mobileComponent || component)
      case LABEL:
        return (output.label = component)
      case TITLE:
        return (output.title = component)
      case ACTIONS:
        return output.actions.push(component)
      case LIST:
        return output.list.push({
          component,
          header,
        })
      case STATUS_BUTTON:
        return (output.statusButton = mobileComponent || component)
      default:
        return null
    }
  })
  return output
}

export default TableSection
