// @flow
import { CALL_API } from "middleware/api"

import { READ_REQUEST, READ_SUCCESS, READ_FAILURE } from "./constants"

export const fetchLocations = () => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: (httpClient) =>
      httpClient({
        method: "get",
        url: "/locations",
      }),
  },
})
