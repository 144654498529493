// @flow
import * as React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"

import type { UserType } from "data/User/types"

type PropTypes = {
  user: UserType,
}

const AUTHORIZED_USER_HOME_URI = "/"
const LOGIN_URI = "/login"

const UNAUTHORIZED = "unauthorized"
const AUTHORIZED = "authorized"

const withProtection = (requiredAccessRoles: [string]) => (
  Component: React.ComponentType<any>
) => {
  class Protection extends React.Component<
    PropTypes,
    { allowRender: boolean }
  > {
    isRoleMatching: boolean
    redirectUrl: ?string

    state = { allowRender: false }

    componentDidMount() {
      this.isRoleMatching = false
      this.redirectUrl = null

      const currentUserRole =
        (this.props.user && this.props.user.role) || UNAUTHORIZED

      this.isRoleMatching = requiredAccessRoles.some(e => e === currentUserRole)

      if (
        requiredAccessRoles.length === 1 &&
        requiredAccessRoles[0] === "register"
      ) {
        switch (currentUserRole) {
          case UNAUTHORIZED:
            this.isRoleMatching = true
            break
          case AUTHORIZED:
            this.redirectUrl = AUTHORIZED_USER_HOME_URI
            this.isRoleMatching = false
            break
          default:
            return console.log("Shall not be hit")
        }
      } else {
        switch (currentUserRole) {
          case AUTHORIZED:
            this.redirectUrl = AUTHORIZED_USER_HOME_URI
            break
          case UNAUTHORIZED:
          default:
            this.redirectUrl = LOGIN_URI
        }
      }
      this.setState({ allowRender: true })
    }

    render() {
      if (!this.state.allowRender) return <span>waiting protection</span>

      return this.isRoleMatching ? (
        <Component {...this.props} />
      ) : (
        <Redirect to={this.redirectUrl} />
      )
    }
  }

  const mstp = ({ user }) => ({ user })
  return connect(
    mstp,
    null
  )(Protection)
}

export default withProtection
