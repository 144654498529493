// @flow
import { CALL_API } from "middleware/api"
import { READ_REQUEST, READ_SUCCESS, READ_FAILURE, RESET_DATA } from "./constants"

export const fetchClub = (club_id?: string) => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: (httpClient) =>
      httpClient({
        method: "get",
        url: "/clubs/getOne",
        params: { club_id },
      }),
  },
})

export function resetClub() {
  return { type: RESET_DATA }
}
