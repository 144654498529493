// @flow
import React from "react"

import styles from "./styles.module.css"

type PropTypes = {
  count: number,
  image: string,
  alt?: string,
}

const LabeledIcon = ({ count, image, alt }: PropTypes) => (
  <div className={styles.container}>
    <img src={image} alt={alt} />
    <div className={styles.label}>
      <p>{count}</p>
    </div>
  </div>
)

export default LabeledIcon
