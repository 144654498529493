// @flow
import React from "react"
import { compose } from "redux"

import withRouter from "wrappers/Router"
import withRoutes from "wrappers/Routes"
import withStore from "wrappers/Store"
import withSession from "wrappers/Session"
import withNotifications from "wrappers/Notifications"

import ReactPixel from "react-facebook-pixel"

const options = {
  autoConfig: true,
  debug: false,
}
ReactPixel.init("935832056886882", {}, options)

class App extends React.Component<{}> {
  render() {
    return null
  }
}

export default compose(withStore, withSession, withRouter, withRoutes, withNotifications)(App)
