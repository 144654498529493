// @flow
import React from "react"
import LazyLoad from "react-lazyload"
import { Loader } from "semantic-ui-react"

import Button from "components/Button"

import styles from "./styles.module.css"

const Item = ({
  title,
  subtitle,
  image,
  label,
  underneathComponent,
  button,
}: {
  title: string,
  subtitle: string,
  image: string,
  label?: string,
  underneathComponent?: any,
  button?: string,
}) => (
  <div className={styles.masterContainer}>
    <div className={styles.contentContainer}>
      {label && <div className={styles.label}>{label}</div>}
      <LazyLoad height={200} placeholder={<Loader active />}>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      </LazyLoad>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
      {underneathComponent}
    </div>
    {button && (
      <div className={styles.buttonContainer}>
        <Button basic>{button}</Button>
      </div>
    )}
  </div>
)

export default Item
