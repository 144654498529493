// @flow
import * as React from "react"
import { Helmet } from "react-helmet"

const baseDescription = "TABLE - Your go-to platform for table bookings, events, exclusive deals and more"
const withTitle =
  ({ title, description, canonicalUrl, disallowIndex }) =>
  (Component: React.ComponentType<any>) =>
    class Title extends React.Component<{}> {
      render() {
        const hasParams = !!this.props.location.search
        return (
          <div>
            <Helmet>
              {title && <title>{`TABLE | ${title}`}</title>}
              {title && <meta name="title" content={`TABLE | ${title}`} />}
              <meta name="description" content={description ? description : baseDescription} />
              {canonicalUrl !== undefined && !disallowIndex && !hasParams && (
                <link rel="canonical" href={`https://tablebooking.co${canonicalUrl}`} />
              )}
              {(disallowIndex || hasParams) && <meta name="robots" content="noindex,follow" />}
            </Helmet>
            <Component {...this.props} />
          </div>
        )
      }
    }
export default withTitle
