import React from "react"
import { Provider } from "react-redux"

import store from "./store"

export default function withStore(Component) {
  return function Store(props) {
    return (
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    )
  }
}
