export default function rowsCreator(segmentToRow, segments, props) {
  if (Object.keys(segments).length) {
    const output = []
    Object.keys(segments).forEach(key =>
      output.push(segmentToRow(segments[key], props))
    )
    return output
  }
  return []
}
