// @flow
import React from "react"
import moment from "moment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Container, Divider, Grid } from "semantic-ui-react"
import ImageGallery from "react-image-gallery"
import { Link } from "react-router-dom"

import Back from "components/Back"
import List from "components/List"
import SubjectHeaders from "components/SubjectHeaders"
import Button from "components/Button"
import Loading from "components/Loading"

import { fetchEvent, resetEvent } from "data/Event/actions"

import music from "assets/Icons/music.svg"
import masks from "assets/Icons/masks.svg"
import time from "assets/Icons/time.svg"
import pin from "assets/Icons/pin.svg"

import styles from "./styles.module.css"
import { Helmet } from "react-helmet"

type PropTypes = {
  event: {
    readLoading: boolean,
    readSuccess: boolean,
    segments: {},
  },
  fetchEvent: Function,
  location: {
    pathname: string,
  },
}

class Event extends React.Component<PropTypes> {
  param: ?string
  param = this.props.location.pathname.split("/")[2]
  hasSearchParams: boolean
  hasSearchParams = !!this.props.location.search

  componentDidMount() {
    if (!this.param) this.props.history.push("/events")
    this.props.fetchEvent({ event_id: this.param })
  }

  componentWillUnmount() {
    this.props.resetEvent()
  }

  render() {
    if (this.props.event.readSuccess && !this.props.event.segments[this.param]) {
      this.props.history.push("/events")
    }

    return (
      <>
        <Helmet>
          <title>{`TABLE${
            this.props.event.readSuccess ? ` | ${this.props.event.segments[this.param].name}` : ""
          }`}</title>
          <meta
            name="title"
            content={`TABLE${
              this.props.event.readSuccess ? ` | ${this.props.event.segments[this.param].name}` : ""
            }`}
          />
          <meta
            name="description"
            content={`TABLE - check out our event page for ${
              this.props.event.readSuccess ? this.props.event.segments[this.param].name : this.params
            } to start your booking.`}
          />
          {!this.hasSearchParams && <link rel="canonical" href={`https://tablebooking.co/event/${this.param}`} />}
          {this.hasSearchParams && <meta name="robots" content="noindex,follow" />}
        </Helmet>
        <Container className={`${styles.masterContainer} fadeIn`}>
          <Back to="/events" />
          <Divider hidden />
          <Loading isLoading={this.props.event.readLoading}>
            <Grid stackable>
              <Grid.Column width={8}>
                <ImageGallery
                  slideInterval={3000}
                  lazyLoad
                  showNav={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  // defaultImage={placeholder}
                  items={
                    this.props.event.segments[this.param] &&
                    this.props.event.segments[this.param].images &&
                    this.props.event.segments[this.param].images.map((e) => ({
                      original: e.path,
                      thumbnail: e.path,
                    }))
                  }
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <SubjectHeaders
                  title={this.props.event.segments[this.param] && this.props.event.segments[this.param].name}
                  subtitle={
                    this.props.event.segments[this.param] &&
                    this.props.event.segments[this.param].club_id &&
                    this.props.event.segments[this.param].club[0] &&
                    this.props.event.segments[this.param].club[0].name
                  }
                  text={this.props.event.segments[this.param] && this.props.event.segments[this.param].description}
                  label={
                    this.props.event.segments[this.param] &&
                    this.props.event.segments[this.param].date &&
                    moment(this.props.event.segments[this.param].date).format("MMM DD, dddd")
                  }
                  link={"/events"}
                />
                <List
                  items={
                    this.props.event.segments[this.param] &&
                    this.props.event.segments[this.param].club_id &&
                    this.props.event.segments[this.param].club[0]
                      ? descriptionItems(this.props.event.segments[this.param].club[0])
                      : []
                  }
                />
                <div className={styles.bookContainer}>
                  <Link
                    to={`/tables/${
                      this.props.event.segments[this.param] && this.props.event.segments[this.param].club_id
                    }?event=${this.param}&date=${
                      this.props.event.segments[this.param] &&
                      this.props.event.segments[this.param].date &&
                      moment(this.props.event.segments[this.param].date).format("YYYY-MM-DD")
                    }`}
                  >
                    <Button fluid>BOOK NOW</Button>
                  </Link>
                </div>
              </Grid.Column>
            </Grid>
          </Loading>
        </Container>
      </>
    )
  }
}

const mstp = ({ event }) => ({ event })
const mdtp = (dispatch) => ({
  fetchEvent: bindActionCreators(fetchEvent, dispatch),
  resetEvent: bindActionCreators(resetEvent, dispatch),
})
export default connect(mstp, mdtp)(Event)

const descriptionItems = ({ music_type, dress_code, address, open, closed }) => [
  {
    header: (
      <span>
        <img src={music} alt="Music icon" />
        Music
      </span>
    ),
    component: <span>{music_type}</span>,
  },
  {
    header: (
      <span>
        <img src={masks} alt="Dress code icon" />
        Dress code
      </span>
    ),
    component: <span>{dress_code}</span>,
  },
  {
    header: (
      <span>
        <img src={time} alt="Time icon" />
        Open hours
      </span>
    ),
    component: <span>{`${open} - ${closed}`}</span>,
  },
  {
    header: (
      <span>
        <img src={pin} alt={"Address icon"} />
        Address
      </span>
    ),
    component: <span>{address}</span>,
  },
]
