// @flow
import * as React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { fetchLocations } from "data/Locations/actions"
import { fetchClubs } from "data/Clubs/actions"
import { fetchEvents } from "data/Events/actions"
import { fetchDeals } from "data/Deals/actions"

type PropTypes = {
  clubs: {
    readSuccess: boolean,
  },
  events: {
    readSuccess: boolean,
  },
  deals: {
    readSuccess: boolean,
  },
  locations: {
    readSuccess: boolean,
  },
  fetchLocations: Function,
  fetchClubs: Function,
  fetchEvents: Function,
  fetchDeals: Function,
}

const withNecessaryCommonData = (which: [string]) => (Component: React.ComponentType<*>) => {
  class NecessaryCommonData extends React.Component<PropTypes> {
    componentDidMount() {
      if (!this.props.clubs.readSuccess && which.includes("locations")) {
        this.props.fetchLocations()
      }
      if (!this.props.clubs.readSuccess && which.includes("clubs")) {
        this.props.fetchClubs()
      }
      if (!this.props.events.readSuccess && which.includes("events")) {
        this.props.fetchEvents({})
      }
      if (!this.props.deals.readSuccess && which.includes("deals")) {
        this.props.fetchDeals()
      }
    }

    render() {
      return <Component {...this.props} />
    }
  }

  const mstp = ({ clubs, events, deals, locations }) => ({
    clubs,
    events,
    deals,
    locations,
  })

  const mdtp = (dispatch) => ({
    fetchLocations: bindActionCreators(fetchLocations, dispatch),
    fetchClubs: bindActionCreators(fetchClubs, dispatch),
    fetchEvents: bindActionCreators(fetchEvents, dispatch),
    fetchDeals: bindActionCreators(fetchDeals, dispatch),
  })

  return connect(mstp, mdtp)(NecessaryCommonData)
}

export default withNecessaryCommonData
