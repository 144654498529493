// @flow
import { SET_LOCATION, UNSET_LOCATION } from "./constants"

export const setLocation = (uri: string) => ({
  type: SET_LOCATION,
  payload: uri,
})

export const unsetLocation = () => ({
  type: UNSET_LOCATION,
})
