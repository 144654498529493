// @flow
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Container } from "semantic-ui-react"

import {
  changePassword,
  updateProfileDetailsAndUpdateUser,
} from "data/User/actions"

import PasswordChangeForm from "./PasswordChangeForm"
import ProfileDetailsForm from "./ProfileDetailsForm"
import styles from "./styles.module.css"

import type { UserType } from "data/User/types"

type PropTypes = {
  user: UserType,
  changePassword: Function,
}

class Settings extends React.Component<PropTypes> {
  updateProfileDetails = ({ name, gender, birthdate, email, phone_number }) => {
    this.props.updateProfileDetails({
      name,
      gender,
      birthdate,
      email,
      phone_number,
    })
  }

  changePassword = ({ oldPassword, password }) => {
    this.props.changePassword(
      {
        old_password: oldPassword,
        new_password: password,
      },
      "PasswordChangeForm"
    )
  }

  render() {
    return (
      <Container className={`${styles.masterContainer} fadeIn`}>
        <h1>Profile settings</h1>
        <h2>Personal information</h2>
        <div className={styles.section}>
          <ProfileDetailsForm
            updateProfileDetails={this.updateProfileDetails}
            user={this.props.user}
          />
        </div>
        <h2>Password</h2>
        <div className={styles.section}>
          <PasswordChangeForm changePassword={this.changePassword} />
        </div>
      </Container>
    )
  }
}

const mstp = ({ user }) => ({ user })
const mdtp = dispatch => ({
  changePassword: bindActionCreators(changePassword, dispatch),
  updateProfileDetails: bindActionCreators(
    updateProfileDetailsAndUpdateUser,
    dispatch
  ),
})

export default connect(
  mstp,
  mdtp
)(Settings)
