import React from "react"
import ReactDOM from "react-dom"

import "semantic-ui-css/semantic.min.css"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"

import "react-id-swiper/src/styles/css/swiper.css"

import "react-image-gallery/styles/css/image-gallery.css"

import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import "./index.css"
import "./calendar.css"
import App from "./App"

ReactDOM.render(<App />, document.getElementById("root"))
serviceWorkerRegistration.register()
