// @flow
import React from "react"

import playstore from "assets/Icons/playstore.svg"
import playstoreColor from "assets/Icons/playstore-color.svg"

import styles from "./styles.module.css"

const AppleButton = () => (
  <div className={`${styles.container} ${styles.android}`}>
    <div className={`${styles.contentContainer} ${styles.inverted}`}>
      <div className={styles.logoContainer}>
        <img src={playstore} alt="Google Play icon" />
        <img src={playstoreColor} alt="Google Play icon" />
      </div>
      <div className={styles.textContainer}>
        <div>
          <p>Soon on</p>
          <p>Google Play</p>
        </div>
      </div>
    </div>
  </div>
)

export default AppleButton
