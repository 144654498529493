// @flow
import React from "react" // eslint-disable-line no-unused-vars
import { connect } from "react-redux"
import { getUserRole } from "data/User/selectors"

import { AUTHORIZED } from "data/User/constants"

const AccessControl = ({ role, renderAuthorized, renderUnauthorized }) =>
  role === AUTHORIZED ? renderAuthorized : renderUnauthorized

AccessControl.defaultProps = {
  renderAuthorized: null,
  renderUnauthorized: null,
}

export default connect(state => ({ role: getUserRole(state) }))(AccessControl)
