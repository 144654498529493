// @flow
import React from "react"
import { Link } from "react-router-dom"

import facebookLogoColor from "assets/Icons/facebook-color.svg"
import instagramLogoColor from "assets/Icons/instagram-color.svg"
import linkedinLogoColor from "assets/Icons/linkedin-color.svg"
import facebookLogo from "assets/Icons/facebook-logo.svg"
import instagramLogo from "assets/Icons/instagram-logo.svg"
import linkedinLogo from "assets/Icons/linkedin-logo.svg"
import lineLogo from "assets/Icons/line-logo.svg"
import lineLogoColor from "assets/Icons/line-color.svg"

import styles from "./styles.module.css"

const Footer = () => (
  <div className={styles.masterContainer}>
    <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/tableasia/">
      <div className={styles.icon}>
        <img src={facebookLogo} alt={"Facebook"} />
        <img src={facebookLogoColor} alt={"Facebook"} />
      </div>
    </a>
    <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/table_asia/">
      <div className={styles.icon}>
        <img src={instagramLogo} alt={"Instagram"} />
        <img src={instagramLogoColor} alt={"Instagram"} />
      </div>
    </a>
    <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/tableasia/">
      <div className={styles.icon}>
        <img src={linkedinLogo} alt={"Linkedin"} />
        <img src={linkedinLogoColor} alt={"Linkedin"} />
      </div>
    </a>
    <a rel="noopener noreferrer" target="_blank" href="https://lin.ee/3yv7oLwpi">
      <div className={styles.icon}>
        <img src={lineLogo} alt={"Line"} />
        <img src={lineLogoColor} alt={"Line"} />
      </div>
    </a>
    <div className={styles.linksContainer}>
      <Link to="/privacy-policy">
        <span className={styles.link}>Privacy Policy</span>
      </Link>
      <Link to="/terms-of-use">
        <span className={styles.link}>Terms of Use</span>
      </Link>
      <Link to="/contact-us">
        <span className={styles.link}>Contact Us</span>
      </Link>
    </div>
    <p>2023 @ TABLE by 49CAP PTE. LTD.</p>
  </div>
)

export default Footer
