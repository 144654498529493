// @flow
import React from "react"
import moment from "moment"
import { SingleDatePicker as SingleDatePickerComponent } from "react-dates"
import { Icon } from "semantic-ui-react"

import "./styles.css"

type PropTypes = {
  value: ?Date,
  bookedBefore: ?number,
  onChange: Function,
  selectMonthAndYear?: Boolean,
  selectionFrom?: Boolean,
  bookFor: ?string,
  id?: string,
  placeholder?: string,
}
type StateTypes = {
  focused: ?string,
}

class SingleDatePicker extends React.Component<PropTypes, StateTypes> {
  state = {
    focused: null,
  }

  minimumDate = moment().add(2, "months")

  checkIsOutsideRange = (day, bookedBefore) =>
    day.isBefore(moment().add(bookedBefore ? bookedBefore : 0, "d"), "day") || day.isAfter(this.minimumDate, "day")

  render() {
    const { value, onChange, selectMonthAndYear, selectionFrom, id, placeholder, bookFor, bookedBefore } =
      this.props
    return (
      <div className={`${selectionFrom ? "selectionFrom" : ""} ${bookFor ? "bookFor" : ""}`}>
        <SingleDatePickerComponent
          date={value ? moment(value) : null}
          onDateChange={(date) => onChange(moment(date))}
          focused={this.state.focused}
          onFocusChange={({ focused }) => this.setState({ focused })}
          id={
            id ||
            (selectionFrom
              ? "SingleDatePicker_selectionFrom"
              : selectMonthAndYear
              ? "SingleDatePicker_selectMonthAndYear"
              : "SingleDatePicker")
          }
          readOnly
          firstDayOfWeek={1}
          hideKeyboardShortcutsPanel
          numberOfMonths={1}
          isOutsideRange={(day) => this.checkIsOutsideRange(day, bookedBefore)}
          showDefaultInputIcon
          inputIconPosition={selectionFrom ? "before" : "after"}
          placeholder={placeholder || (selectionFrom ? "Select date" : "")}
          displayFormat={selectionFrom ? "MMM DD" : "MMM, DD, YYYY"}
          renderMonthElement={
            selectMonthAndYear
              ? ({ month, onMonthSelect, onYearSelect }) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <select
                        style={{ fontSize: "0.75em" }}
                        value={month.month()}
                        onChange={(e) => {
                          onMonthSelect(month, e.target.value)
                        }}
                      >
                        {moment.months().map((label, value) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <select
                        style={{ fontSize: "0.75em" }}
                        value={month.year()}
                        onChange={(e) => {
                          onYearSelect(month, e.target.value)
                        }}
                      >
                        {years.map((year, i) => (
                          <option key={i} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )
              : undefined
          }
          navPrev={<Icon className={"DateRangePicker_prev"} name="angle left" size={"big"} />}
          navNext={<Icon className={"DateRangePicker_next"} name="angle right" size={"big"} />}
        />
      </div>
    )
  }
}

const years = (() => {
  const output = []
  for (let i = 0; i < 100; i++) output.push(moment().year() - i)
  return output
})()

export default SingleDatePicker
