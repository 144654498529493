// @flow
import { SET_LOCATION, UNSET_LOCATION } from "./constants"

type NavigationTypes = {
  location: ?string,
}

const NavigationReducer = (
  state: NavigationTypes = {
    location: null,
  },
  { type, payload }: { type: string, payload: string }
) => {
  switch (type) {
    case SET_LOCATION:
      console.log(SET_LOCATION, payload)
      return {
        ...state,
        location: payload,
      }
    case UNSET_LOCATION:
      return {
        ...state,
        location: null,
      }
    default:
      return state
  }
}

export default NavigationReducer
