// @flow
import React from "react"

import { containsValue, minChars, maxChars } from "./validations"

const schemaToFields = schema => {
  const fields = {}
  Object.keys(schema).forEach(key => {
    fields[key] = schema[key].defaultValue || ""
  })
  return fields
}

type PropTypes = {
  onChange: Function,
}

export default class FormValidation extends React.Component<PropTypes> {
  state = {
    isValid: false,
    fields: schemaToFields(this.props.schema),
    invalidFields: {},
    alertedFields: {},
    confirmedFields: {},
  }

  defaultValidationRules = {
    required: {
      on: "blur",
      errorMessage: "Field is required",
    },
    minLength: {
      on: "blur",
      errorMessage: opt => `Input must be at least ${opt} characters long`,
    },
    maxLength: {
      on: "change",
      errorMessage: opt => `Input may not be longer than ${opt} characters`,
    },
    confirm: {
      on: "blur",
      errorMessage: opt => `Input must match ${opt}`,
    },
  }

  componentDidMount() {
    this.initialValidation()
  }

  initialValidation() {
    const fields = { ...this.state.fields }
    const { schema } = this.props
    const invalidFields = {}
    Object.keys(schema).forEach(field => {
      Object.keys(schema[field]).forEach(key => {
        const validationError = this.validateField(
          key,
          fields[field],
          schema[field][key]
        )
        if (validationError && !invalidFields[field]) {
          if (Array.isArray(invalidFields[field]))
            invalidFields[field].push(validationError)
          else invalidFields[field] = [validationError]
        }
      })
    })
    this.setState({
      invalidFields,
      isValid: !Object.keys(invalidFields).length,
    })
  }

  validateField(rule, value, opt) {
    const errorMessage =
      this.defaultValidationRules[rule] &&
      this.defaultValidationRules[rule].errorMessage
    switch (rule) {
      case "required":
        return !containsValue(value) ? errorMessage : null
      case "minLength":
        return !minChars(value, opt) ? errorMessage(opt) : null
      case "maxLength":
        return !maxChars(value, opt) ? errorMessage(opt) : null
      case "customValidation":
        const isValid = opt.isValid(value)
        if (!isValid) {
          if (opt.errorMessage) {
            if (typeof opt.errorMessage === "function")
              return opt.errorMessage(value)
            return opt.errorMessage
          }
          return "Invalid input"
        }
        return null
      case "confirm":
        if (value !== this.state.fields[opt]) return errorMessage(opt)
        return null
      default:
        return undefined
    }
  }

  executeValidationOnField(field, value, event) {
    const { schema } = this.props
    const validationErrors = []
    const alertedValidationErrors = []
    Object.keys(schema[field]).forEach(rule => {
      const res = this.validateField(rule, value, schema[field][rule])
      if (res) {
        validationErrors.push(res)
        if (
          (rule === "customValidation" &&
            schema[field].customValidation.on === event) ||
          (this.defaultValidationRules[rule] &&
            this.defaultValidationRules[rule].on === event)
        ) {
          alertedValidationErrors.push(res)
        }
      }
    })
    const invalidFields = { ...this.state.invalidFields }
    const alertedFields = { ...this.state.alertedFields }
    if (validationErrors.length) invalidFields[field] = validationErrors
    else delete invalidFields[field]
    if (alertedValidationErrors.length)
      alertedFields[field] = alertedValidationErrors
    else delete alertedFields[field]
    this.setState({
      invalidFields,
      alertedFields,
      isValid: !Object.keys(invalidFields).length,
    })
  }

  handleChange(field, value) {
    const fields = { ...this.state.fields }
    fields[field] = value
    this.setState({ fields }, () => {
      this.executeValidationOnField(field, value, "change")
      this.props.onChange && this.props.onChange(this.state.fields)
    })
  }

  handleBlur(field) {
    this.executeValidationOnField(field, this.state.fields[field], "blur")
  }

  render = () =>
    this.props.children({
      ...this.state,
      fieldProps: field => ({
        onChange: e => this.handleChange(field, e.target ? e.target.value : e),
        onBlur: () => this.handleBlur(field),
        value: this.state.fields[field],
      }),
    })
}

// TODO:
// - implenent is touched or similar functionality for reverse confirmation
// - implement a way to set value to some field
