// @flow
export function containsValue(input: string): boolean {
  return Boolean(input)
}
export function onlyLettersAndSpaces(input: string): boolean {
  return Boolean(input.match(/^[A-Za-z][a-zA-Z\s]*$/))
}
export function onlyNumericAndSpaces(input: string): boolean {
  return Boolean(input.match(/^[0-9\s]*$/))
}
export function minChars(input: string, n: number): boolean {
  return input.length >= n
}
export function maxChars(input: string, n: number): boolean {
  return input.length <= n
}
export function isNotOnlyWhitespace(input: string): boolean {
  return Boolean(input.replace(/\s/g, "").length)
}
export function isEmail(input: string): boolean {
  return /[^\s@]+@[^\s@]+\.[^\s@]+/.test(input)
}
export function isName(input: string): boolean {
  return (
    Boolean(input) &&
    input.length >= 1 &&
    Boolean(input.match(/^[A-Za-z][a-zA-Z\s]*$/))
  )
}
export function isPhoneNumber(input: string): boolean {
  return Boolean(input.match(/^[+?0-9\s]*$/))
}
export function mustMatch(input: string, subject: string): boolean {
  return input === subject
}

export function onlyNumericAndLetters(input: string): boolean {
  return Boolean(input.match(/^([0-9]+[a-zA-Z]+|[a-zA-Z]+[0-9]+)[0-9a-zA-Z]*$/))
}

export function includesNumericAndLetters(input: string): boolean {
  let containsNumeric = false
  let containsLetters = false
  input.split().forEach(char => {
    if (char.match(/[a-zA-Z]/)) containsLetters = true
    if (char.match(/[0-9]/)) containsNumeric = true
  })
  return containsLetters && containsNumeric
}
