// @flow
import React from "react"
import { Link } from "react-router-dom"
import { Icon } from "semantic-ui-react"

import styles from "./styles.module.css"

const Back = ({ to, text = "Back", onClick }: { to?: string, text?: string, onClick?: Function }) =>
  to ? (
    <Link to={to} className={"link"}>
      <Icon className={styles.backIcon} name={"chevron left"} />
      <span className={styles.backLink}>{text} </span>
    </Link>
  ) : (
    <a href="#" className="link" onClick={onClick}>
      <Icon className={styles.backIcon} name={"chevron left"} />
      <span className={styles.backLink}>{text}</span>
    </a>
  )

export default Back
