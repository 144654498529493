// @flow

export function addOmiseScript(publicKey: string) {
  return new Promise<void>((resolve: Function) => {
    const omiseLoaded = () => {
      const { OmiseCard } = window
      OmiseCard.configure({
        publicKey,
      })
      resolve()
    }
    const { OmiseCard } = window
    if (OmiseCard && OmiseCard.configure) {
      return resolve()
    }
    const script = document.createElement("script")
    script.src = "https://cdn.omise.co/omise.js"
    script.onload = omiseLoaded
    document.body.appendChild(script)
  })
}

export function openOmisePopover(amount: number, currency: string) {
  return new Promise<string>((resolve: Function) => {
    const { OmiseCard } = window
    OmiseCard.open({
      amount: amount * 100,
      currency,
      defaultPaymentMethod: "credit_card",
      otherPaymentMethods: "alipay, credit_card, internet_banking",
      onCreateTokenSuccess: resolve,
    })
  })
}
