// @flow
import React from "react"
import * as _ from "lodash"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link, Redirect } from "react-router-dom"
import { Grid, Container, Divider } from "semantic-ui-react"
import ImageGallery from "react-image-gallery"

import EventsList from "containers/EventsList"
import SingleDatePicker from "components/SingleDatePicker"

import Back from "components/Back"
import List from "components/List"
import SubjectHeaders from "components/SubjectHeaders"
import Button from "components/Button"

import { fetchClub, resetClub } from "data/Club/actions"
import { fetchEvents } from "data/Events/actions"

import music from "assets/Icons/music.svg"
import masks from "assets/Icons/masks.svg"
import time from "assets/Icons/time.svg"
import pin from "assets/Icons/pin.svg"

import styles from "./styles.module.css"
import Loading from "../../components/Loading"
import { Helmet } from "react-helmet"

type PropTypes = {}
type StateTypes = {
  date: ?Date,
}

class Club extends React.Component<PropTypes, StateTypes> {
  param: ?string
  hasSearchParams: boolean
  param = this.props.location.pathname.split("/")[2]
  hasSearchParams = !!this.props.location.search

  state = {
    date: null,
  }

  componentDidMount() {
    if (!this.param) this.props.history.push("/venues")
    this.props.fetchClub(this.param)
  }

  componentWillUnmount() {
    this.props.resetClub()
  }

  handleDateChange(date: Date) {
    this.setState({ date }, () => this.props.fetchEvents({ from: this.state.date }))
  }

  render() {
    const { club } = this.props
    const [key] = Object.keys(club.segments)

    if (club.readSuccess && !club.segments[key]) {
      this.props.history.push("/venues")
    }

    if (club.readSuccess && key === this.props.location.pathname.split("/")[2]) {
      this.param = club.segments[key].slug
      this.hasSearchParams = false
    }

    const baseTitle = _.startCase(this.param.replaceAll("-", " "))

    return (
      <>
        <Helmet>
          <title>{`TABLE${club.readSuccess ? ` | ${club.segments[key].name}` : ` | ${baseTitle}`}`}</title>
          <meta
            name="title"
            content={`TABLE${club.readSuccess ? ` | ${club.segments[key].name}` : ` | ${baseTitle}`}`}
          />
          <meta
            name="description"
            content={`TABLE - check out our venue page for ${
              club.readSuccess ? club.segments[key].name : baseTitle
            } and start your booking.`}
          />
          {!this.hasSearchParams && club.readSuccess && key !== this.param && (
            <link rel="canonical" href={`https://tablebooking.co/club/${this.param}`} />
          )}
          {this.hasSearchParams && <meta name="robots" content="noindex,follow" />}
        </Helmet>

        {club.readSuccess && key === this.props.location.pathname.split("/")[2] && (
          <Redirect to={`/club/${club.segments[key].slug}`} />
        )}
        <Container className={`${styles.masterContainer} fadeIn`}>
          <Back to={"/venues"} />
          <Loading isLoading={club.readLoading}>
            <Divider hidden />
            <Grid stackable>
              <Grid.Column width={8}>
                <ImageGallery
                  slideInterval={3000}
                  lazyLoad
                  showNav={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  items={
                    club.segments[key] &&
                    club.segments[key].images &&
                    club.segments[key].images.map((e) => ({
                      original: e.path,
                      thumbnail: e.path,
                    }))
                  }
                />
              </Grid.Column>
              <Grid.Column width={8}>
                {club.readSuccess && club.segments[key] && (
                  <React.Fragment>
                    <SubjectHeaders title={club.segments[key].name} text={club.segments[key].description} />
                    <List items={club.readSuccess ? descriptionItems(club.segments[key]) : []} />
                    <div className={styles.bookContainer}>
                      <Link to={`/tables/${key}`}>
                        <Button fluid>BOOK NOW</Button>
                      </Link>
                    </div>
                  </React.Fragment>
                )}
              </Grid.Column>
            </Grid>

            <Divider hidden />

            <Grid stackable columns={2}>
              <Grid.Column>
                <h3>Upcoming events</h3>
              </Grid.Column>
              <Grid.Column textAlign={"right"}>
                <SingleDatePicker
                  selectionFrom
                  value={this.state.date}
                  onChange={(date) => this.handleDateChange(date)}
                />
              </Grid.Column>
            </Grid>
            <EventsList club={club.segments[key]} clubPage />
          </Loading>
        </Container>
      </>
    )
  }
}

const mstp = ({ club }) => ({ club })
const mdtp = (dispatch) => ({
  fetchClub: bindActionCreators(fetchClub, dispatch),
  fetchEvents: bindActionCreators(fetchEvents, dispatch),
  resetClub: bindActionCreators(resetClub, dispatch),
})

export default connect(mstp, mdtp)(Club)

const descriptionItems = ({ music_type, dress_code, address, open, closed }) => [
  {
    header: (
      <span>
        <img src={music} alt="Music icon" />
        Music
      </span>
    ),
    component: <span>{music_type || "Unknown"}</span>,
  },
  {
    header: (
      <span>
        <img src={masks} alt="Dress code icon" />
        Dress code
      </span>
    ),
    component: <span>{dress_code || "Unknown"}</span>,
  },
  {
    header: (
      <span>
        <img src={time} alt="Time icon" />
        Open hours
      </span>
    ),
    component: <span>{`${open || "?"} - ${closed || "?"}`}</span>,
  },
  {
    header: (
      <span>
        <img src={pin} alt={"Address icon"} />
        Address
      </span>
    ),
    component: <span>{address}</span>,
  },
]
