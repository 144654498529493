// @flow
import { CALL_API } from "middleware/api"

import { READ_REQUEST, READ_SUCCESS, READ_FAILURE, RESET_DATA } from "./constants"
import objectifyArray from "utils/objectifyArray"

export const fetchDeal = (deal_id: string) => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],

    promise: (httpClient) =>
      httpClient({
        url: "/deals/getOne",
        method: "get",
        params: { deal_id },
      }),
    payloadModifier: (payload) => {
      return payload.map((e) => {
        e.override_prices = objectifyArray(e.override_prices, "table_id")
        return e
      })
    },
  },
})

export function resetDeal() {
  return { type: RESET_DATA }
}
