// @flow
import React from "react"
import Lottie from "react-lottie"
// import * as animationData from "./animation.json" // workarounded - this throws error for React script v2

export default class MobileAppAnimation extends React.Component<{}> {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: require("./animation.json"), // eslint-disable-line no-undef
      rendererSettings: {
        scaleMode: "xMidYMid",
      },
    }

    return (
      <div>
        <Lottie
          style={{ height: "70vh", margin: "1em" }}
          options={defaultOptions}
        />
      </div>
    )
  }
}
