// @flow
import React from "react"
import moment from "moment"
import { Grid } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import Item from "components/Item"
import TimeLabel from "components/TimeLabel"

type PropTypes = {
  events: {
    segments: {},
    readSuccess: Boolean,
  },
  clubs: {
    segments: {},
    readSuccess: Boolean,
  },
  club?: {},
  clubPage?: Boolean,
}
type StateTypes = {
  segments: [],
}

class EventsList extends React.Component<PropTypes, StateTypes> {
  state = {
    segments: [],
  }

  componentDidMount() {
    this.separateItemsByDateSections()
  }

  componentDidUpdate(prevProps) {
    if (this.props.club && this.props.clubPage) {
      if (
        this.props.events.readSuccess &&
        (!prevProps.events.readSuccess || (!prevProps.club && this.props.club))
      ) {
        this.separateItemsByDateSections()
      }
    }

    if (
      this.props.clubs.readSuccess &&
      this.props.events.readSuccess &&
      (!prevProps.events.readSuccess || !prevProps.clubs.readSuccess)
    ) {
      this.separateItemsByDateSections()
    }
  }

  separateItemsByDateSections() {
    let items = Object.values(this.props.events.segments)
      .map((e) => {
        e.date = new Date(e.date)
        return e
      })
      .sort(({ date: a }, { date: b }) => a - b)

    if (this.props.club && this.props.clubPage) {
      items = items.filter((e) => e.club_id === this.props.club._id)
    }

    if (!this.props.club && this.props.clubPage) {
      items = []
    }

    let segments = []
    let sectionBuffer = {
      date: null,
      items: [],
    }
    let dateBuffer = null

    items.forEach((item, i) => {
      // set initial buffers
      if (!dateBuffer) {
        dateBuffer = item.date
        sectionBuffer.date = item.date
      }

      // create section of events for next date (which is larger than previous)
      // and push previous section buffer to segmeent list
      if (dateBuffer < item.date) {
        segments.push(sectionBuffer)
        sectionBuffer = {
          date: null,
          items: [],
        }
        dateBuffer = item.date
        sectionBuffer.date = item.date
        sectionBuffer.items.push(item)
      } else {
        sectionBuffer.items.push(item)
      }

      // if it last item push current section buffer to segments list
      if (i === items.length - 1) {
        segments.push(sectionBuffer)
      }
    })

    if (this.props.club && this.props.clubPage) {
      segments.forEach((segment, i) => {
        let isSegmentContainingCurrentClubId = false
        segment.items.forEach((item) => {
          if (item.club_id === this.props.club._id) isSegmentContainingCurrentClubId = true
        })
        if (!isSegmentContainingCurrentClubId) {
          segments[i].shouldDelete = true
        }
      })
      segments = segments.filter((e) => !e.shouldDelete)
    }

    return this.setState({ segments })
  }

  render() {
    return (
      <div>
        {this.state.segments.length ? (
          this.state.segments.map((segment, i) => {
            return (
              <div key={i} style={{ marginTop: "2em" }}>
                <h4>{moment(segment.date).format("MMM DD, dddd")}</h4>
                <Grid doubling stackable columns={3}>
                  {segment.items.map(({ _id, name, type, club_id, images }, j) => {
                    const club = this.props.clubPage ? this.props.club : this.props.clubs.segments[club_id]
                    const eventDate = moment(segment.date).format("YYYY-MM-DD")
                    return (
                      <Grid.Column key={`event-${j}`}>
                        <Link to={`/event/${_id}`}>
                          <Item
                            bookNowLink={`/tables/${club_id}?event=${_id}&date=${eventDate}`}
                            title={name}
                            subtitle={type}
                            image={images && images.length && images[0] && images[0].path}
                            underneathComponent={
                              <TimeLabel
                                start={(club && club.open) || (club && club.opening_hours) || "?"}
                                end={(club && club.closed) || (club && club.closing_hours) || "?"}
                              />
                            }
                            button={"Find out more"}
                          />
                        </Link>
                      </Grid.Column>
                    )
                  })}
                </Grid>
              </div>
            )
          })
        ) : (
          <p style={{ textAlign: "center", padding: "5em 1em", minHeight: "300px" }}>No upcoming events</p>
        )}
      </div>
    )
  }
}

const mstp = ({ events, clubs }) => ({ events, clubs })

export default connect(mstp, null)(EventsList)
