import { showNotification } from "wrappers/Notifications/actions"

export const NOTIFICATION = "NOTIFICATION"

export default store => next => async action => {
  if (!action[NOTIFICATION] || typeof action[NOTIFICATION] !== "object") {
    return next(action)
  }
  const { successMessage, errorMessage } = action[NOTIFICATION]

  if (!successMessage && !errorMessage) return next(action)
  if (successMessage) showNotification(successMessage, next)
  if (errorMessage) showNotification(errorMessage, next)
}
