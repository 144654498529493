const def = [
  { name: "nav-home", text: "Home", uri: "/" },
  { name: "nav-deals", text: "Deals & Experiences", uri: "/deals" },
  { name: "nav-venues", text: "Venues", uri: "/venues" },
  { name: "nav-events", text: "Events", uri: "/events" },
  { name: "nav-insider", text: "Insider", uri: "/insider", target: "_self" },
  { name: "nav-concierge", text: "Concierge", uri: "/concierge" },
]

export const mobile = [
  ...def,
  { name: "nav-login", text: "Login", uri: "/login" },
  { name: "nav-register", text: "Signup", uri: "/register" },
]

export default def
