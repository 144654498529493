// @flow
import React from "react"

import styles from "./styles.module.css"

type PropTypes = {
  title: string,
  subtitle: string,
  size?: string,
}

const TitleAndSubtitle = ({
  title,
  subtitle,
  size = "small",
  withPadding,
}: PropTypes) => (
  <div
    className={`${styles.masterContainer} ${styles[size]} ${
      withPadding ? styles.withPadding : ""
    }`}
  >
    <h1>{title}</h1>
    <h2>{subtitle}</h2>
  </div>
)

export default TitleAndSubtitle
