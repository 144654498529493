// @flow
import React from "react"
import moment from "moment"
import { Grid, Divider, TextArea } from "semantic-ui-react"

import Button from "components/Button"
import LabeledIcon from "components/LabeledIcon"
import OmiseButton from "components/OmiseButton"

import calendar from "assets/Icons/calendar.svg"
import man from "assets/Icons/man.svg"
import selection from "assets/Icons/selection.svg"
import styles from "./styles.module.css"

const omisePublicKey = window._env_ && window._env_.OMISE_PUBLIC_KEY

type PropTypes = {
  booked: boolean,
  onClick: Function,
  onTokenReceived: Function,
  onSourceReceived: Function,
  active: boolean,
  name: string,
  date: Date,
  open: string,
  closed: string,
  capacity: number,
  minSpend: number,
  additionalInfo: {
    title: string,
    content: string,
  },
  notice: string,
  onNoticeChange: Function,
  //promo code
  onPromoCodeChange: Function,
  applyPromotion: Function,
  promoCode: string,
  codeApplied: boolean,
  validatingCode: boolean,
  promotion: Object,
  errorLog: string,
  //promo code
  hasPayButton: boolean,
  hasBookingButton: boolean,
  checkShouldProccessedWithCheckout: Function,
}

export default class TableCard extends React.Component<PropTypes> {
  renderAmount(minSpend) {
    if (!minSpend) {
      return minSpend === 0 ? "Reservation Only" : "Unknown amount"
    }

    return `฿ ${minSpend}`
  }

  render() {
    const {
      booked,
      onClick,
      onTokenReceived,
      onSourceReceived,
      active,
      name,
      date,
      open,
      closed,
      capacity,
      minSpend,
      additionalInfo,
      onNoticeChange,
      notice,
      onPromoCodeChange,
      applyPromotion,
      promoCode,
      codeApplied,
      validatingCode,
      promotion,
      errorLog,
      hasPayButton,
      hasBookingButton,
      checkShouldProccessedWithCheckout,
    } = this.props
    return (
      <div className={styles.container}>
        {active ? (
          <React.Fragment>
            <h1>{name || "Unknown name"}</h1>
            <Grid divided columns={"equal"}>
              <Grid.Column width={10}>
                <img className={styles.dateIcon} src={calendar} alt={"Calendar"} />
                <span className={styles.date}>
                  {(date && moment(date).format("MMM DD, dddd")) || "Unkown date"}
                </span>
                <p>
                  <span className={styles.time}>{open || "Unknown open time"}</span>
                  <span className={styles.spacing} />
                  <span className={styles.time}> {closed || "Unknown closing time"}</span>
                </p>
              </Grid.Column>
              <Grid.Column>
                <LabeledIcon count={capacity || "Unkown capacity"} image={man} />
              </Grid.Column>
            </Grid>
            <Divider hidden />
            <div className={styles.info}>
              <h3>{(additionalInfo && additionalInfo.title) || "Unkown title"}</h3>
              <p>{(additionalInfo && additionalInfo.content) || "Unknown additional info content"}</p>
            </div>
            <Divider />
            {minSpend !== 0 && (
              <>
                <div className={styles.promoCode}>
                  <div className={styles.codeForm}>
                    <label className="dark">Promotion Code</label>
                    <input
                      type="text"
                      value={promoCode}
                      className="quantity light"
                      onChange={(e) => onPromoCodeChange(e.target.value)}
                    />
                  </div>
                  <Button
                    disabled={validatingCode || codeApplied}
                    className={styles.codeApplyButton}
                    onClick={() => applyPromotion()}
                    fluid
                  >
                    {"Apply"}
                  </Button>
                </div>
              </>
            )}
            {promotion && (
              <p style={{ color: "#f54073" }}>
                {`Code ${promotion.name} Applied: -${promotion.amount}${
                  promotion.promotion_type === "flat_discount" ? "THB" : "%"
                }`}
              </p>
            )}
            {errorLog && <p style={{ color: "red" }}>{errorLog}</p>}
            <div className={styles.minSpend}>
              <p>{this.renderAmount(minSpend)}</p>
            </div>
            <label className={styles.inputLabel}>Leave us a note:</label>
            <TextArea
              onChange={(e, { value }) => onNoticeChange(value)}
              value={notice}
              rows={2}
              autoHeight
              placeholder="Please type in your estimated arrival time & the number of people in your group"
              className={styles.input}
            />
            {hasBookingButton && (
              <Button
                disabled={booked}
                style={{ fontSize: "14px", marginBottom: ".25em" }}
                onClick={onClick}
                fluid
              >
                {"Book Now (Pay at Venue)"}
              </Button>
            )}
            {hasPayButton && (
              <OmiseButton
                isDisabled={booked}
                amount={minSpend}
                promotion={promotion ? promotion._id : null}
                checkShouldProccessedWithCheckout={checkShouldProccessedWithCheckout}
                title="Book Now (Pay Now)"
                publicKey={omisePublicKey}
                onTokenReceived={onTokenReceived}
                onSourceReceived={onSourceReceived}
              />
            )}
            {booked ? <p className={styles.warning}>Table already booked!</p> : null}
          </React.Fragment>
        ) : (
          <div className={styles.inactiveContainer}>
            <img src={selection} alt={"Selection"} />
            <h2>{"Choose an option to proceed with booking!"}</h2>
            <Divider />
            <div className={styles.minSpend}>
              <p>{"฿ 00.00"}</p>
            </div>
            {hasBookingButton && (
              <Button disabled style={{ fontSize: "14px", marginBottom: ".25em" }} fluid>
                {"Book Now (Pay at Venue)"}
              </Button>
            )}
            {hasPayButton && (
              <Button disabled style={{ fontSize: "14px" }} fluid>
                {"Book Now (Pay Now)"}
              </Button>
            )}
          </div>
        )}
      </div>
    )
  }
}
