// @flow
import React from "react"
import { Icon, Divider } from "semantic-ui-react"
import { Link, useParams } from "react-router-dom"

import Button from "components/Button"

import styles from "./styles.module.css"
import AccessControl from "../../components/AccessControl/AccessControl"

const LabeledIcon = ({ icon }: { icon: string }) => (
  <div className={styles.labelContainer}>
    <Icon className={styles.icon} name={icon} size={"big"} inverted />
  </div>
)

const Confirmation: React.FC = () => {
  const { deal = false } = useParams()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={`${styles.container} fadeIn`}>
      <AccessControl
        renderAuthorized={
          <React.Fragment>
            <LabeledIcon icon={"checkmark"} />
            <h1>Awesome, we have received your booking.</h1>
            <p>
              For already paid bookings, please look out for your confirmation email.
              <br />
              For (non-paid) booking inquiries, we will get back to you with confirmation as soon as we can.
            </p>
            {!deal && (
              <Link to="/bookings">
                <Button basic>View your bookings</Button>
              </Link>
            )}
          </React.Fragment>
        }
        renderUnauthorized={
          <React.Fragment>
            <LabeledIcon icon={"times"} />
            <h1>You’re not logged in</h1>
            <p>Please log in or create an account to make a booking</p>
            <Divider hidden />
            <div className={styles.buttonsContainer}>
              <Link to="/login">
                <Button style={{ fontSize: "1.2em", marginBottom: "0.5em" }} fluid basic>
                  Login
                </Button>
              </Link>
              <Link to="/register">
                <Button fluid simple>
                  Create account
                </Button>
              </Link>
            </div>
          </React.Fragment>
        }
      />
    </div>
  )
}

export default Confirmation
