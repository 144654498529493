import { SET, UNSET } from "./constants"

const defaultState = {
  id: null,
  email: null,
  role: null,
  isLoggedIn: false,
}

const UserReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET:
      return {
        ...state,
        ...payload,
        isLoggedIn: true,
        role: "authorized",
      }
    case UNSET:
      return {
        ...defaultState,
      }
    default:
      return state
  }
}

export default UserReducer
