// @flow

import React, { useEffect, useState } from "react"
import { addOmiseScript, openOmisePopover } from "../../utils/omise"
import Button from "components/Button"

type Props = {
  amount: number,
  currency?: string,
  title?: string,
  css?: Object,
  publicKey: string,
  isDisabled: Boolean,
  onTokenReceived: Function,
  onSourceReceived: Function,
  onError?: Function,
  checkShouldProccessedWithCheckout: Function,
}

export default function OmiseButton({
  amount,
  currency = "THB",
  title = "Checkout With Omise",
  qty = 1,
  promotion = null,
  publicKey,
  onTokenReceived,
  onSourceReceived,
  onError = () => {},
  isDisabled,
  checkShouldProccessedWithCheckout,
}: Props) {
  const [isLoading, setIsLoading] = useState(true)

  function configureOmise() {
    addOmiseScript(publicKey).then(() => {
      setIsLoading(false)
    })
  }

  async function checkoutClicked() {
    if (!checkShouldProccessedWithCheckout()) {
      return
    }
    try {
      const response = await openOmisePopover(amount, currency)
      if (response.startsWith("tokn_")) {
        onTokenReceived(response, qty, amount, promotion)
      } else {
        // this is not a credit card
        onSourceReceived(response, qty, amount, promotion)
      }
    } catch (e) {
      onError(e)
    }
  }

  useEffect(configureOmise, [])

  return (
    <Button onClick={checkoutClicked} disabled={isDisabled || isLoading} style={{ fontSize: "14px" }} fluid>
      {isLoading ? "Loading..." : title}
    </Button>
  )
}
