// @flow
import * as React from "react"
import { connect } from "react-redux"

import authService from "instances/AuthService"

type PropTypes = {
  user: UserType,
}
type StateTypes = {
  allowRender: boolean,
}

const withSession = (Component: React.ComponentType<any>) => {
  class Session extends React.Component<PropTypes, StateTypes> {
    state = { allowRender: false }
    async componentDidMount() {
      try {
        if (authService.getToken("refresh_token") && !this.props.user.isLoggedIn) {
          await authService.refreshTokens()
          await authService.fetchUser()
          return this.setState({ allowRender: true })
        } else {
          return this.setState({ allowRender: true })
        }
      } catch (e) {
        console.error(e)
        authService.logout()
        this.setState({ allowRender: true })
        // throw e
      }
    }

    render() {
      return this.state.allowRender ? <Component {...this.props} /> : null
    }
  }

  const mstp = ({ user }) => ({ user })

  // const mdtp = dispatch => ({})

  return connect(
    mstp,
    null
  )(Session)
}

export default withSession
