// @flow
import * as React from "react"

import styles from "./styles.module.css"

type PropTypes = {
  image: string,
  dimmed: ?boolean,
}

const withPageBackground = ({ image, dimmed }: PropTypes) => (
  Component: React.ComponentType<*>
) => (props: PropTypes) => (
  <div className={styles.masterContainer}>
    <img
      className={`${styles.backgroundImage} ${dimmed ? styles.dimmed : ""}`}
      src={image}
      alt="Cover background"
    />
    <Component {...props} />
  </div>
)

export default withPageBackground
