// @flow
import React from "react"
import { Loader } from "semantic-ui-react"

const defaultProps = {
  isLoading: true,
}

type PropTypes = {
  isLoading: boolean,
  children: any,
}

const Loading = ({ isLoading, children }: PropTypes) => {
  return (
    <React.Fragment>{isLoading ? <Loader active /> : children}</React.Fragment>
  )
}

Loading.defaultProps = defaultProps

export default Loading
