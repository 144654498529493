import {
  TABLES,
  BOOKING_STATUSES_REQUEST,
  BOOKING_STATUSES_SUCCESS,
  BOOKING_STATUSES_FAILURE,
} from "./constants"

import commonReducer from "utils/commonReducer"

const initialState = {
  bookingStatusesLoading: false,
  bookingStatusesSuccess: false,
  bookingStatusesError: false,
  bookingStatuses: {},
}

const TablesReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case BOOKING_STATUSES_REQUEST: {
      return {
        ...state,
        bookingStatusesLoading: true,
        bookingStatusesSuccess: false,
        bookingStatusesError: false,
      }
    }
    case BOOKING_STATUSES_SUCCESS: {
      return {
        ...state,
        bookingStatusesLoading: false,
        bookingStatusesSuccess: true,
        bookingStatuses: payload,
      }
    }
    case BOOKING_STATUSES_FAILURE: {
      return {
        ...state,
        bookingStatusesLoading: false,
        bookingStatusesError: true,
      }
    }
    default:
      return state
  }
}

export default commonReducer(TABLES, TablesReducer, initialState)
