// @flow
import React from "react"
import DOMPurify from "dompurify"
import { Divider } from "semantic-ui-react"

import { instance as axios } from "instances/Axios"

import Button from "components/Button"
import OmiseButton from "components/OmiseButton"

import selection from "assets/Icons/selection.svg"
import styles from "./styles.module.css"

const omisePublicKey = window._env_ && window._env_.OMISE_PUBLIC_KEY

type PropTypes = {
  name: string,
  booked: boolean,
  onClick: Function,
  onTokenReceived: Function,
  onSourceReceived: Function,
  active: boolean,
  conditions: string,
  minSpend: number,
  hasPayButton: boolean,
  hasBookingButton: boolean,
  checkShouldProccessedWithCheckout: Function,
}

export default class DealCard extends React.Component<PropTypes, StateTypes> {
  state = {
    qty: 1,
    totalSpent: 0,
    promoCode: "",
    codeApplied: false,
    validatingCode: false,
    promotion: null,
    errorLog: null,
  }

  componentDidMount() {
    this.setState({ ...this.state, totalSpent: this.props.minSpend })
  }

  componentDidUpdate(prevProps) {
    const { minSpend } = this.props
    if (prevProps.minSpend !== minSpend && minSpend > 0) {
      let totalSpent = minSpend * this.state.qty

      const promotion = this.state.promotion
      if (promotion) {
        totalSpent =
          promotion.promotion_type === "flat_discount"
            ? totalSpent - promotion.amount
            : totalSpent - totalSpent * (promotion.amount / 100)
      }

      this.setState({ ...this.state, totalSpent: Math.round(totalSpent) })
    }
  }

  onPromoCodeChange = (value: string) => {
    this.setState({
      ...this.state,
      promoCode: value,
      promotion: null,
      totalSpent: this.props.minSpend * this.state.qty,
      codeApplied: false,
      errorLog: null,
    })
  }

  async applyPromotion() {
    this.setState({
      ...this.state,
      promotion: null,
      validatingCode: true,
    })
    try {
      const response = await axios.get("/promotions/validate", {
        params: {
          name: this.state.promoCode,
          club_id: this.props.clubId,
        },
      })

      if (response.data.success) {
        const promotion = response.data.data[0]
        const discountedTotalSpent =
          promotion.promotion_type === "flat_discount"
            ? this.state.totalSpent - promotion.amount
            : this.state.totalSpent - this.state.totalSpent * (promotion.amount / 100)
        this.setState({
          ...this.state,
          promotion,
          codeApplied: true,
          validatingCode: false,
          errorLog: null,
          totalSpent: Math.round(discountedTotalSpent),
        })
      }
    } catch (e) {
      this.setState({
        ...this.state,
        errorLog: "This Promo Code is not valid or has expired",
        validatingCode: false,
      })
    }
  }

  onQtyChange = (value: number) => {
    let changeQty = parseInt(value)
    if (changeQty > 10) {
      changeQty = 10
    }

    let totalSpent = this.props.minSpend * (changeQty && changeQty > 1 ? changeQty : 1)

    const promotion = this.state.promotion
    if (promotion) {
      totalSpent =
        promotion.promotion_type === "flat_discount"
          ? totalSpent - promotion.amount
          : totalSpent - totalSpent * (promotion.amount / 100)
    }

    this.setState({
      ...this.state,
      qty: changeQty || "",
      totalSpent: Math.round(totalSpent),
    })
  }

  validateQty = (value: number) => {
    let changeQty = parseInt(value)
    if (changeQty < 1 || !changeQty) {
      changeQty = 1
    }

    let totalSpent = this.props.minSpend * changeQty
    const promotion = this.state.promotion
    if (promotion) {
      totalSpent =
        promotion.promotion_type === "flat_discount"
          ? totalSpent - promotion.amount
          : totalSpent - totalSpent * (promotion.amount / 100)
    }

    this.setState({ ...this.state, qty: changeQty, totalSpent: Math.round(totalSpent) })
  }

  renderAmount(minSpend) {
    if (!minSpend) {
      return minSpend === 0 ? "Reservation Only" : "Unknown amount"
    }

    return `฿ ${minSpend}`
  }

  render() {
    const {
      name,
      booked,
      onClick,
      onTokenReceived,
      onSourceReceived,
      active,
      conditions,
      hasPayButton,
      hasBookingButton,
      checkShouldProccessedWithCheckout,
    } = this.props
    return (
      <div className={styles.container}>
        {active ? (
          <React.Fragment>
            <h1>{name || "Unknown name"}</h1>
            <Divider hidden />
            <div className={styles.promoCode}>
              <div className={styles.codeForm}>
                <label className="dark">Promotion Code</label>
                <input
                  type="text"
                  value={this.state.promoCode}
                  className="quantity light"
                  onChange={(e) => this.onPromoCodeChange(e.target.value)}
                />
              </div>
              <Button
                disabled={this.state.validatingCode || this.state.codeApplied}
                className={styles.codeApplyButton}
                onClick={() => this.applyPromotion()}
                fluid
              >
                {"Apply"}
              </Button>
            </div>
            {this.state.promotion && (
              <p style={{ color: "#f54073" }}>
                {`Code ${this.state.promotion.name} Applied: -${this.state.promotion.amount}${
                  this.state.promotion.promotion_type === "flat_discount" ? "THB" : "%"
                }`}
              </p>
            )}
            {this.state.errorLog && <p style={{ color: "red" }}>{this.state.errorLog}</p>}
            <div className={styles.minSpend}>
              <p>{this.renderAmount(this.state.totalSpent)}</p>
              <div className={"qtySelector"}>
                <label className="dark">Qty</label>
                <input
                  type="number"
                  value={this.state.qty}
                  className="quantity light"
                  min="1"
                  max="10"
                  onBlur={(e) => this.validateQty(e.target.value)}
                  onChange={(e) => this.onQtyChange(e.target.value)}
                />
              </div>
            </div>
            <Divider />
            <h1>Terms & Conditions:</h1>
            <div className={styles.info} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(conditions) }} />
            <Divider hidden />
            {hasBookingButton && (
              <Button
                disabled={booked}
                style={{ fontSize: "14px", marginBottom: ".25em" }}
                onClick={() =>
                  onClick(
                    this.state.qty,
                    this.state.totalSpent,
                    this.state.promotion ? this.state.promotion._id : null
                  )
                }
                fluid
              >
                {"Booking Inquiry"}
              </Button>
            )}
            {hasPayButton && (
              <OmiseButton
                isDisabled={booked}
                amount={this.state.totalSpent}
                checkShouldProccessedWithCheckout={checkShouldProccessedWithCheckout}
                title="Buy Now"
                qty={this.state.qty}
                promotion={this.state.promotion ? this.state.promotion._id : null}
                publicKey={omisePublicKey}
                onTokenReceived={onTokenReceived}
                onSourceReceived={onSourceReceived}
              />
            )}
            {booked ? <p className={styles.warning}>Deal already booked!</p> : null}
          </React.Fragment>
        ) : (
          <div className={styles.inactiveContainer}>
            <img src={selection} alt={"Selection"} />
            <h2>{"Choose a deal / experience to proceed with booking!"}</h2>
            <Divider />
            <div className={styles.minSpend}>
              <p>{"฿ 00.00"}</p>
            </div>
            {hasBookingButton && (
              <Button disabled style={{ fontSize: "14px", marginBottom: ".25em" }} fluid>
                {"Booking Inquiry"}
              </Button>
            )}
            {hasPayButton && (
              <Button disabled style={{ fontSize: "14px" }} fluid>
                {"Buy Now"}
              </Button>
            )}
          </div>
        )}
      </div>
    )
  }
}
