// @flow
import * as React from "react"

import style from "./style.module.css"
import type { NotificationType } from "./types"

const Notification = ({ content, icon, type }: NotificationType) => (
  <div className={style.notification}>{content}</div>
)

export default Notification
