// @flow
import React from "react"
import moment from "moment"
import * as _ from "lodash"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Container, Divider, Grid } from "semantic-ui-react"
import ImageGallery from "react-image-gallery"
import { Link, Redirect } from "react-router-dom"

import Back from "components/Back"
import SubjectHeaders from "components/SubjectHeaders"
import Button from "components/Button"
import Loading from "components/Loading"

import { fetchDeal, resetDeal } from "data/Deal/actions"

import styles from "./styles.module.css"
import { Helmet } from "react-helmet"

type PropTypes = {
  deals: {
    readLoading: boolean,
    readSuccess: boolean,
    segments: {},
  },
  fetchDeals: Function,
  location: {
    pathname: string,
  },
}

class Deal extends React.Component<PropTypes> {
  param: ?string
  param = this.props.location.pathname.split("/")[2]
  hasSearchParams: boolean
  hasSearchParams = !!this.props.location.search

  componentDidMount() {
    if (!this.param) this.props.history.push("/deals")
    this.props.fetchDeal(this.param)
  }

  componentWillUnmount() {
    this.props.resetDeal()
  }

  render() {
    const { deal } = this.props

    const [key] = Object.keys(deal.segments)
    if (deal.readSuccess && !deal.segments[key]) {
      this.props.history.push("/deals")
    }

    if (deal.readSuccess && key === this.props.location.pathname.split("/")[2]) {
      this.param = deal.segments[key].slug
      this.hasSearchParams = false
    }

    const club = deal.segments[key] && deal.segments[key].club_id && deal.segments[key].club[0]

    const baseTitle = _.startCase(this.param.replaceAll("-", " "))
    return (
      <>
        <Helmet>
          <title>{`TABLE${deal.readSuccess ? ` | ${deal.segments[key].name}` : ` | ${baseTitle}`}`}</title>
          <meta
            name="title"
            content={`TABLE${deal.readSuccess ? ` | ${deal.segments[key].name}` : ` | ${baseTitle}`}`}
          />
          <meta
            name="description"
            content={`TABLE - check out our deal page for ${
              deal.readSuccess ? deal.segments[key].name : baseTitle
            }!`}
          />
          {!this.hasSearchParams && deal.readSuccess && key !== this.param && (
            <link rel="canonical" href={`https://tablebooking.co/deal/${this.param}`} />
          )}
          {this.hasSearchParams && <meta name="robots" content="noindex,follow" />}
        </Helmet>
        {deal.readSuccess && key === this.props.location.pathname.split("/")[2] && (
          <Redirect to={`/deal/${deal.segments[key].slug}`} />
        )}
        <Container className={`${styles.masterContainer} fadeIn`}>
          <Back to="/deals" />
          <Divider hidden />
          <Loading isLoading={deal.readLoading}>
            <Grid stackable>
              <Grid.Column width={8}>
                <ImageGallery
                  slideInterval={3000}
                  lazyLoad
                  showNav={false}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  // defaultImage={placeholder}
                  items={
                    deal.segments[key] &&
                    deal.segments[key].images &&
                    deal.segments[key].images.map((e) => ({
                      original: e.path,
                      thumbnail: e.path,
                    }))
                  }
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <SubjectHeaders
                  title={deal.segments[key] && deal.segments[key].name}
                  subtitle={club ? club.name : "-"}
                  text={club ? club.description : "-"}
                  label={
                    deal.segments[key] &&
                    deal.segments[key].date &&
                    moment(deal.segments[key].date).format("MMM DD, dddd")
                  }
                  link={"/deals"}
                />
                <div className={styles.bookContainer}>
                  <Link to={`/book-deal/${deal.segments[key] && deal.segments[key].club_id}?deal=${key}`}>
                    <Button fluid>BOOK NOW</Button>
                  </Link>
                </div>
              </Grid.Column>
            </Grid>
          </Loading>
        </Container>
      </>
    )
  }
}

const mstp = ({ deal }) => ({ deal })
const mdtp = (dispatch) => ({
  fetchDeal: bindActionCreators(fetchDeal, dispatch),
  resetDeal: bindActionCreators(resetDeal, dispatch),
})
export default connect(mstp, mdtp)(Deal)
