// @flow

import { CALL_API } from "middleware/api"
import { instance as httpClient } from "instances/Axios"
import history from "instances/History"
import { showNotification } from "wrappers/Notifications/actions"
import { OMISE_CHARGE_START, OMISE_CHARGE_SUCCESS, OMISE_CHARGE_FAILURE } from "./constants"
import { bookTable } from "../Bookings/actions"
import type { BookingType } from "./types"
import ReactPixel from "react-facebook-pixel"

type PaymentType = {
  booking_id: string,
  amount: number,
  token: string,
}

export const chargeFromCard = (data: PaymentType) => ({
  [CALL_API]: {
    types: [OMISE_CHARGE_START, OMISE_CHARGE_SUCCESS, OMISE_CHARGE_FAILURE],
    promise: (httpClient) =>
      httpClient({
        method: "post",
        url: "/payment/omise/card",
        data,
      }),
  },
})

export const chargeFromSource = (data: PaymentType) => ({
  [CALL_API]: {
    types: [OMISE_CHARGE_START, OMISE_CHARGE_SUCCESS, OMISE_CHARGE_FAILURE],
    promise: (httpClient) =>
      httpClient({
        method: "post",
        url: "/payment/omise/source",
        data,
      }),
  },
})

const isSuccessType = (action) => {
  return action && action.type && action.type.includes("SUCCESS")
}

const isFailureType = (action) => {
  return action && action.type && action.type.includes("FAILURE")
}

export const bookTableAndPay =
  (
    { table_id, deal_id, contact, date, comments, minSpend, qty, promotion }: BookingType,
    token: string,
    isSourcePayment: boolean = false,
    isDeal: ?boolean = false
  ) =>
  async (dispatch: Function) => {
    const bookingCreateType = await dispatch(
      bookTable({
        table_id,
        deal_id,
        promotion_id: promotion,
        contact,
        date,
        comments,
        qty,
        minimum_spend: minSpend,
      })
    )

    if (isFailureType(bookingCreateType)) {
      showNotification("Failed to book selected table, it might be already booked by someone else", dispatch)
      return
    }

    if (isSuccessType(bookingCreateType)) {
      const { booking_id } = bookingCreateType.payload
      const return_uri = window.location.port
        ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}/confirmation`
        : `${window.location.protocol}//${window.location.hostname}/confirmation`
      let paymentResult
      if (isSourcePayment) {
        paymentResult = await dispatch(
          chargeFromSource({
            booking_id,
            amount: minSpend,
            token,
            return_uri,
          })
        )
      } else {
        paymentResult = await dispatch(
          chargeFromCard({
            booking_id,
            amount: minSpend,
            token,
            return_uri,
          })
        )
      }

      if (isFailureType(paymentResult)) {
        showNotification("Failed to process payment for your booking", dispatch)
        return
      }

      if (isSuccessType(paymentResult)) {
        if (window && window.gtag && typeof window.gtag === "function") {
          window.gtag("config", "AW-757063567")
          window.gtag("event", "conversion", {
            send_to: "AW-757063567/_DcWCOPym5cBEI-__-gC",
            value: minSpend,
            currency: "THB",
          })
        }
        if (window && window.fbq && typeof window.fbq === "function") {
          window.fbq("track", "Purchase", {
            value: minSpend,
            currency: "THB",
          })
        }
        ReactPixel.track("Purchase", {
          value: minSpend,
          currency: "THB",
        })

        const { authorize_uri } = paymentResult.payload
        if (authorize_uri) {
          window.location = authorize_uri
          return
        }
        history.push(isDeal ? "/confirmation/deal" : "/confirmation")
      }
    }
  }
