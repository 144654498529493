// @flow
import React from "react"
import LazyLoad from "react-lazyload"
import { Loader } from "semantic-ui-react"
import DOMPurify from "dompurify"
import { Link } from "react-router-dom"

import Button from "components/Button"

import styles from "./styles.module.css"

const Item = ({
  title,
  subtitle,
  image,
  label,
  underneathComponent,
  button,
  bookNowLink,
  isDeal = false,
}: {
  title: string,
  subtitle: string,
  image: string,
  label?: string,
  underneathComponent?: any,
  button?: string,
  bookNowLink: string,
  isDeal?: boolean,
}) => (
  <div className={styles.masterContainer}>
    {label && <div className={styles.label}>{label}</div>}
    <LazyLoad height={200} placeholder={<Loader active />}>
      <div className={styles.imageContainer}>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      </div>
    </LazyLoad>
    <h1>{title}</h1>
    <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subtitle && shortern(subtitle)) }} />
    {underneathComponent}
    {button && (
      <div className={styles.buttonContainer}>
        <Button basic className={styles.detailButton}>
          {button}
        </Button>
        <Link to={bookNowLink}>
          <Button small className={styles.bookButton}>
            Book now
          </Button>
        </Link>
      </div>
    )}
  </div>
)

function shortern(text: string, max = 120): string {
  if (text.length > max) return `${text.substring(0, max)}...`
  return text
}

export default Item
