// @flow
import React from "react"
import moment from "moment"
import qs from "qs"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { instance as axios } from "instances/Axios"
import { Container, Divider, Grid, Responsive, Loader } from "semantic-ui-react"

import Back from "components/Back"
import TitleAndSubtitle from "components/TitleAndSubtitle"
import TableItem from "components/TableItem"
import TableCard from "components/TableCard"
import SingleDatePicker from "components/SingleDatePicker"

import { fetchTablesAndStatuses } from "data/Tables/actions"
import { bookTableAndRedirect } from "data/Bookings/actions"
import { chargeFromCard, bookTableAndPay } from "data/Omise/actions"
import { setLocation } from "data/Navigation/actions"
import { fetchEvents } from "data/Events/actions"

import styles from "./styles.module.css"
import DOMPurify from "dompurify"

type PropTypes = {
  fetchEvents: Function,
  setLocation: Function,
  fetchTablesAndStatuses: Function,
  history: {
    push: Function,
  },
  location: {
    pathname: string,
    search: string,
  },
  events: {
    readSuccess: Boolean,
    segments: {},
  },
  tables: {},
}

type TableType = {
  name: string,
  maxGuests: number,
  minimum_spend: number,
}

type StateTypes = {
  tables: Array<TableType>,
  active: ?string,
  notice: string,
  promoCode: string,
  codeApplied: boolean,
  validatingCode: boolean,
  promotion: Object,
  errorLog: string,
}

class Tables extends React.Component<PropTypes, StateTypes> {
  clubId: ?string
  clubId = this.props.location.pathname.split("/")[2]

  params: {}
  params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

  state = {
    active: null,
    notice: "",
    date: this.params.date ? moment(this.params.date) : moment(),
    passEventBookingWindow: false,
    promoCode: "",
    codeApplied: false,
    validatingCode: false,
    promotion: null,
    errorLog: "",
  }

  async componentDidMount() {
    const { events, clubs } = this.props

    if (!this.clubId) this.props.history.push("/events")

    const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    await this.props.fetchEvents({
      from: query.date ? moment(query.date) : moment(),
      to: query.date ? moment(query.date) : moment(),
    })

    if (events.readSuccess) {
      this.props.fetchTablesAndStatuses({
        date: (events.segments[this.params.event] && events.segments[this.params.event].date) || query.date,
        club: this.clubId,
      })
    }

    if (clubs.readSuccess) {
      const club = clubs.segments[this.clubId]
      if (club.booked_before && this.state.date.isBefore(moment().add(club.booked_before, "d"), "date")) {
        this.setState(
          {
            ...this.state,
            date: moment().add(club.booked_before, "d"),
            passEventBookingWindow: !!this.params.event,
          },
          () => {
            query.date = moment(this.state.date).format("YYYY-MM-DD")
            query.event = undefined
            const queryString = qs.stringify(query, {
              addQueryPrefix: true,
            })
            this.props.history.push(`${this.props.location.pathname}${queryString}`)
          }
        )
      }
    }

    // if (!this.props.location.search.includes("event")) {
    //   Object.keys(events.segments).forEach((key) => {
    //     const event = events.segments[key]
    //     if (event.club_id === this.clubId && moment(event.date).format("YYYY-MM-DD") === query.date) {
    //       const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    //       query.event = event._id
    //       const queryString = qs.stringify(query, {
    //         addQueryPrefix: true,
    //       })
    //       return this.props.history.push(`${this.props.location.pathname}${queryString}`)
    //     }
    //   })
    // }

    // return this.props.fetchTablesAndStatuses({
    //   date: query.date,
    //   club: this.clubId,
    // })
  }

  componentDidUpdate(prevProps) {
    const { events, clubs } = this.props
    if (!prevProps.events.readSuccess && events.readSuccess) {
      this.props.fetchTablesAndStatuses({
        date: events.segments[this.params.event] && events.segments[this.params.event].date,
        club: this.clubId,
      })
    }

    if (!prevProps.clubs.readSuccess && clubs.readSuccess) {
      const club = clubs.segments[this.clubId]
      if (club.booked_before && this.state.date.isBefore(moment().add(club.booked_before, "d"), "date")) {
        this.setState(
          {
            ...this.state,
            date: moment().add(club.booked_before, "d"),
            passEventBookingWindow: !!this.params.event,
          },
          () => {
            const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
            query.date = moment(this.state.date).format("YYYY-MM-DD")
            query.event = undefined
            const queryString = qs.stringify(query, {
              addQueryPrefix: true,
            })
            this.props.history.push(`${this.props.location.pathname}${queryString}`)
          }
        )
      }
    }
  }
  onPromoCodeChange = (value: string) => {
    this.setState({
      ...this.state,
      promoCode: value,
      promotion: null,
      codeApplied: false,
      errorLog: null,
    })
  }

  applyPromotion = () => {
    this.setState({
      ...this.state,
      promotion: null,
      validatingCode: true,
    })
    axios
      .get("/promotions/validate", {
        params: {
          name: this.state.promoCode,
          club_id: this.clubId,
        },
      })
      .then((response) => {
        if (response.data.success) {
          const promotion = response.data.data[0]
          this.setState({
            ...this.state,
            promotion,
            codeApplied: true,
            errorLog: null,
            validatingCode: false,
          })
        }
      })
      .catch((e) => {
        this.setState({
          ...this.state,
          errorLog: "This Promo Code is not valid or has expired",
          validatingCode: false,
        })
      })
  }

  getTotalSpent = (minSpend: number) => {
    if (minSpend !== 0) {
      let totalSpent = minSpend
      if (this.state.promotion) {
        totalSpent =
          this.state.promotion.promotion_type === "flat_discount"
            ? totalSpent - this.state.promotion.amount
            : totalSpent - totalSpent * (this.state.promotion.amount / 100)
      }
      return Math.round(totalSpent)
    } else {
      return minSpend
    }
  }

  handleDateChange = (date: Date) => {
    this.setState({ date }, () => {
      const query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
      query.date = moment(this.state.date).format("YYYY-MM-DD")
      query.event = undefined
      const queryString = qs.stringify(query, {
        addQueryPrefix: true,
      })
      this.props.history.push(`${this.props.location.pathname}${queryString}`)
    })
  }

  setActive(id: ?string) {
    this.setState({ active: id })
  }

  rememberAndRedirect() {
    const locationToRemember = `${this.props.location.pathname}${this.props.location.search}`
    this.props.setLocation(locationToRemember)
    this.props.history.push("/confirmation")
  }

  onNoticeChange = (value: string) => this.setState({ notice: value })

  render() {
    const { clubs, events, tables } = this.props
    const { active, passEventBookingWindow, date } = this.state

    const club = clubs.segments[this.clubId]

    const eventDate = events.segments[this.params.event] && events.segments[this.params.event].date
    const eventName = events.segments[this.params.event] && events.segments[this.params.event].name
    const activeItem = this.state.active && tables.segments[this.state.active]
    const activeOverridePrice = ((activeItem && activeItem.override_prices) || []).find(
      (price) => moment(price.date).format("DD-MM-YYYY") === moment(this.state.date).format("DD-MM-YYYY")
    )
    const selectedClub =
      active &&
      tables.segments[active] &&
      tables.segments[active].club_id &&
      clubs.segments[tables.segments[active].club_id]
    const tableCardProps = {
      onNoticeChange: this.onNoticeChange,
      notice: this.state.notice,
      active: active !== null,
      name: activeItem && activeItem.name,
      date: passEventBookingWindow
        ? moment(this.state.date) || moment()
        : eventDate || moment(this.state.date) || moment(),
      open: selectedClub && selectedClub.open,
      closed: selectedClub && selectedClub.closed,
      capacity: activeItem && activeItem.capacity,
      minSpend:
        activeItem &&
        (passEventBookingWindow
          ? activeOverridePrice
            ? this.getTotalSpent(activeOverridePrice.minimum_spend)
            : this.getTotalSpent(activeItem.minimum_spend)
          : (events.segments[this.params.event] &&
              events.segments[this.params.event].override_prices &&
              events.segments[this.params.event].override_prices[activeItem && activeItem._id] &&
              this.getTotalSpent(
                events.segments[this.params.event].override_prices[activeItem && activeItem._id].price
              )) ||
            (activeOverridePrice
              ? this.getTotalSpent(activeOverridePrice.minimum_spend)
              : this.getTotalSpent(activeItem.minimum_spend))),
      additionalInfo: {
        title: activeItem && activeItem.arrival_time,
        content: "The latest time guests must arrive",
      },
      hasPayButton: club && club.payment_enabled,
      hasBookingButton: club && club.booking_enabled,
      onPromoCodeChange: this.onPromoCodeChange,
      applyPromotion: this.applyPromotion,
      promoCode: this.state.promoCode,
      codeApplied: this.state.codeApplied,
      validatingCode: this.state.validatingCode,
      promotion: this.state.promotion,
      errorLog: this.state.errorLog,
      onClick: () =>
        this.props.user.isLoggedIn
          ? this.props.bookTableAndRedirect({
              name: activeItem && activeItem.name,
              date: passEventBookingWindow ? date : eventDate || date,
              table_id: active,
              transaction_status: "invoiced",
              contact: this.props.user.phone || this.props.user.email,
              comments: this.state.notice,
              promotion: this.state.promotion ? this.state.promotion._id : null,
              minSpend:
                activeItem &&
                (passEventBookingWindow
                  ? activeOverridePrice
                    ? this.getTotalSpent(activeOverridePrice.minimum_spend)
                    : this.getTotalSpent(activeItem.minimum_spend)
                  : (events.segments[this.params.event] &&
                      events.segments[this.params.event].override_prices &&
                      events.segments[this.params.event].override_prices[activeItem && activeItem._id] &&
                      this.getTotalSpent(
                        events.segments[this.params.event].override_prices[activeItem && activeItem._id].price
                      )) ||
                    (activeOverridePrice
                      ? this.getTotalSpent(activeOverridePrice.minimum_spend)
                      : this.getTotalSpent(activeItem.minimum_spend))),
            })
          : this.rememberAndRedirect(),
      checkShouldProccessedWithCheckout: () => {
        if (this.props.user.isLoggedIn) {
          return true
        }
        this.rememberAndRedirect()
        return false
      },
      onTokenReceived: (token: string, qty: number, totalSpent: number, promotion: string) => {
        const bookingDetails = {
          name: activeItem && activeItem.name,
          date: passEventBookingWindow ? date : eventDate || date,
          table_id: active,
          transaction_status: "invoiced",
          contact: this.props.user.phone || this.props.user.email,
          comments: this.state.notice,
          promotion,
          minSpend: totalSpent,
        }

        this.props.bookTableAndPay(bookingDetails, token, false)
      },
      onSourceReceived: (source: string, qty: number, totalSpent: number, promotion: string) => {
        const bookingDetails = {
          name: activeItem && activeItem.name,
          date: passEventBookingWindow ? date : eventDate || date,
          table_id: active,
          transaction_status: "invoiced",
          contact: this.props.user.phone || this.props.user.email,
          comments: this.state.notice,
          promotion,
          minSpend: totalSpent,
        }

        this.props.bookTableAndPay(bookingDetails, source, true)
      },
    }
    return (
      <Container className={`${styles.masterContainer} fadeIn`}>
        <Back
          to={
            events.segments[this.params.event] && events.segments[this.params.event]._id
              ? `/event/${events.segments[this.params.event]._id}`
              : `/club/${this.clubId}`
          }
        />
        <Divider hidden />
        <TitleAndSubtitle
          size={"medium"}
          title={clubs.segments[this.clubId] && clubs.segments[this.clubId].name}
          subtitle={
            this.state.passEventBookingWindow ? "Event not avaliable for booking" : eventName || "No event"
          }
        />
        <Divider hidden style={{ marginBottom: 0 }} />

        {/* {this.props.location.search.includes("event") ? (
          <Link to={"/events"}>
            <Button simple>{moment(eventDate).format("MMM DD, dddd")}</Button>
          </Link>
        ) : (
          ... datepicker
        )} */}

        <SingleDatePicker
          selectionFrom
          bookFor
          bookedBefore={clubs.segments[this.clubId] && clubs.segments[this.clubId].booked_before}
          value={this.state.date}
          onChange={(date) => this.handleDateChange(date)}
        />
        {this.state.passEventBookingWindow && (
          <div className={styles.noEventBooking}>
            The booking window for event {eventName} - {moment(eventDate).format("DD MMM")} has already passed.
          </div>
        )}
        {clubs.segments[this.clubId] && clubs.segments[this.clubId].client_note && (
          <Grid stackable columns={"equal"}>
            <Grid.Column width={11}>
              <section className={styles.contactSection}>
                <p
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(clubs.segments[this.clubId].client_note) }}
                />
              </section>
            </Grid.Column>
            <Responsive as={Grid.Column} minWidth={768} />
          </Grid>
        )}

        <Grid stackable columns={"equal"} style={{ minHeight: 400 }}>
          <Grid.Column width={11}>
            {tables.readSuccess ? (
              Object.keys(tables.segments).length ? (
                Object.keys(tables.segments).map((key, i) => {
                  const {
                    _id: id,
                    name,
                    capacity,
                    placement,
                    minimum_spend: minSpend,
                    override_prices,
                  } = tables.segments[key]
                  const dateOverridePrice = (override_prices || []).find(
                    (price) =>
                      moment(price.date).format("DD-MM-YYYY") === moment(this.state.date).format("DD-MM-YYYY")
                  )
                  const priceToShow = passEventBookingWindow
                    ? dateOverridePrice
                      ? dateOverridePrice.minimum_spend
                      : minSpend
                    : (events.segments[this.params.event] &&
                        events.segments[this.params.event].override_prices &&
                        events.segments[this.params.event].override_prices[id] &&
                        this.getTotalSpent(events.segments[this.params.event].override_prices[id].price)) ||
                      (dateOverridePrice ? dateOverridePrice.minimum_spend : minSpend)

                  return (
                    <TableItem
                      booked={
                        tables.bookingStatusesSuccess &&
                        tables.bookingStatuses[id] &&
                        tables.bookingStatuses[id].booking
                      }
                      key={i}
                      active={this.state.active === id}
                      name={name}
                      maxGuests={capacity}
                      minSpend={priceToShow}
                      placement={placement}
                      onClick={() => this.setActive(id)}
                    />
                  )
                })
              ) : (
                <div style={{ height: 300, textAlign: "center" }}>
                  <div className="vertical-align">No available tables</div>
                </div>
              )
            ) : tables.readLoading ? (
              <Loader active />
            ) : (
              <div style={{ height: 300, textAlign: "center" }}>
                <div className="vertical-align">No available tables</div>
              </div>
            )}
          </Grid.Column>
          <Responsive as={Grid.Column} minWidth={768}>
            <TableCard {...tableCardProps} />
          </Responsive>
        </Grid>
        <Responsive maxWidth={768}>
          {this.state.active !== null && (
            <div className={styles.tableCardContainer}>
              <div className={styles.tableCardDimmer} onClick={() => this.setActive(null)} />
              <div className={styles.tableCardContent}>
                <TableCard {...tableCardProps} />
              </div>
            </div>
          )}
        </Responsive>
      </Container>
    )
  }
}

const mstp = ({ tables, clubs, events, user }) => ({
  tables,
  clubs,
  events,
  user,
})

const mdtp = (dispatch) => ({
  fetchTablesAndStatuses: bindActionCreators(fetchTablesAndStatuses, dispatch),
  bookTableAndRedirect: bindActionCreators(bookTableAndRedirect, dispatch),
  setLocation: bindActionCreators(setLocation, dispatch),
  fetchEvents: bindActionCreators(fetchEvents, dispatch),
  chargeFromCard: bindActionCreators(chargeFromCard, dispatch),
  bookTableAndPay: bindActionCreators(bookTableAndPay, dispatch),
})

export default connect(mstp, mdtp)(Tables)
