// @flow
import React from "react"
import { Link } from "react-router-dom"
import { Icon } from "semantic-ui-react"
import menuIcon from "assets/Icons/menu.svg"

import Logo from "components/Logo"
import HeaderUserMenu from "components/HeaderUserMenu"

import styles from "./styles.module.css"

type LinkType = {
  name?: string,
  text: string,
  uri: string,
  target?: string,
}

type HeaderPropTypes = {
  path: string,
  links: {
    def: Array<LinkType>,
    mobile: Array<LinkType>,
  },
}

type HeaderStateType = {
  scrollDirection: string,
  scrolledFarEnough: boolean,
  isMenuOpen: boolean,
}

type NavigationItemPropTypes = {
  ...LinkType,
  path: string,
}

class Header extends React.Component<HeaderPropTypes, HeaderStateType> {
  lastY: number

  lastY = 0
  state = {
    scrollDirection: "down",
    scrolledFarEnough: false,
    isMenuOpen: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this))
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this))
  }

  // TODO: OPTIMIZE THIS
  handleScroll(event: SyntheticEvent<*>) {
    const Y = window.pageYOffset
    const { lastY } = this
    if (Y > lastY && this.state.scrollDirection === "up") {
      this.setState({ scrollDirection: "down" })
    }
    if (Y < lastY && this.state.scrollDirection === "down") {
      this.setState({ scrollDirection: "up" })
    }
    if (Y >= 60) this.setState({ scrolledFarEnough: true })
    else this.setState({ scrolledFarEnough: false })
    this.lastY = Y
  }

  openMenu() {
    this.setState({ isMenuOpen: true })
  }

  closeMenu() {
    this.setState({ isMenuOpen: false })
  }

  render() {
    const { path, links } = this.props
    const { scrollDirection, scrolledFarEnough, isMenuOpen } = this.state
    return (
      <React.Fragment>
        <Logo
          className={"only-mobile-and-tablet"}
          style={{ position: "absolute", left: "-1.5em", top: "0.5em" }}
        />
        <MobileMenu links={links} isOpen={isMenuOpen} closeMenu={() => this.closeMenu()} path={path} />
        <header
          onClick={() => this.openMenu()}
          className={`${styles.mobileContainer} ${scrolledFarEnough ? styles.visibleContainer : ""}`}
        >
          <img src={menuIcon} alt={"Menu"} />
        </header>
        <header
          className={`${styles.masterContainer} ${
            scrolledFarEnough && scrollDirection === "down"
              ? styles.hidden
              : scrolledFarEnough
              ? styles.visibleContainer
              : ""
          }`}
        >
          <Logo />
          <div className={styles.navigationContainer}>
            {links.def.map(({ target, text, name, uri }, i) => (
              <NavigationItem key={i} path={path} text={text} name={name} uri={uri} target={target} />
            ))}
          </div>
          <HeaderUserMenu />
        </header>
      </React.Fragment>
    )
  }
}

const NavigationItem = ({ path, text, name, uri, target }: NavigationItemPropTypes) => {
  if (target) {
    return (
      <a href={uri} target={target}>
        <div className={`${styles.navigationLinkContainer} ${uri === path ? styles.activeLinkContainer : ""}`}>
          {text}
        </div>
      </a>
    )
  }
  return (
    <Link name={name} to={uri}>
      <div className={`${styles.navigationLinkContainer} ${uri === path ? styles.activeLinkContainer : ""}`}>
        {text}
      </div>
    </Link>
  )
}

const MobileNavigationItem = ({ path, text, name, uri, target }: NavigationItemPropTypes) => {
  if (target) {
    return (
      <a href={uri} target={target}>
        <div className={`${styles.mobileMenuItem} ${uri === path ? styles.mobileMenuItemActive : ""}`}>{text}</div>
      </a>
    )
  }
  return (
    <Link name={name} to={uri}>
      <div className={`${styles.mobileMenuItem} ${uri === path ? styles.mobileMenuItemActive : ""}`}>{text}</div>
    </Link>
  )
}

const MobileMenu = ({ path, closeMenu, isOpen, links }) => (
  <div className={`${styles.mobileMenu} ${isOpen ? styles.menuOpen : styles.menuClosed}`}>
    <Icon className={styles.closeIcon} size={"big"} name={"close"} onClick={closeMenu} />
    <div>
      {links.mobile.map(({ text, name, uri, target }, i) => (
        <MobileNavigationItem key={i} path={path} text={text} name={name} uri={uri} target={target} />
      ))}
    </div>
  </div>
)

export default Header
