// @flow
import moment from "moment"
import { CALL_API } from "middleware/api"
import objectifyArray from "utils/objectifyArray"

import {
  READ_REQUEST,
  READ_SUCCESS,
  READ_FAILURE,
  BOOKING_STATUSES_REQUEST,
  BOOKING_STATUSES_SUCCESS,
  BOOKING_STATUSES_FAILURE,
} from "./constants"
// import type { TableType } from "./types"
const isSuccessType = action => {
  return action && action.type && action.type.includes("SUCCESS")
}

export const fetchTables = ({ date, club }: { date: Date, club: string }) => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: httpClient =>
      httpClient({
        method: "get",
        url: "/tables",
        params: {
          date: moment(date).format("YYYY-MM-DD"),
          club,
        },
      }),
  },
})

export const fetchTableBookingStatuses = ({
  date,
  tables,
}: {
  date: Date,
  tables: string, // comma separated ids
}) => ({
  [CALL_API]: {
    types: [
      BOOKING_STATUSES_REQUEST,
      BOOKING_STATUSES_SUCCESS,
      BOOKING_STATUSES_FAILURE,
    ],
    promise: httpClient =>
      httpClient({
        method: "get",
        url: "/tables/booked",
        params: {
          tables,
          date: moment(date).format("YYYY-MM-DD"),
        },
      }),
    payloadModifier: payload => objectifyArray(payload, "table_id"),
  },
})

// Operations
export const fetchTablesAndStatuses = ({
  date,
  club,
}: {
  date: Date,
  club: string,
}) => async (dispatch: Function) => {
  const finalCreateType = await dispatch(fetchTables({ date, club }))
  if (isSuccessType(finalCreateType)) {
    const tables = Object.keys(finalCreateType.payload).join(",")
    if (tables.length) dispatch(fetchTableBookingStatuses({ date, tables }))
  }
}

// /tables/booked?tables=5bab87b77750fb08902a8b78,5bce2d7a21d8e100194e9ff8&date=2018-11-06
