export const commonReducerDefaultState = {
  createLoading: false,
  createSuccess: false,
  createError: false,
  readLoading: false,
  readSuccess: false,
  readError: false,
  updateLoading: false,
  updateSuccess: false,
  updateError: false,
  deleteLoading: false,
  deleteSuccess: false,
  deleteError: false,
  segments: {},
}

export const RESET = "RESET"

const commonReducer =
  (which, customReducer, customReducerState = {}) =>
  (
    state = {
      ...commonReducerDefaultState,
      ...customReducerState,
    },
    action
  ) => {
    const { type, payload } = action

    const RESET_DATA = `${which}_RESET_DATA`
    const CREATE_REQUEST = `${which}_CREATE_REQUEST`
    const CREATE_SUCCESS = `${which}_CREATE_SUCCESS`
    const CREATE_FAILURE = `${which}_CREATE_FAILURE`
    const READ_REQUEST = `${which}_READ_REQUEST`
    const READ_SUCCESS = `${which}_READ_SUCCESS`
    const READ_FAILURE = `${which}_READ_FAILURE`
    const UPDATE_REQUEST = `${which}_UPDATE_REQUEST`
    const UPDATE_SUCCESS = `${which}_UPDATE_SUCCESS`
    const UPDATE_FAILURE = `${which}_UPDATE_FAILURE`
    const DELETE_REQUEST = `${which}_DELETE_REQUEST`
    const DELETE_SUCCESS = `${which}_DELETE_SUCCESS`
    const DELETE_FAILURE = `${which}_DELETE_FAILURE`

    switch (type) {
      // CREATE
      case RESET:
        return {
          ...state,
          ...commonReducerDefaultState,
        }
      case RESET_DATA:
        return {
          ...state,
          ...commonReducerDefaultState,
        }
      case CREATE_REQUEST:
        return {
          ...state,
          createLoading: true,
          createSuccess: false,
          createError: false,
        }
      case CREATE_SUCCESS:
        return {
          ...state,
          createLoading: false,
          createSuccess: true,
          createError: false,
        }
      case CREATE_FAILURE:
        return {
          ...state,
          createLoading: false,
          createSuccess: false,
          createError: true,
        }
      // READ
      case READ_REQUEST:
        return {
          ...state,
          readLoading: true,
          readSuccess: false,
          readError: false,
        }
      case READ_SUCCESS:
        return {
          ...state,
          readLoading: false,
          readSuccess: true,
          segments: payload,
        }
      case READ_FAILURE:
        return {
          ...state,
          readLoading: false,
          readSuccess: false,
          readError: true,
        }
      // UPDATE
      case UPDATE_REQUEST:
        return {
          ...state,
          updateLoading: true,
          updateSuccess: false,
          updateError: false,
        }
      case UPDATE_SUCCESS:
        return {
          ...state,
          updateLoading: false,
          updateSuccess: true,
          updateError: false,
        }
      case UPDATE_FAILURE:
        return {
          ...state,
          updateLoading: false,
          updateSuccess: false,
          updateError: true,
        }
      // DELETE
      case DELETE_REQUEST:
        return {
          ...state,
          deleteLoading: true,
          deleteSuccess: false,
          deleteError: false,
        }
      case DELETE_SUCCESS:
        return {
          ...state,
          deleteLoading: false,
          deleteSuccess: true,
          deleteError: false,
        }
      case DELETE_FAILURE:
        return {
          ...state,
          deleteLoading: false,
          deleteSuccess: false,
          deleteError: true,
        }
      default: {
        if (customReducer) return customReducer({ ...customReducerState, ...state }, action)
        return state
      }
    }
  }

export default commonReducer
