// @flow
import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Grid, Checkbox, Responsive } from "semantic-ui-react"
import { Link } from "react-router-dom"
import LazyLoad from "react-lazyload"
import ReCAPTCHA from "react-google-recaptcha"

import MobileAppAnimation from "components/MobileAppAnimation"
import withPageBackground from "wrappers/PageBackground"
import Button from "components/Button"
import Card from "components/Card"
import AccessControl from "components/AccessControl"
import FeatureIcon from "components/FeatureIcon"
import { AndroidButton, AppleButton } from "components/AppButtons"
import { unsetLocation } from "data/Navigation/actions"
import { instance as axios } from "instances/Axios"
import { _oldPushNotification as pushNotification } from "wrappers/Notifications/actions"

import cover from "assets/Images/newbackgroundmain6.webp"
import bangkok from "assets/Images/bangkok.webp"
import singapore from "assets/Images/singapore.webp"
import hongkong from "assets/Images/hongkong.webp"
import search from "assets/Icons/search.svg"
import check from "assets/Icons/check.svg"
import star from "assets/Icons/star-outline.svg"

import styles from "./styles.module.css"

type PropTypes = {
  history: {
    push: Function,
  },
  unsetLocation: Function,
  navigation: {
    location: string,
  },
}

class Home extends React.Component<PropTypes> {
  constructor(props) {
    super(props)
    this.captchaRef = React.createRef(null)
  }

  state = {
    email: "",
    policyCheckbox: false,
    subscribeCheckbox: false,
  }

  handleNewsletterInputChange(value) {
    this.setState({ email: value })
  }

  handleCheckboxChange(which) {
    this.setState((state) => {
      const newState = { ...state }
      newState[which] = !newState[which]
      return newState
    })
  }

  componentDidMount() {
    if (this.props.navigation.location) {
      this.props.history.push(this.props.navigation.location)
      this.props.unsetLocation()
    }
  }

  async handleNewsletterFormSubmit(e) {
    e.preventDefault()
    try {
      const token = this.captchaRef.current.getValue()
      const response = await axios.post("/checks", {
        token: token,
      })
      this.captchaRef.current.reset()
      if (response.data.data.success) {
        await axios.post("/subscriptions", {
          email: this.state.email,
          acceptsPrivacyPolicy: this.state.policyCheckbox,
          acceptsSubscription: this.state.subscribeCheckbox,
        })
        this.props.pushNotification({ content: "Subscribed!" })
      } else {
        this.props.pushNotification({ content: "Failed to subscribe!" })
      }
    } catch (e) {
      console.log(e)
      this.props.pushNotification({ content: "Failed to subscribe" })
    }
  }

  render() {
    const coverSection = (
      <section className={styles.coverSection}>
        <Grid stackable columns="equal">
          <Grid.Column width={12}>
            <div className={styles.coverContentContainer}>
              <h1>TABLE - Lifestyle &amp; Beyond</h1>
              <p>
                Your go-to platform for table bookings, events, exclusive deals &amp; experiences and concierge
                services
              </p>

              <Responsive minWidth={768}>
                <div className={styles.coverButtons}>
                  <Link to="/venues">
                    <Button shadowed className={styles.coverButton}>
                      Explore
                    </Button>
                  </Link>
                  <AccessControl
                    renderUnauthorized={
                      <Link to="/register">
                        <Button shadowed secondary className={styles.coverButton}>
                          Sign up
                        </Button>
                      </Link>
                    }
                  />
                </div>
              </Responsive>
            </div>
          </Grid.Column>
          <Grid.Column className={styles.videoSection}>
            <Responsive maxWidth={767}>
              <div className={styles.coverButtons}>
                <Link to="/venues">
                  <Button shadowed className={styles.coverButton}>
                    Book now
                  </Button>
                </Link>
                <AccessControl
                  renderUnauthorized={
                    <Link to="/register">
                      <Button shadowed secondary className={styles.coverButton}>
                        Sign up
                      </Button>
                    </Link>
                  }
                />
              </div>
            </Responsive>
          </Grid.Column>
        </Grid>
      </section>
    )

    const featuresSection = (
      <section className={styles.featuresSection}>
        <div className={styles.featuresContent}>
          <Grid stackable columns="equal">
            <Grid.Column>
              <FeatureIcon image={search} />
              <h3 className={styles.featureHeading}>Discovery</h3>
              <p className={styles.featureText}>
                Your inside guide to the best places, exclusive deals, fun events and more
              </p>
            </Grid.Column>
            <Grid.Column>
              <FeatureIcon image={check} />
              <h3 className={styles.featureHeading}>Convenience</h3>
              <p className={styles.featureText}>
                Fast and secure booking and payment via Alipay, Debit/Credit Card &apm; Internet Banking
              </p>
            </Grid.Column>
            <Grid.Column>
              <FeatureIcon image={star} />
              <h3 className={styles.featureHeading}>Exclusivity</h3>
              <p className={styles.featureText}>
                Enjoy exclusive promotions and time-based value for money deals from our partners
              </p>
            </Grid.Column>
          </Grid>
        </div>
      </section>
    )

    const appSection = (
      <section className={styles.appSection}>
        <Grid stackable columns="equal">
          <Grid.Column width={7} className={styles.leftSlice}>
            <LazyLoad height="100vh">
              <div className={styles.appSectionImage}>
                <MobileAppAnimation />
              </div>
            </LazyLoad>
          </Grid.Column>
          <Grid.Column className={styles.rightSlice}>
            <div className={styles.appSectionContent}>
              <h2>EXPLORE, BOOK, EXPERIENCE</h2>
              <p className={styles.appDescription}>
                Easy access to all our offerings via our website and upcoming apps
              </p>
              <LazyLoad height="100vh">
                <div className={styles.appSectionImageMobile}>
                  <MobileAppAnimation />
                </div>
              </LazyLoad>
              <div className={styles.appButtonsContainer}>
                <AppleButton />
                <AndroidButton />
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </section>
    )

    const placesSection = (
      <section className={styles.placesSection}>
        <h2 className={styles.sectionHeading}>Where you can find us</h2>
        <Grid stackable columns="equal">
          <Grid.Column>
            <Link to="/venues?city=bangkok">
              <Card
                title={"Bangkok"}
                subtitle={"Explore the city that never sleeps!"}
                buttonText={"Find out more"}
                image={bangkok}
              />
            </Link>
          </Grid.Column>
          <Grid.Column>
            <Card
              title={"Hong Kong"}
              subtitle={"Coming soon!"}
              buttonText={"Find out more"}
              image={hongkong}
              disabled
            />
          </Grid.Column>
          <Grid.Column>
            <Card
              title={"Singapore"}
              subtitle={"Coming soon!"}
              buttonText={"Find out more"}
              image={singapore}
              disabled
            />
          </Grid.Column>
        </Grid>
      </section>
    )

    const contactSection = (
      <section className={styles.contactSection}>
        <h2>Leave us your email to stay up-to-date with TABLE's offers &amp; news</h2>
        <form onSubmit={(e) => this.handleNewsletterFormSubmit(e)}>
          <input
            placeholder="Leave us your email here to stay up-to-date"
            className={styles.emailInput}
            onChange={({ target: { value } }) => this.handleNewsletterInputChange(value)}
            value={this.state.email}
          />
          <Button secondary>Join us now</Button>
          <div className={styles.checkboxContainer}>
            <Checkbox
              onChange={() => this.handleCheckboxChange("policyCheckbox")}
              className={styles.checkbox}
              label={"I agree to TABLE's privacy policy, terms of use/terms and conditions"}
            />
            <br />
            <Checkbox
              onChange={() => this.handleCheckboxChange("subscribeCheckbox")}
              className={styles.checkbox}
              label={"I would like to subscribe to updates from TABLE"}
            />
            <div className={styles.captchaContainer}>
              <ReCAPTCHA
                sitekey={window._env_ && window._env_.CAPTCHA_APP_SITE_KEY}
                ref={this.captchaRef}
                theme={"dark"}
              />
            </div>
          </div>
        </form>
      </section>
    )

    return (
      <div className={`${styles.container} fadeIn`}>
        {coverSection}
        {featuresSection}
        <div className={styles.additionalBackground}>
          {appSection}
          {placesSection}
        </div>
        {contactSection}
      </div>
    )
  }
}

const mstp = ({ navigation }) => ({ navigation })

const mdtp = (dispatch) => ({
  unsetLocation: bindActionCreators(unsetLocation, dispatch),
  pushNotification: bindActionCreators(pushNotification, dispatch),
})

export default connect(mstp, mdtp)(withPageBackground({ image: cover })(Home))
