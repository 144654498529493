// @flow
import * as React from "react"

type PropTypes = {
  Header: React.ComponentType<any>,
  Footer: React.ComponentType<any>,
}

const withLayout =
  ({ Header, Footer }: PropTypes, removeFooter = false) =>
  (Component: React.ComponentType<any>) =>
    class Layout extends React.Component<{}> {
      render() {
        return (
          <div>
            <Header {...this.props} />
            <Component {...this.props} />
            {!removeFooter && <Footer {...this.props} />}
          </div>
        )
      }
    }

export default withLayout
