// @flow
import React from "react"

import styles from "./styles.module.css"

const FeatureIcon = ({ image, alt }) => (
  <div className={styles.container}>
    <img src={image} alt={alt || "Feature icon image"} />
  </div>
)

export default FeatureIcon
