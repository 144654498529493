// @flow
import moment from "moment"

import { CALL_API } from "middleware/api"

import { READ_REQUEST, READ_SUCCESS, READ_FAILURE, RESET_DATA } from "./constants"
import objectifyArray from "utils/objectifyArray"

export const fetchEvent = ({ from, to, event_id }: { from: Date, to: Date, event_id: string }) => ({
  [CALL_API]: {
    types: [READ_REQUEST, READ_SUCCESS, READ_FAILURE],
    promise: (httpClient) =>
      httpClient({
        url: "/events/getOne",
        method: "get",
        params: {
          event_id,
          date_from: (from && moment(from).format("YYYY-MM-DD")) || moment().format("YYYY-MM-DD"),
          date_to: (to && moment(to).format("YYYY-MM-DD")) || moment().add(1, "y").format("YYYY-MM-DD"),
        },
      }),
    payloadModifier: (payload) => {
      return payload.map((e) => {
        e.override_prices = objectifyArray(e.override_prices, "table_id")
        return e
      })
    },
  },
})

export function resetEvent() {
  return { type: RESET_DATA }
}
