// @flow
import * as React from "react"
import { Router } from "react-router-dom"
import ReactPixel from "react-facebook-pixel"

import ScrollToTop from "components/ScrollToTop"
import history from "instances/History"

history.listen((location) => {
  console.log("---------- page change", location.pathname)
  ReactPixel.pageView()
})

export default function withRouter(Component: React.ComponentType<any>) {
  return function TheRouter(props: {}) {
    return (
      <Router history={history}>
        <ScrollToTop>
          <Component {...props} />
        </ScrollToTop>
      </Router>
    )
  }
}
