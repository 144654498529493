import React from "react"

const Content = () => (
  <div>
    <h1>Terms of use</h1>

    <h3>1 These terms</h3>
    <ul>
      <li>
        1.1 These terms of use govern your use and access to our services, including our website(s), our
        application(s), our application programming interfaces (APIs), our notifications and any information or
        content appearing therein (collectively our &ldquo;Platform&rdquo;).
      </li>
      <li>
        1.2 By using our Platform, you agree to these terms regardless of whether you are paying user or a
        non-paying visitor. If you are using our Platform as a representative of an entity, you are agreeing to
        these terms on behalf of that entity.
      </li>
      <li>
        1.3 You should also read our Privacy Policy which sets out how we collect and use your personal
        information.
      </li>
    </ul>
    <h3>2 About us and how to contact us</h3>
    <p>
      2.1 We are 49CAP PTE. LTD., a company registered in Singapore, trading as Table. Our Unique Identity Number
      is 201726847G and our registered office is at 51 GOLDHILL PLAZA, #07-10/11, SINGAPORE 308900. Our Thai
      company Table (Thailand) Co., Ltd. with registration number 0105562076526 has its registered head office at
      TABLE (Thailand) Co., Ltd., Woof Pack Building 1, 3-9 Sala Daeng 1 Alley, Silom, Bang Rak, Bangkok 10500.
    </p>
    <ul>
      <li>
        2.2 For any questions or problems relating to our Platform, our products or services, or these terms, you
        can contact us by emailing us at customerservice@tablebooking.co or writing to us at 49CAP PTE. LTD., 51
        GOLDHILL PLAZA, #07-10/11, SINGAPORE 308900 or TABLE (Thailand) Co., Ltd Woof Pack Building 1, 3-9 Sala
        Daeng 1 Alley, Silom, Bang Rak, Bangkok 10500.
      </li>
      <li>
        2.3 We are the data controller in relation to our Platform and are responsible for your personal data.
        Please refer to our Privacy Policy which is available at https://tablebooking.co/privacy-policy for
        information about what personal data we collect and what we do with it. It is important that you read our
        privacy policy, which may be updated from time to time, and understand how we use your information and the
        rights that you have about your information.
      </li>
      <li>
        2.4 If we have to contact you, we will do so by telephone or by writing to you at the email address or
        postal address you provided to us.
      </li>
      <li>
        2.5 When we use the words &ldquo;writing&rdquo; or &ldquo;written&rdquo; in these terms, this includes
        emails.
      </li>
    </ul>
    <h3>3 Changes of terms</h3>
    <ul>
      <li>
        3.1 We may amend these terms from time to time by posting the updated terms on our Platform. If we make
        material changes, we will notify you of the changes before they become effective. By continuing to use our
        Platform and our services after the changes come into effect means that you agree to be bound by the
        revised policy.
      </li>
    </ul>
    <h3>4 Availability of our services</h3>
    <ul>
      <li>
        4.1 We are constantly changing and improving our Platform and the products or services we provide. We may
        from time to time change or discontinue any of the products or services we offer, or add or remove
        functionalities or features, and we may suspend or stop certain products, services, functionalities or
        features altogether. If we discontinue certain products, services, functionalities or features, we will
        give you advance notice where reasonably possible.
      </li>
      <li>
        4.2 We may release products, services, functionalities or features that we are still testing and
        evaluating. We will label such services as &ldquo;beta&rdquo;, &ldquo;preview&rdquo;, &ldquo;early
        access&rdquo; or &ldquo;trial&rdquo; or any words or phrases with similar meanings. You understand that
        these beta services are not as reliable as other products or services we offer.
      </li>
      <li>
        4.3 We reserve the right to limit your use of our Platform and the services we provide, including the right
        to restrict, suspend or terminate your account if we believe you are in breach of these terms or are
        misusing our Platform or any services we provide.
      </li>
      <li>
        4.4 We try our best to ensure that our Platform is always available, but we do not guarantee that the
        operation of or access to our Platform will be uninterrupted or continuous. Our Platform may be interrupted
        for maintenance, repairs, upgrades, network or equipment failures.
      </li>
      <li>
        4.5 You are responsible for configuring your information technology, computer programs and platform or
        system in order to access our Platform. We do not guarantee that our Platform will be free from bugs or
        viruses.
      </li>
    </ul>
    <h3>5 Your account and password</h3>
    <ul>
      <li>
        5.1 In registering for an account on our Platform, you must provide truthful, accurate and up-to-date
        information about yourself. You should choose a strong and secure password. You must keep your password
        secure and confidential.
      </li>
      <li>
        5.2 You agree not to share your account credentials or give others access to your account. If and when we
        detect that an account is shared by multiple users, we may treat this as a security breach and suspend or
        terminate your account.
      </li>
      <li>
        5.3 We have the right to disable any password, whether chosen by you or allocated by us, at any time, if in
        our reasonable opinion you are in breach of these terms.
      </li>
      <li>5.4 You agree to follow our Acceptable Use Policy, which is set out at the end of these terms.</li>
      <li>
        5.5 You are responsible for all actions or activities that happens by, through or under your account,
        unless you report misuse.
      </li>
    </ul>
    <h3>6 Use of the platform</h3>
    <ul>
      <li>
        6.1 You must comply with the Acceptable Use Policy and all applicable laws and regulatory requirements,
        including privacy laws and intellectual property laws in using or accessing the Platform.
      </li>
      <li>
        6.2 We give you a personal, worldwide, royalty-free, non-assignable, non-transferrable, non-sublicensable,
        non-exclusive and revocable license to access and use our Platform, including any software or application
        as part of the services we offer. This license is for the sole purpose of enabling you to use and enjoy the
        benefit of our Platform as provided by us and in the manner as permitted by these terms.
      </li>
      <li>
        6.3 This license to use our Platform will terminate if you do not comply with these terms or other
        additional terms or conditions imposed by us from time to time.
      </li>
      <li>
        6.4 You must not copy, modify, distribute, sell, lease, loan or trade any access to the Platform or any
        data or information on it.
      </li>
    </ul>
    <h3>7 Your rights</h3>
    <ul>
      <li>
        7.1 You retain your rights to any information or content you submit, post or display on or through the
        Platform (&ldquo;Your Content&rdquo;). By submitting, posting or displaying such content, you grant us a
        worldwide, non-exclusive, royalty-free licence (with the right to sublicense) to use, process, copy,
        reproduce, adapt, modify, publish, transmit, display and distribute such content in any and all media or
        through any distribution channels (now known or later developed), subject to the applicable provisions in
        our Privacy Policy.
      </li>
      <li>
        7.2 You are responsible for your use of Your Content and any consequences thereof, including any
        consequences of the use of Your Content by other users or third parties. We are not responsible or liable
        for any use of Your Content, nor the use of any content or information submitted or posted by other users
        or visitors.
      </li>
      <li>
        7.3 You warrant that Your Content is not and will not infringe rights of any third parties and that you
        have all the necessary rights, power and authority to satisfy your obligations with regard to Your Content
        under these terms.
      </li>
      <li>
        7.4 If you believe your intellectual property rights have been infringed, please contact us by email at
        customerservice@tablebooking.co. co
      </li>
    </ul>
    <h3>8 Our rights</h3>
    <ul>
      <li>
        8.1 All intellectual property rights subsisting in the Platform or the products or services we provide
        belong to us or have been lawfully licensed to us. All rights under applicable laws are hereby reserved.
        You must not upload, post, publish, reproduce, transmit or distribute any content or component of our
        Platform in any way, or create any derivative works with respect to any such content or component.
      </li>
      <li>
        8.2 We may (at our discretion but are not obliged to) review content or information submitted or posted by
        users on our Platform. We reserve the right to remove any content which we consider as offensive, harmful,
        deceptive, discriminative, defamatory or otherwise inappropriate or misleading, or content that we believe
        may be infringing rights of third parties. We do not endorse or support any views expressed by any users on
        our Platform.
      </li>
      <li>
        8.3 Our name &ldquo;Table&rdquo; and our marks and logos are our trade marks (be it registered or
        unregistered) and may not be used without our express prior written consent.
      </li>
    </ul>
    <h3>9 Integrations</h3>
    <ul>
      <li>
        9.1 We integrate with third-party software to provide a full suite of functionalities to our users. We are
        not responsible for any issues or loss arising from the use of any third-party software. Your access and
        use of the third-party software is governed by the terms of service or user agreements of that software.
      </li>
    </ul>
    <h3>10 Feedback</h3>
    <ul>
      <li>
        10.1 We value and welcome feedback on our Platform. You agree that we are free to use, disclose, adopt
        and/or modify any feedback and any information (including any ideas, concepts, proposals, suggestions or
        comments) provided by you to use in connection with our Platform or any products or services we offer,
        without any payment to you.
      </li>
      <li>
        10.2 You hereby waive and agree to waive any rights to claim for any fees, royalties, charges or other
        payments in relation to our use, disclosure, adoption and/or modification of any of your feedback.
      </li>
    </ul>
    <h3>11 Limitation on liabilities</h3>
    <ul>
      <li>
        11.1 Some countries or jurisdictions may not allow the disclaimers in this clause, in which case these
        disclaimers will not apply to you.
      </li>
      <li>
        11.2 To the fullest extent permitted by law, we (including our holding company(ies), subsidiaries,
        affiliates, directors, officers, employees, agents, representatives, partners and licensors (collectively,
        &ldquo;Our Entities&rdquo;)) expressly limit our liabilities in connection with or arising out of the
        provision of the Platform as follows:
      </li>
      <ul>
        <li>
          (a) we provide the Platform and any products or services we offer on an &ldquo;as is&rdquo; and &ldquo;as
          available&rdquo; basis, and your access to or use of our Platform is at your own risk;
        </li>
        <li>
          (b) we give no assurance, representation or warranty of any kind (whether express or implied) about the
          Platform and any products or services we provide;
        </li>
        <li>
          (c) we do not guarantee that the information or content you find on the Platform is always accurate,
          truthful, complete and up-to-date;
        </li>
        <li>
          (d) we expressly disclaim all warranties and representations (for example, warranties of merchantability,
          fitness for a particular purpose, and non-infringement);
        </li>
        <li>
          (e) we are not responsible for any delay or disruption in our Platform or any defect, viruses, bugs or
          errors; and
        </li>
        <li>
          (f) we are not responsible for the conduct of or any content or information submitted or posted by any
          user of the Platform (whether online or offline).
        </li>
      </ul>
      <li>11.3 To the fullest extent permitted by law, Our Entities are not liable to you or others for:</li>
      <ul>
        <li>(a) any indirect, incidental, special, exemplary, consequential or punitive damages; or</li>
        <li>(b) any loss of data, business, opportunities, reputation, profits or revenues,</li>
        <li>(c) relating to the use of our Platform or any products or services we offer.</li>
      </ul>
      <li>
        11.4 We do not exclude or limit our liability to you where it would be illegal to do so. This includes any
        of our liability for fraud or making fraudulent misrepresentation in operating the Platform or providing
        the products or services we offer.
      </li>
      <li>
        11.5 If you are using the Platform as a consumer, in some countries or jurisdictions you may have certain
        legal rights as a consumer. In such cases, nothing in these terms limit your legal rights as a consumer
        that may not be waived by contract.
      </li>
      <li>
        11.6 Other than the types of liabilities that we cannot limit by law, the liabilities of Our Entities to
        you (on aggregate) are limited to the amount you have paid us (if any) for the use of our Platform or for
        any products or services we offer over the last twelve (12) months.
      </li>
    </ul>
    <h3>12 Your representation</h3>
    <ul>
      <li>
        12.1 Our Platform is not intended for and may not be used by minors. By using our Platform, you represent
        that you are an adult and that you are able to legally enter into contractual agreements.
      </li>
      <li>
        12.2 If you are using the Platform on behalf of an entity, by using the Platform you represent that you
        have the necessary rights and authority to agree to these terms (and our Privacy Policy, Acceptable Use
        Policy and other documents referred to herein) on behalf of that entity.
      </li>
    </ul>
    <h3>13 Indemnity</h3>
    <ul>
      <li>
        13.1 You agree to indemnify and hold Our Entities harmless from and against all liabilities, damages,
        claims, costs (including legal fees and costs), and expenses in connection with or arising from (i) your
        breach of these terms, (ii) your use of our Platform and/or (iii) any misrepresentation made by you.
      </li>
      <li>
        13.2 You also agree to fully co-operate with us in the defence or settlement of any claim in relation to or
        arising out of our Platform or these terms.
      </li>
    </ul>
    <h3>14 Termination</h3>
    <ul>
      <li>14.1 These terms will continue to apply until terminated by either you or us as follows.</li>
      <li>14.2 You may stop using the Platform any time by deactivating your account.</li>
      <li>
        14.3 We reserve the right to suspend or terminate your access to our Platform, if we reasonably believe:
      </li>
      <ul>
        <li>
          (a) you are in serious or repeated breach of these terms (including a prolonged failure to settle any
          payment);
        </li>
        <li>
          (b) you are using the Platform in a manner that would cause a real risk of harm or loss to us, other
          users, or the public;
        </li>
        <li>
          (c) we are requested to do so by government or regulatory authorities or as required under applicable
          laws, regulations or legal processes; or
        </li>
        <li>(d) our provision of the Platform to you is no longer possible or commercially viable.</li>
        <li>
          (e) In any of the above cases, we will notify you by the email address associated with your account or at
          the next time you attempt to access your account, unless we are prohibited from notifying you by law.
        </li>
      </ul>
      <li>14.4 Upon termination of your access, these terms will also terminate except for Clauses 11 to 18.</li>
      <li>
        14.5 Where we consider necessary or appropriate, we will report any breach of these terms (or the
        Acceptable Use Policy) to law enforcement authorities and we will cooperate with such authorities by
        disclosing your identity and providing any information about you within our systems to them.
      </li>
    </ul>
    <h3>15 Entire agreement</h3>
    <ul>
      <li>
        15.1 These terms constitute the entire agreement between any user and us in relation to the use of or any
        transactions on the Platform. These terms supersede and extinguish all other agreements, promises,
        assurances, warranties, representations and understandings between any user and us, whether written or
        oral, in relation to the use of or any transactions on the Platform.
      </li>
      <li>
        15.2 You acknowledge that you will have no remedies in respect of any statement, representation, assurance
        or warranty (whether made innocently or negligently) that is not set out in these terms.
      </li>
    </ul>
    <h3>16 Other important terms</h3>
    <ul>
      <li>
        16.1 We may transfer our rights and obligations under these terms to another organisation. We will contact
        you to let you know if we plan to do this. If you do not wish to continue the contract with the transferee,
        you may contact us to end the contract within one (1) calendar month of us informing you of the proposed
        transfer and we will refund you any payments you have made in advance for any products not provided.
      </li>
      <li>
        16.2 You may only transfer your rights or your obligations under these terms to another person if we agree
        to this in writing.
      </li>
      <li>
        16.3 If a court finds part of this contract illegal, the rest will continue in force. Each of the
        paragraphs of these terms operates separately. If any court or relevant authority decides that any of them
        are unlawful, the remaining paragraphs will remain in full force and effect.
      </li>
      <li>
        16.4 Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist
        immediately that you do anything you are required to do under these terms, or if we delay in taking steps
        against you in respect of your breaking this contract, that will not mean that you do not have to do those
        things and it will not prevent us taking steps against you at a later date. For example, if you miss a
        payment and we do not chase you but we continue to provide the products, we can still require you to make
        the payment at a later date.
      </li>
    </ul>
    <h3>17 Contact</h3>
    <ul>
      <li>
        17.1 If you have any questions about these terms or the Acceptable Use Policy, please contact us by
        customerservice@tablebooking.co.
      </li>
    </ul>
    <h3>18 Governing law and dispute resolution</h3>
    <ul>
      <li>
        18.1 These terms are governed by and shall be construed in accordance with the laws of the Republic of
        Singapore and the Kingdom of Thailand, depending on where you use our services.
      </li>
      <li>
        18.2 Alternative dispute resolution is a process where an independent body considers the facts of a dispute
        and seeks to resolve it, without you having to go to court. If you are not happy with how we have handled
        any complaint, you may want to contact the alternative dispute resolution provider we use. You can submit a
        complaint to (if you have a complaint about or issue with our services) 49 CAP PTE. LTD., 51 GOLDHILL
        PLAZA, #07-10/11, SINGAPORE 308900 or TABLE (Thailand) Co., Ltd., Woof Pack Building 1, 3-9 Sala Daeng 1
        Alley, Silom, Bang Rak, Bangkok 10500 or write us an email to customerservice@tablebooking.co. We will take
        reasonable steps to work with you to attempt to resolve your complaint.&nbsp; www.tablebooking.co will not
        charge you for making a complaint and if you are not satisfied with the outcome you can still bring legal
        proceedings.
      </li>
    </ul>
    <h2>Acceptable Use Policy</h2>
    <ul>
      <li>
        18.3 As part of the terms of use, you agree not to misuse the Platform or help anyone else to do so. For
        example, you agree not to do any of the following in connection with the Platform:
      </li>
      <ul>
        <li>(a) use our Platform for unlawful or unauthorised purposes;</li>
        <li>
          (b) re-sell or attempt to benefit in a commercial fashion from any data, content or information available
          on the Platform;
        </li>
        <li>(c) probe, scan, or test the vulnerability of any system or network;</li>
        <li>(d) breach or otherwise circumvent any security or authentication measures or service use limits;</li>
        <li>(e) access, tamper with, or use non-public areas or parts of the Platform;</li>
        <li>
          (f) interfere with or disrupt any user, host, or network, for example by sending a virus, trojan, worm,
          logic bomb, or any other material that is malicious or technologically harmful, overloading, flooding,
          spamming, or mail-bombing any part of the Platform, or by scripting the creation of any content in such
          manner as to interfere with or create an undue burden on the Platform;
        </li>
        <li>
          (g) reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for
          the Platform or any related technology that is not open source;
        </li>
        <li>
          (h) access, search, or create accounts for the Platform by any means (automated or otherwise) other than
          our publicly supported interfaces (for example, &ldquo;scraping&rdquo; or creating accounts in bulk) or
          attempt to do so;
        </li>
        <li>(i) send unsolicited communications, promotions or advertisements, or spam;</li>
        <li>(j) forge any TCP/IP packet header or any part of the header information in any email;</li>
        <li>
          (k) send altered, deceptive, or false source-identifying information, including &ldquo;spoofing&rdquo; or
          &ldquo;phishing&rdquo;;
        </li>
        <li>
          (l) conduct surveys, contests, or pyramid schemes, or promote or advertise products or services without
          appropriate authorisation;
        </li>
        <li>(m) abuse referrals or promotions;</li>
        <li>
          (n) post, publish, upload, display, distribute, or share materials that are unlawful, inappropriate,
          profane, pornographic, obscene, indecent, libelous, defamatory, abusive, or knowingly false, and/or that
          infringe intellectual property rights;
        </li>
        <li>(o) violate the letter or spirit of our terms of use;</li>
        <li>(p) violate applicable laws or regulations in any way; or</li>
        <li>(q) violate the privacy or infringe the rights of others.</li>
      </ul>
    </ul>
    <p>
      <em>Last updated: 27 November 2019</em>
    </p>
    <h1>TERMS AND CONDITIONS FOR ONLINE SALE OF PRODUCTS</h1>
    <h3>1 These terms</h3>
    <ul>
      <li>
        1.1 These are the terms and conditions on which we supply reservation services (&ldquo;Products&rdquo;) to
        you through our website, software and applications provided by us (collectively, our
        &ldquo;Platform&rdquo;).
      </li>
      <li>
        1.2 Please read these terms carefully before you submit your order to us. These terms tell you who we are,
        how we will provide Products to you, how you and we may change or end the contract, what to do if there is
        a problem and other important information. If you think that there is a mistake in these terms or require
        any changes, please contact us to discuss.
      </li>
      <li>
        1.3 By using our Platform, you agree to these terms. If you are using our Platform as a representative of
        an entity, you are agreeing to these terms on behalf of that entity.
      </li>
    </ul>
    <h3>2 Information about us and how to contact us</h3>
    <ul>
      <li>
        2.1 We are 49CAP PTE. LTD., a company registered in Singapore, trading as Table. Our Unique Entity Number
        is 201726847G and our registered office is at 51 GOLDHILL PLAZA, #07-10/11, SINGAPORE 308900. Our Thai
        company Table (Thailand) Co., Ltd. with registration number 0105562076526 has its registered head office at
        TABLE (Thailand) Co., Ltd., Woof Pack Building 1, 3-9 Sala Daeng 1 Alley, Silom, Bang Rak, Bangkok 10500.
      </li>
      <li>
        2.2 For any questions or problems relating to our Platform, our Products or these terms, you can contact us
        by emailing us at customerservice@tablebooking.co or write to us at 51 GOLDHILL PLAZA, #07-10/11, SINGAPORE
        308900 or TABLE (Thailand) Co., Ltd., Woof Pack Building 1, 3-9 Sala Daeng 1 Alley, Silom, Bang Rak,
        Bangkok 10500.
      </li>
      <li>
        2.3 We are the data controller in relation to our Platform and are responsible for your personal data.
        Please refer our privacy policy which is available at&nbsp;
        <a href="https://tablebooking.co/privacy-policy" target="_blank" rel="noopener noreferrer">
          https://tablebooking.co/privacy-policy
        </a>
        &nbsp;for information about what personal data we collect and what we do with it. It is important that you
        read our privacy policy, which may be updated from time to time, and understand how we use your information
        and the rights that you have about your information.
      </li>
      <li>
        2.4 If we have to contact you, we will do so by telephone or by writing to you at the email address or
        postal address you provided to us.
      </li>
      <li>
        2.5 When we use the words &ldquo;writing&rdquo; or &ldquo;written&rdquo; in these terms, this includes
        emails.
      </li>
    </ul>
    <h3>3 Our contract with you</h3>
    <ul>
      <li>
        3.1 You may place orders with us as instructed on our Platform. Our acceptance of your order will take
        place when we email you to accept it, at which point a contract will come into existence between you and
        us.
      </li>
      <li>
        3.2 If we are unable to accept your order, we will let you know in writing and will not charge you for the
        Product ordered. This might be because:
      </li>
      <ul>
        <li>(a) the Product ordered is out of stock;</li>
        <li>(b) there are unexpected limits on our resources which we could not reasonably plan for;</li>
        <li>(c) we have identified an error in the price or description of the Product; or</li>
        <li>(d) we are unable to meet a delivery deadline you have specified.</li>
      </ul>
      <li>
        3.3 We will assign an order number to each order. Please tell us the order number whenever you contact us
        about your order.
      </li>
    </ul>
    <h3>4 Your rights to make changes</h3>
    <ul>
      <li>
        4.1 If you wish to make a change to the Product you have ordered, please contact us. We have full
        discretion whether or not to accept the changes. We will let you know about any changes to the price of the
        product, the timing of supply or anything else which would be necessary as a result of your requested
        change and ask you to confirm whether you wish to go ahead with the change. If we cannot make the change or
        the consequences of making the change are unacceptable to you, you may want to end the contract (see Clause
        7 &ndash; Your rights to end the contract).
      </li>
    </ul>
    <h3>5 Changes to our product or these terms</h3>
    <ul>
      <li>5.1 We may make minor changes to the Product from time to time in order to:</li>
      <ul>
        <li>(a) reflect changes in relevant laws and regulatory requirements; and</li>
        <li>
          (b) implement minor technical adjustments and improvements, for example to address a security threat.
          These changes will not affect your use of the product.
        </li>
      </ul>
      <li>
        5.2 If we make significant changes to these terms or the Product, we will notify you and you may then
        contact us to end the contract before the changes take effect and receive a refund for any Products paid
        for but not received.
      </li>
    </ul>
    <h3>6 Providing the products</h3>
    <ul>
      <li>
        6.1 We will provide the Products at such time or during such period as set out in our confirmation email.
      </li>
      <li>
        6.2 If our supply of the Products is delayed by an event outside our control, then we will contact you as
        soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do
        this, we will not be liable for delays caused by the event, but if there is a risk of substantial delay,
        you may contact us to end the contract and receive a refund for any products you have paid for but not
        received.
      </li>
      <li>
        6.3 If you do not allow us access to your property to perform the services as arranged (and you do not have
        a good reason for this), we may charge you additional costs incurred by us as a result. If, despite our
        reasonable efforts, we are unable to contact you or re-arrange access to your property we may end the
        contract and Clause 8.2 will apply.
      </li>
      <li>
        6.4 We may need certain information from you so that we can supply the Products to you. If so, this will
        have been stated in the description of the Products on our Platform. We will contact you to ask for this
        information. If you do not give us this information within a reasonable time of us asking for it, or if you
        give us incomplete or incorrect information, we may either end the contract (and Clause 8.2 will apply) or
        make an additional charge of a reasonable sum to compensate us for any extra work that is required as a
        result. We will not be responsible for supplying the Products late or not supplying any part of them if
        this is caused by you not giving us the information we need within a reasonable time of us asking for it.
      </li>
      <li>6.5 We may have to suspend the supply of a Product to:</li>
      <ul>
        <li>(a) deal with technical problems or make minor technical changes;</li>
        <li>(b) update the Product to reflect changes in relevant laws and regulatory requirements; or</li>
        <li>(c) make changes to the Product as requested by you or notified by us to you (see Clause 5).</li>
      </ul>
      <li>
        6.6 We will contact you in advance to tell you we will be suspending supply of the Product, unless the
        problem is urgent or an emergency. If we have to suspend the Product, we will adjust the price so that you
        do not pay for Products while they are suspended. You may contact us to end the contract for a Product if
        we suspend it, or tell you we are going to suspend it, in each case for a period of more than one (1) week
        and we will refund any sums you have paid in advance for the Product in respect of the period after you end
        the contract.
      </li>
      <li>
        6.7 If you do not pay us for the Products when you are supposed to (see Clause 10.4) and you still do not
        make payment within three (3) days of us reminding you that payment is due, we may suspend supply of the
        Products until you have paid us the outstanding amounts. We will contact you to tell you we are suspending
        supply of the Products. We will not suspend the Products where you dispute the unpaid invoice (see Clause
        10.5). We will not charge you for the Products during the period for which they are suspended.
      </li>
    </ul>
    <h3>7 Your rights to end the contract</h3>
    <ul>
      <li>
        7.1 You can always end your contract with us. Your rights when you end the contract will depend on what you
        have bought, whether there is anything wrong with it, how we are performing and when you decide to end the
        contract:
      </li>
      <ul>
        <li>
          (a) If what you have bought is faulty or misdescribed you may have a right to end the contract or to get
          the Product re-performed or to get some or all of your money back, see Clause 9;
        </li>
        <li>
          (b) If you want to end the contract because of something we have done or have told you we are going to
          do, see Clause 7.2;
        </li>
        <li>(c) In all other cases, see Clause 7.4 and Clause 7.5.</li>
      </ul>
      <li>
        7.2 If you are ending a contract for a reason set out below the contract will end immediately, we will
        refund you in full for any Products which have not been provided and you may also be entitled to
        compensation:]
      </li>
      <ul>
        <li>
          (a) we have told you about an upcoming change to the Product or these terms which you do not agree to
          (see Clause 5.2);
        </li>
        <li>
          (b) we have told you about an error in the price or description of the Product you have ordered and you
          do not wish to proceed;
        </li>
        <li>
          (c) there is a risk that supply of the Products may be significantly delayed because of events outside
          our control; or
        </li>
        <li>
          (d) we have suspended supply of the Products for technical reasons, or notify you we are going to suspend
          them for technical reasons, in each case for a period of more than one (1) week.
        </li>
      </ul>
      <li>
        7.3 Even if we are not at fault, you can still end the contract before it is completed (i.e. when we have
        finished providing the services and you have paid for them) by giving us notice in writing, provided that
        you will pay us reasonable compensation for the net costs we will incur as a result of your ending the
        contract. In such case, the contract will end immediately. We will refund any advance payment you have made
        for Products which will not be provided to you.
      </li>
      <li>
        7.4 To end the contract with us, please post a printed return form to us, or email our customer services at
        customerservice@tablebooking.co.
      </li>
      <li>
        7.5 If you end the contract with us, we will refund you the price you paid for the Products, but we may
        deduct from the refund the price for the part of services already provided (up to the time you inform us
        that you are ending the contract with us) in proportion to the full price of the services. More details can
        be found in section 10. &amp; 11.
      </li>
      <li>7.6 We will make any refunds due to you as soon as possible.</li>
    </ul>
    <h3>8 Our rights to end the contract</h3>
    <ul>
      <li>8.1 We may end the contract for a Product at any time by writing to you if:</li>
      <ul>
        <li>
          (a) you do not, within a reasonable time of us asking for it, provide us with information that is
          necessary for us to provide the Products, for example, information needed to perform our contract with
          the user such as, but not exclusively, information needed for identification, physical address (billing
          or delivery or both), email address, telephone number(s), information relevant to facilitate payment for
          services;
        </li>
        <li>
          (b) you do not, within a reasonable time, allow us access to your premises to supply the services; or
        </li>
        <li>
          (c) you do not make any payment to us when it is due and you still do not make payment within three (3)
          days of us reminding you that payment is due.
        </li>
      </ul>
      <li>
        8.2 If we end the contract in the situations set out in Clause 8.1 we will refund any money you have paid
        in advance for Products we have not provided but we may deduct or charge you reasonable compensation for
        the net costs we will incur as a result of your breaking the contract.
      </li>
    </ul>
    <h3>9 Product defects</h3>
    <ul>
      <li>9.1 We warrant that on delivery, the Products will be:</li>
      <ul>
        <li>
          (a) provided in accordance with the specifications or requirements set out in our Platform or in our
          confirmation email; and
        </li>
        <li>
          (b) performed with reasonable care, skill and ability in line with the common practices and standards in
          the relevant industry or trade.
        </li>
      </ul>
      <li>
        9.2 Any warranty given by us under this clause does not apply to any defect in the Products arising from us
        following any of your specification or requests.
      </li>
      <li>
        9.3 If you become aware of any defect in all or part of the Products performed, you must give notice in
        writing to us as soon as practicable.
      </li>
      <li>
        9.4 Where the Products reported under Clause 9.3 are found to be defective we will, at our option,
        re-perform the services or refund the price of such defective Products in full. We have no further
        liability to you for defective Products upon re-performance or refund of the defective Products.
      </li>
      <li>
        9.5 If you have any questions or complaints about any Product, please email us at
        customerservice@tablebooking.co.
      </li>
    </ul>
    <h3>10 Price and payment</h3>
    <ul>
      <li>
        10.1 The price of the Product will be the price indicated on the order pages when you placed your order. We
        take all reasonable care to ensure that the price of the Product advised to you is correct. However please
        see Clause 10.2 for what happens if we discover an error in the price of the Product you order.
      </li>
      <li>
        10.2 It is always possible that, despite our best efforts, some of the products we sell may be incorrectly
        priced. If the correct price for the Product at your order date is less than the price stated to you, we
        will charge the lower amount; but if it&rsquo;s higher than the price stated to you, we will contact you
        for your instructions before we accept your order.
      </li>
      <li>
        10.3 We accept payment (only if previously agreed) by bank transfer, otherwise by online payment methods
        and major credit and debit cards.
      </li>
      <li>
        10.4 We will invoice you for the price of the Products upon completion. Each invoice must be paid within
        three (3) calendar days from the date of its issue.
      </li>
      <li>10.5 If you think an invoice is wrong please contact us promptly to let us know.</li>
    </ul>
    <h3>11. Additional Policy on Payments, Cancellations and Refunds</h3>
    <ul>
      <li>
        11.1 Availability of Online / Direct Payment Option Venues and Events may have different payment methods
        depending on preference, some prefer online payment while others prefer payment to be made at the venue.
      </li>
      <li>
        11.2 Payment Problems: Payment may be rejected due to the wrong credit card number or some missing
        information. Please double check the information entered. You are obliged to make sure to provide us your
        real name and other accurate information in order for us to complete your booking. If payment still cannot
        be made please contact us at booking@tablebooking.co and we will try to help to remedy the situation.
      </li>
      <li>
        11.3 Charging Method Depending on the venue and event type we may either charge the full amount to your
        credit card at the time of booking (e.g. for tickets of music events) or place a hold on your card until
        the time of booking/event start.
      </li>
      <li>11.4 Cancellations &amp; Refunds:&nbsp;</li>
      <li>
        You can cancel your booking for bookings on www.tablebooking.co, unless otherwise communicated to you in
        writing or stated in the description(s) on the website, up to 48 hours before the booking/event start time
        by contacting booking@tablebooking.co and we will give you a refund to the original method of payment. The
        amount refunded to the customer for a cancellation with a minimum of 48h notice is (unless otherwise
        stated): Your payed amount minus payment gateway fees already incurred. For more information on applicable
        payment gateway fees please refer to:{" "}
        <a href="https://www.omise.co/pricing" target="_blank" rel="noopener noreferrer">
          https://www.omise.co/pricing
        </a>
        .&nbsp;
      </li>
      <li>
        If you cancel your prepaid booking less than 48 hours before the actual booking/event start time, TABLE and
        the Venue/Partner reserve the right to keep up to 100% of the prepayment if TABLE or the venue cannot find
        an appropriate replacement for your booking and depending on the booking/event type and policies.&nbsp;
      </li>
      <li>Please note that for no-shows, TABLE/Venue/Partner will keep 100% of the pre-paid amount.</li>
      <li>Please cancel your appointments well in advance in order to receive your refund.&nbsp;</li>
      <li>Simple admission tickets and certain special event bookings are non-refundable.</li>
    </ul>
    <h3>12 Our responsibility for loss or damage suffered by you</h3>
    <ul>
      <li>12.1 Subject to clause 11.2:</li>
      <ul>
        <li>
          (a) all other warranties, conditions, or terms relating to fitness for purpose, quality, or condition of
          the Products, whether express or implied by statute or common law or otherwise, are expressly excluded;
        </li>
        <li>
          (b) we shall not be liable to you (whether in contract, tort or otherwise) for any loss of profit or any
          indirect or consequential loss arising from or in connection with the provision of the Products; and
        </li>
        <li>
          (c) our total liability to you for all losses arising from or in connection with the provision of the
          Products shall be limited to the total sums received by us for the Products.
        </li>
      </ul>
      <li>12.2 Nothing in these terms will limit or exclude our liability for:</li>
      <ul>
        <li>
          (a) death or personal injury caused by our negligence, or the negligence of our employees, agents or
          subcontractors (where applicable);
        </li>
        <li>(b) fraud or fraudulent misrepresentation; or</li>
        <li>(c) any matter in respect of which it would be unlawful for us to exclude or restrict liability.</li>
      </ul>
    </ul>
    <h3>13 Use of your personal information</h3>
    <p>
      When you register or otherwise use our Platform, you provide us with personal data which is collected and
      used in accordance with the terms of our privacy policy which is accessible at
      <a href="https://tablebooking.co/privacy-policy" target="_blank" rel="noopener noreferrer">
        https://tablebooking.co/privacy-policy
      </a>
      . It is important that you read our privacy policy, which may be updated from time to time, and understand
      how we use your information and the rights that you have about your information.
    </p>
    <h3>14 Entire agreement</h3>
    <ul>
      <li>
        14.1 These terms constitute the entire agreement between us in relation to your purchase. These terms
        supersede and extinguish all other agreements, promises, assurances, warranties, representations and
        understandings between us, whether written or oral, in relation to your purchase.
      </li>
      <li>
        14.2 You acknowledge that you will have no remedies in respect of any statement, representation, assurance
        or warranty (whether made innocently or negligently) that is not set out in these terms.
      </li>
    </ul>
    <h3>15 Other important terms</h3>
    <ul>
      <li>
        15.1 We may transfer our rights and obligations under these terms to another organisation. We will contact
        you to let you know if we plan to do this. If you do not wish to continue the contract with the transferee,
        you may contact us to end the contract within one (1) calendar month of us informing you of the proposed
        transfer and we will refund you any payments you have made in advance for Products not provided.
      </li>
      <li>
        15.2 You may only transfer your rights or your obligations under these terms to another person if we agree
        to this in writing.
      </li>
      <li>
        15.3 This contract is between you and us. Nobody else has any rights under this contract. No other person
        shall have any rights to enforce any of its term.
      </li>
      <li>
        15.4 If a court finds part of this contract illegal, the rest will continue in force. Each of the
        paragraphs of these terms operates separately. If any court or relevant authority decides that any of them
        are unlawful, the remaining paragraphs will remain in full force and effect.
      </li>
      <li>
        15.5 Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist
        immediately that you do anything you are required to do under these terms, or if we delay in taking steps
        against you in respect of your breaking this contract, that will not mean that you do not have to do those
        things and it will not prevent us taking steps against you at a later date. For example, if you miss a
        payment and we do not chase you but we continue to provide the Products, we can still require you to make
        the payment at a later date.
      </li>
    </ul>
    <h3>16 Governing law and dispute resolution</h3>
    <ul>
      <li>
        16.1 This Agreement and any dispute or claim arising out of or in connection with it or its subject matter
        or formation (including non-contractual disputes or claims) shall be governed by and construed in
        accordance with the laws of the Republic of Singapore and the Kingdom of Thailand, depending on where you
        use our services.
      </li>
      <li>
        16.2 Alternative dispute resolution is a process where an independent body considers the facts of a dispute
        and seeks to resolve it, without you having to go to court. If you are not happy with how we have handled
        any complaint, you may want to contact the alternative dispute resolution provider we use. You can submit a
        complaint to (if you have a complaint about or issue with our services) 49 CAP PTE. LTD., 51 GOLDHILL
        PLAZA, #07-10/11, SINGAPORE 308900 or TABLE (Thailand) Co., Ltd., Woof Pack Building 1, 3-9 Sala Daeng 1
        Alley, Silom, Bang Rak, Bangkok 10500 or write us an email to customerservice@tablebooking.co. We will take
        reasonable steps to work with you to attempt to resolve your complaint will not charge you for making a
        complaint and if you are not satisfied with the outcome you can still bring legal proceedings.
      </li>
    </ul>
  </div>
)

export default Content
