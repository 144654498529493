import * as React from "react"

import style from "./style.module.css"

const NotificationsContainer = ({
  notifications,
  component: NotificationComponent,
}) => (
  <div className={style.container}>
    <div>
      {notifications.active.map((e, i) => (
        <NotificationComponent key={i} {...e} />
      ))}
    </div>
  </div>
)

export default NotificationsContainer
