// @flow
import * as React from "react"
import { connect } from "react-redux"

import Notification from "./Notification"
import NotificationsContainer from "./NotificationsContainer"
import type { NotificationsObjectType } from "./types"

type Props = {
  notifications: NotificationsObjectType,
}

export default function withNotifications(Component: React.ComponentType<any>) {
  class Notifications extends React.Component<Props> {
    render() {
      return (
        <div>
          <NotificationsContainer
            component={Notification}
            notifications={this.props.notifications}
          />
          <Component {...this.props} />
        </div>
      )
    }
  }

  const mstp = ({ notifications }) => ({ notifications })

  return connect(
    mstp,
    null
  )(Notifications)
}
