// @flow
import React from "react"
import * as _ from "lodash"
import { Grid } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import Item from "components/Item"

export const dealCategories = [
  "Events",
  "Shopping",
  "Fine Dining",
  "Lifestyle",
  "Travel",
  "Activities",
  "Food & Drinks",
  "Health & Beauty",
  "Learning",
  "Others",
]

type PropTypes = {
  deals: {
    segments: {},
    readSuccess: Boolean,
  },
  clubs: {
    segments: {},
    readSuccess: Boolean,
  },
  club?: string,
}
type StateTypes = {
  segments: [],
}

class DealsList extends React.Component<PropTypes, StateTypes> {
  state = {
    deals: {},
  }

  componentDidMount() {
    this.separateItemsByCategorySections()
  }

  componentDidUpdate(prevProps) {
    const { clubs, deals } = this.props
    if (clubs.readSuccess && deals.readSuccess && (!prevProps.deals.readSuccess || !prevProps.clubs.readSuccess)) {
      this.separateItemsByCategorySections()
    }
  }

  separateItemsByCategorySections() {
    const {
      deals: { segments },
    } = this.props

    const items = Object.values(segments).sort(({ category: a }, { category: b }) => {
      return dealCategories.indexOf(a) - dealCategories.indexOf(b)
    })

    const deals = _.groupBy(items, "category")

    return this.setState({ deals })
  }

  render() {
    const { deals } = this.state
    const categories = Object.keys(deals)
    return (
      <div>
        {categories.length ? (
          categories.map((category) => {
            const items = deals[category]
            return (
              <div key={`category-${category}`} style={{ marginTop: "2em" }}>
                <h4>{category}</h4>
                <Grid doubling stackable columns={3}>
                  {items.map(({ _id, slug, name, club_id, images, minimum_spend }, j) => {
                    const club = this.props.clubs.segments[club_id]
                    return (
                      <Grid.Column key={`deal-${j}`}>
                        <Link to={`/deal/${slug || _id}`}>
                          <Item
                            isDeal
                            bookNowLink={`/book-deal/${club_id}?deal=${_id}`}
                            title={name}
                            subtitle={club ? club.description : ""}
                            image={images && images.length && images[0] && images[0].path}
                            underneathComponent={
                              <div style={{ fontSize: "0.9em", opacity: 0.9, margin: "0.25em" }}>
                                <span style={{ color: "#f54073", fontWeight: "bold", paddingRight: "5px" }}>
                                  ฿
                                </span>{" "}
                                From {minimum_spend}THB
                              </div>
                            }
                            button={"Find out more"}
                          />
                        </Link>
                      </Grid.Column>
                    )
                  })}
                </Grid>
              </div>
            )
          })
        ) : (
          <p style={{ textAlign: "center", padding: "5em 1em", minHeight: "300px" }}>No deals</p>
        )}
      </div>
    )
  }
}

const mstp = ({ deals, clubs }) => ({ deals, clubs })

export default connect(mstp, null)(DealsList)
