// @flow
import * as React from "react"
import { connect } from "react-redux"
import { Divider, Grid } from "semantic-ui-react"
import { reduxForm, Field } from "redux-form"

import Button from "components/Button"
import {
  renderInput,
  renderDropdown,
  renderSingleDatePicker,
} from "utils/renderField"

import styles from "./styles.module.css"

import type { UserType } from "data/User/types"

type PropTypes = {
  handleSubmit: Function,
  updateProfileDetails: Function,
  user: UserType,
}

const ProfileDetailsForm = ({
  handleSubmit,
  updateProfileDetails,
  user,
}: PropTypes) => (
  <form onSubmit={handleSubmit(updateProfileDetails)}>
    <div className={styles.avatar}>
      <div>
        <p>{user.name || "Unknown name"}</p>
        <p>{user.email || "Unknown email"}</p>
      </div>
    </div>
    <Divider />
    <Divider hidden />
    <Grid stackable columns="equal">
      <Grid.Row>
        <Grid.Column width={5}>
          <Field
            name={"name"}
            component={renderInput}
            type={"text"}
            label={"Name"}
            placeholder={"Enter full name"}
          />
        </Grid.Column>
        <Grid.Column>
          <Field
            name={"gender"}
            component={renderDropdown}
            options={[
              { text: "Male", value: "male" },
              { text: "Female", value: "female" },
            ]}
            label={"Gender"}
          />
        </Grid.Column>
        <Grid.Column>
          <Field
            name={"birthdate"}
            component={renderSingleDatePicker}
            label={"Birthdate"}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={5}>
          <Field
            name={"email"}
            component={renderInput}
            label={"Email"}
            type={"email"}
          />
        </Grid.Column>
        <Grid.Column>
          <Field
            name={"phone_number"}
            component={renderInput}
            label={"Phone"}
            type={"text"}
          />
        </Grid.Column>
        <Grid.Column className={"labelless"}>
          <Button>Save changes</Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </form>
)

export default connect(
  ({ user }) => ({ initialValues: user }),
  null
)(
  reduxForm({
    form: "ProfileDetailsForm",
  })(ProfileDetailsForm)
)
